import { useQuery } from "react-query";
import * as mallService from "services/mall.service";
import useMallStore from "common/stores/mall.store";
import { useNavigate } from "react-router-dom";
import _ from "lodash";

const isAuthenticated = localStorage.getItem("token");

export const useMallQuery = () => {
  const navigate = useNavigate();
  const { setMalls } = useMallStore();

  return useQuery({
    queryKey: "mallByUser",
    queryFn: () => mallService.getMallByUser(),
    onSuccess: (mall) => {
      setMalls(mall);
    },
    onError: (error) => {
      console.log("userQuery error");
      localStorage.removeItem("token");
      localStorage.removeItem("refresh_token");
      // navigate("/recruit/login");
    },
  });
};
// userIsLoading, userInfoError, user
