import React, { useState, useEffect } from "react";
import "modules/space/css/admin.css";
import {
  PlusOutlined,
  ArrowLeftOutlined,
  RightOutlined,
} from "@ant-design/icons";
import {
  Upload,
  Button,
  Form,
  Input,
  Image,
  Checkbox,
  Radio,
  Divider,
  InputNumber,
  Row,
  Col,
  Switch,
} from "antd";
import AddfloorPlan from "./add-floorplan";
import axiosAPI from "assets/js/axiosApi";
import { Loader } from "semantic-ui-react";
import { useNavigate } from "react-router-dom";
const CheckboxGroup = Checkbox.Group;

export default function SettingOnboardNamedEditSpace(props) {
  const navigate = useNavigate();
  const [editZone] = useState(JSON.parse(localStorage.getItem("edit_zone")));
  const [businessType, setBusinessType] = useState(null);

  const [reserveType] = useState(editZone.booking_unit);
  const [required_approval, setRequired_approval] = useState(
    editZone.required_approval
  );
  const [zonePic] = useState(
    editZone.images.filter((img) => img.image_type === "รูปโซน")
  );
  const [uploadFileFloor] = useState(
    editZone.images.filter((img) => img.image_type === "รูปFloorPlan")
  );
  const [zoneName] = useState(editZone.name);
  const [avaiZone, setAvaiZone] = useState(editZone.total_unit);
  const [floor, setFloor] = useState(editZone.floor);
  const [contactType] = useState(editZone.contract_type);
  const [areaType] = useState(editZone.area_type);
  const [booth_price, setBooth_price] = useState(editZone.booth_price);
  const [checkedList] = useState(editZone.shop_specification.business_type);
  const [boothSize_width] = useState(editZone.space_booth_width);
  const [boothSize_height] = useState(editZone.space_booth_length);

  const [minReserved, setMinReserved] = useState(editZone.min_booking_day);
  const [maxReserved, setMaxReserved] = useState(editZone.max_booking_day);

  const [minFuture_Reserved, setMinFutureReserved] = useState(
    editZone.min_pre_booking_day
  );
  const [maxFuture_Reserved, setMaxFutureReserved] = useState(
    editZone.max_pre_booking_day
  );

  const [onLoading, setOnloading] = useState(false);

  console.log(editZone);

  useEffect(() => {
    async function getBusinessType() {
      await axiosAPI
        .get(`/v1/shop/business/type`)
        .then((response) => {
          setBusinessType(response.data.data);
          console.log("getBusinessType: ", response.data.data);
        })
        .catch((error) => {
          console.log(error.responce);
          setBusinessType([]);
        });
    }
    getBusinessType();
  }, []);

  const onFinish = () => {
    setOnloading(true);
    var data = {
      id: editZone.id,
      min_booking_day: minReserved,
      max_booking_day: maxReserved,
      min_pre_booking_day: minFuture_Reserved,
      max_pre_booking_day: maxFuture_Reserved,
      required_approval: required_approval,
      booth_price: booth_price,
    };

    axiosAPI
      .post("/v1/space/update", data)
      .then((response) => {
        console.log(response);
        navigate("/space/setting/add/available");
      })
      .catch((error) => {
        console.log(error.response);
      });
  };
  const handleChange = (e) => {
    if (e.target.name === "booth_price") {
      if (/^[0-9]+$/.test(e.target.value) || e.target.value === "") {
        setBooth_price(e.target.value);
        console.log("booth_price : ", e.target.value);
      }
    }
  };
  const handleminFuture_Reserved = (e) => {
    setMinFutureReserved(e);
    console.log("min_Future_Reserved : ", e);
  };
  const handlemaxFuture_Reserved = (e) => {
    setMaxFutureReserved(e);
    console.log("max_Future_Reserved : ", e);
  };
  const handleminReserved = (e) => {
    setMinReserved(e);
    console.log("minReserved : ", e);
  };
  const handlemaxReserved = (e) => {
    setMaxReserved(e);
    console.log("maxReserved : ", e);
  };
  function onswitchChange(checked) {
    setRequired_approval(checked);
  }

  if (businessType === null) {
    return (
      <div
        id="loading_div"
        style={{ top: "50%", left: "50%", position: "absolute" }}
      >
        <Loader active size="medium">
          Loading...
        </Loader>
      </div>
    );
  }

  return (
    <>
      <body style={{ fontFamily: "'Kanit', sans-serif" }}>
        <div id="admin-bg">
          <div
            style={{
              width: "800px",
              backgroundColor: "white",
              display: "block",
              marginLeft: "auto",
              marginRight: "auto",
              marginTop: "10px",
              borderRadius: "12px",
              padding: "50px",
            }}
          >
            <div
              style={{
                color: "#5ABBAD",
                fontSize: "16px",
                fontWeight: "bold",
                textAlign: "left",
              }}
            >
              <p
                onClick={() => navigate("/space/setting/add/available")}
                style={{ cursor: "pointer" }}
              >
                <ArrowLeftOutlined />
              </p>
            </div>
            <div
              style={{
                color: "#5ABBAD",
                fontSize: "24px",
                fontWeight: "bold",
                marginTop: "23px",
                textAlign: "left",
              }}
            >
              แก้ไขโซน
            </div>
            <div
              style={{
                textAlign: "left",
                overflow: "scroll",
                height: "780px",
                overflowX: "visible",
                marginTop: "20px",
              }}
            >
              <div style={{ marginRight: "10px" }}>
                <div>
                  <Form
                    name="basic"
                    labelCol={{ span: 8 }}
                    wrapperCol={{ span: 24 }}
                    requiredMark
                    layout="vertical"
                    onFinish={onFinish}
                  >
                    <div style={{ textAlign: "left", marginTop: "17px" }}>
                      <Image width={80} src={zonePic[0].image_url} />
                    </div>
                    <div style={{ marginTop: "20px" }}>
                      <Form.Item label="ชื่อโซน" style={{ textAlign: "left" }}>
                        <Input
                          placeholder="โซนพาวิลเลี่ยน, เปิดท้าย"
                          name="zoneName"
                          value={zoneName}
                          style={{
                            borderBottom: "2px solid #F2F2F2",
                            fontFamily: "'Kanit', sans-serif",
                          }}
                          bordered={false}
                          disabled
                        />
                      </Form.Item>

                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          cursor: "pointer",
                          marginTop: "10px",
                        }}
                        onClick={() =>
                          navigate(
                            "/space/setting/upload/floorplan-aminities-update"
                          )
                        }
                      >
                        <div>
                          สิ่งอำนวยความสะดวก{" "}
                          <RightOutlined
                            size="medium"
                            style={{ color: "#5ABBAD", fontWeight: "bold" }}
                          />
                        </div>
                      </div>
                      <Divider />
                      <Form.Item label="ระบุชั้น" style={{ textAlign: "left" }}>
                        <Input
                          placeholder="B, G, 1"
                          name="floor"
                          value={floor}
                          style={{
                            borderBottom: "2px solid #F2F2F2",
                            fontFamily: "'Kanit', sans-serif",
                          }}
                          bordered={false}
                          disabled
                        />
                      </Form.Item>
                      <Form.Item
                        label="รูปแบบการจอง"
                        rules={[
                          { required: true, message: "กรุณาเลือกรูปแบบการจอง" },
                        ]}
                      >
                        <Radio.Group value={reserveType}>
                          <Radio.Button value="วัน">รายวัน</Radio.Button>
                          <Radio.Button value="เดือน">รายเดือน</Radio.Button>
                        </Radio.Group>
                      </Form.Item>
                      <Form.Item
                        label="สัญญาในพื้นที่ที่เปิดรับ"
                        rules={[
                          { required: true, message: "กรุณาเลือกรูปแบบสัญญา" },
                        ]}
                      >
                        <Radio.Group value={contactType}>
                          <Radio.Button value="ระยะสั้น">ระยะสั้น</Radio.Button>
                          <Radio.Button value="ระยะยาว">ระยะยาว</Radio.Button>
                        </Radio.Group>
                      </Form.Item>
                      <Form.Item
                        label="รูปแบบพื้นที่"
                        rules={[
                          {
                            required: true,
                            message: "กรุณาเลือกรูปแบบพื้นที่",
                          },
                        ]}
                      >
                        <Radio.Group value={areaType}>
                          <Radio.Button value="indoor">indoor</Radio.Button>
                          <Radio.Button value="outdoor">outdoor</Radio.Button>
                        </Radio.Group>
                      </Form.Item>
                      <Form.Item
                        name="checkbox-group"
                        label="รูปแบบร้านค้าที่สามารถจองได้"
                      >
                        <div style={{ color: "#869594", fontSize: "14px" }}>
                          สามารถเลือกได้มากกว่า 1 รายการ
                        </div>
                        <div style={{ marginTop: "10px" }}>
                          <CheckboxGroup
                            options={businessType}
                            value={checkedList}
                          />
                        </div>
                      </Form.Item>
                      <Divider />

                      <Form.Item
                        label="ขนาดต่อล็อก"
                        style={{ marginBottom: 0 }}
                        rules={[
                          { required: true, message: "กรุณาระบุขนาดต่อล็อก" },
                        ]}
                      >
                        <Form.Item
                          style={{
                            display: "inline-block",
                            width: "calc(50% - 12px)",
                          }}
                        >
                          <Input
                            placeholder="100ตร.ม"
                            name="boothSize_width"
                            value={boothSize_width}
                            style={{
                              borderBottom: "2px solid #F2F2F2",
                              fontFamily: "'Kanit', sans-serif",
                            }}
                            bordered={false}
                            disabled
                          />
                        </Form.Item>
                        <span
                          style={{
                            display: "inline-block",
                            width: "24px",
                            lineHeight: "32px",
                            textAlign: "center",
                          }}
                        >
                          x
                        </span>
                        <Form.Item
                          style={{
                            display: "inline-block",
                            width: "calc(50% - 12px)",
                          }}
                        >
                          <Input
                            placeholder="100ตร.ม"
                            name="boothSize_height"
                            value={boothSize_height}
                            style={{
                              borderBottom: "2px solid #F2F2F2",
                              fontFamily: "'Kanit', sans-serif",
                            }}
                            bordered={false}
                            disabled
                          />
                        </Form.Item>
                      </Form.Item>

                      <Form.Item label="ล็อกว่าง" style={{ textAlign: "left" }}>
                        <Input
                          placeholder="100"
                          name="avaiZone"
                          value={avaiZone}
                          style={{
                            borderBottom: "2px solid #F2F2F2",
                            fontFamily: "'Kanit', sans-serif",
                          }}
                          bordered={false}
                          disabled
                        />
                      </Form.Item>

                      <Form.Item
                        label="ราคาต่อล็อค"
                        style={{ textAlign: "left" }}
                        rules={[
                          { required: true, message: "กรุณาราคา" },
                          {
                            pattern: new RegExp(/^[0-9]+$/),
                            message: "ระบุเป็นตัวเลข",
                          },
                        ]}
                      >
                        <Input
                          placeholder="0.00"
                          name="booth_price"
                          value={booth_price}
                          suffix="ต่อวัน"
                          onChange={(e) => handleChange(e)}
                          style={{
                            borderBottom: "2px solid #F2F2F2",
                            fontFamily: "'Kanit', sans-serif",
                          }}
                          bordered={false}
                        />
                      </Form.Item>

                      <Row>
                        <Col span={12}>
                          <div style={{ marginBottom: "10px" }}>
                            จำนวนวันจองล่วงหน้าขั้นต่ำ
                          </div>
                          <Form.Item
                            rules={[
                              {
                                required: true,
                                message: "กรุณาระบุจำนวนวันจองล่วงหน้าขั้นต่ำ",
                              },
                            ]}
                          >
                            <InputNumber
                              min={0}
                              max={365}
                              defaultValue={0}
                              name="minFuture_Reserved"
                              value={minFuture_Reserved}
                              onChange={handleminFuture_Reserved}
                            />
                          </Form.Item>
                        </Col>
                        <Col span={12}>
                          <div style={{ marginBottom: "10px" }}>
                            จำนวนวันจองล่วงหน้าสูงสุด
                          </div>
                          <Form.Item
                            rules={[
                              {
                                required: true,
                                message: "กรุณาระบุจำนวนวันจองล่วงหน้าสูงสุด",
                              },
                            ]}
                          >
                            <InputNumber
                              min={0}
                              max={365}
                              defaultValue={0}
                              name="maxFuture_Reserved"
                              value={maxFuture_Reserved}
                              onChange={(e) => handlemaxFuture_Reserved(e)}
                            />
                          </Form.Item>
                        </Col>
                      </Row>
                      <Row>
                        <Col span={12}>
                          <div style={{ marginBottom: "10px" }}>
                            จำนวนวันขั้นต่ำในการจอง
                          </div>
                          <Form.Item
                            rules={[
                              {
                                required: true,
                                message: "กรุณาระบุจำนวนวันขั้นต่ำในการจอง",
                              },
                            ]}
                          >
                            <InputNumber
                              min={0}
                              value={minReserved}
                              name="minReserved"
                              max={365}
                              defaultValue={0}
                              onChange={(e) => handleminReserved(e)}
                            />
                          </Form.Item>
                        </Col>
                        <Col span={12}>
                          <div style={{ marginBottom: "10px" }}>
                            จำนวนวันสูงสุดในการจอง
                          </div>
                          <Form.Item
                            rules={[
                              {
                                required: true,
                                message: "กรุณาระบุจำนวนวันขั้นต่ำในการจอง",
                              },
                            ]}
                          >
                            <InputNumber
                              min={0}
                              max={365}
                              value={maxReserved}
                              name="maxReserved"
                              defaultValue={0}
                              onChange={(e) => handlemaxReserved(e)}
                            />
                          </Form.Item>
                        </Col>
                      </Row>

                      <div style={{ marginTop: "10px" }}>
                        <div>
                          ตรวจสอบการจอง{" "}
                          <Switch
                            style={{ marginLeft: "15px" }}
                            onClick={onswitchChange}
                            checked={required_approval}
                          />
                        </div>
                        <p style={{ marginTop: "10px", color: "#869594" }}>
                          การจองที่ต้องตรวจสอบก่อนยืนยันให้ร้านค้าเข้าพื้นที่
                        </p>
                      </div>

                      <Divider />

                      <div style={{ marginTop: "10px" }}>
                        <div>ภาพ Floorplan</div>
                        <div>
                          <Image
                            width={80}
                            src={uploadFileFloor[0].image_url}
                          />
                        </div>
                      </div>
                    </div>

                    <div style={{ marginTop: "45px" }}>
                      <Button
                        size="large"
                        htmlType="submit"
                        loading={onLoading}
                        style={{
                          fontSize: "18px",
                          backgroundColor: "#5ABBAD",
                          color: "white",
                          borderRadius: "6px",
                          width: "100%",
                          fontFamily: "'Kanit', sans-serif",
                        }}
                      >
                        ยืนยัน
                      </Button>
                    </div>
                    <div style={{ marginTop: "25px" }}>
                      <Button
                        size="large"
                        // onClick={() => }
                        style={{
                          fontSize: "16px",
                          backgroundColor: "transparent",
                          borderRadius: "6px",
                          width: "100%",
                          fontFamily: "'Kanit', sans-serif",
                          border: "transparent",
                        }}
                      >
                        ยกเลิก
                      </Button>
                    </div>
                  </Form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </body>
    </>
  );
}
