import create from "zustand";

const useStore = create((set) => ({
  registration: {},
  setRegistration: (registration) =>
    set(() => ({ registration: registration })),
  campaigns: {},
  setCampaigns: (campaigns) => set(() => ({ campaigns: campaigns })),
  role: {},
  setRole: (role) => set(() => ({ role: role })),
  registrations: [],
  setRegistrations: (registrations) => set({ registrations: registrations }),
  fetchErrorModal: false,
  setFetchErrorModal: (fetchErrorModal) =>
    set({ fetchErrorModal: fetchErrorModal }),
  productNumberList: [],
  setProductNumberList: (productNumberList) =>
    set({ productNumberList: productNumberList }),
  modal: { visible: false, type: "" },
  setModal: (modal) => set({ modal: modal }),
  isEditCalendar: false,
  setIsEditCalendar: (isEditCalendar) =>
    set({ isEditCalendar: isEditCalendar }),
  emailChecked: null,
  setEmailChecked: (emailChecked) => set({ emailChecked: emailChecked }),
  timeSlots: [],
  setTimeSlots: (timeSlots) => set({ timeSlots: timeSlots }),
  buyerEmails: [],
  setBuyerEmails: (buyerEmails) => set({ buyerEmails: buyerEmails }),
  filterModal: false,
  setFilterModal: (filterModal) => set({ filterModal: filterModal }),
  filterState: {},
  setFilterState: (filterState) => set({ filterState: filterState }),
  campaign_id: 2,
  setCampaignId: (campaign_id) => set({ campaign_id: campaign_id }),
}));

export default useStore;
