import React, { useState, useEffect } from "react";
import { Upload, Button, Form, Input, Row, Col } from "antd";
import "modules/space/css/admin.css";
import { RightOutlined } from "@ant-design/icons";
import addFlooricon from "assets/img/addFlooricon.png";
import { useNavigate } from "react-router-dom";

export default function AddFloorPlan(props) {
  const navigate = useNavigate();
  return (
    <>
      <body style={{ fontFamily: "'Kanit', sans-serif" }}>
        <div id="admin-bg">
          <div
            style={{
              width: "708px",
              backgroundColor: "white",
              display: "block",
              marginLeft: "auto",
              marginRight: "auto",
              marginTop: "200px",
              borderRadius: "12px",
              padding: "70px",
            }}
          >
            <div>
              <div
                style={{
                  color: "#5ABBAD",
                  fontSize: "24px",
                  textAlign: "left",
                  fontWeight: "bold",
                }}
              >
                สร้างพื้นที่ขาย
              </div>
              <div
                style={{
                  color: "#869594",
                  fontSize: "15px",
                  textAlign: "left",
                  fontWeight: "bold",
                  marginTop: "37px",
                }}
              >
                โปรดเพิ่มสถานที่สำหรับพื้นที่ขายของคุณ
              </div>

              <div
                style={{
                  color: "#18A0FB",
                  fontSize: "18px",
                  marginTop: "94px",
                  border: "1px solid #DBE8E7",
                  borderRadius: "12px",
                  marginLeft: "170px",
                  marginRight: "170px",
                  paddingTop: "20px",
                  paddingBottom: "20px",
                  cursor: "pointer",
                }}
                onClick={() => navigate("/space/setting/create")}
              >
                <img src={addFlooricon} />
                <p style={{ marginTop: "15px" }}>เพิ่มชั้น</p>
              </div>

              <div style={{ marginTop: "90px" }}>
                <div
                  style={{
                    backgroundColor: "transparent",
                    borderRadius: "6px",
                    fontFamily: "'Kanit', sans-serif",
                    cursor: "pointer",
                  }}
                >
                  <div
                    style={{
                      textDecoration: "underline",
                      fontSize: "16px",
                      color: "gray",
                    }}
                    onClick={() => navigate("/")}
                  >
                    ภายหลัง
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </body>
    </>
  );
}
