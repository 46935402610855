import React, { useState, useEffect } from "react";
import "./css/admin.css";
import {
  PlusOutlined,
  CalendarOutlined,
  RightOutlined,
} from "@ant-design/icons";
import {
  Upload,
  Button,
  Form,
  Input,
  Collapse,
  Checkbox,
  Radio,
  Divider,
  InputNumber,
  Row,
  Col,
  Switch,
  Modal,
  message,
} from "antd";
import axiosAPI from "assets/js/axiosApi";
import { Loader, Label } from "semantic-ui-react";
import * as spaceService from "services/space.service";

const CheckboxGroup = Checkbox.Group;
const defaultCheckedList = [];
const { Panel } = Collapse;

export default function CreateSapce(props) {
  // Local Storage
  const [mallDetail, setMallDeatil] = useState(
    JSON.parse(localStorage.getItem("malls_access"))
  );
  // API
  const [businessType, setBusinessType] = useState(null);

  const [form] = Form.useForm();
  // Create Space
  const [reserveType, setReserveType] = useState(null);
  const [required_approval, setRequired_approval] = useState(true);
  const [uploadFileZone, setUploadFileZone] = useState(null);
  const [uploadFileFloor, setUploadFileFloor] = useState(null);
  const [avaiZone, setAvaiZone] = useState(null);
  const [zoneName, setZoneName] = useState(null);
  const [floor, setFloor] = useState(null);
  const [contactType, setContactType] = useState(null);
  const [areaType, setAreaType] = useState(null);
  const [booth_price, setBooth_price] = useState(null);
  const [checkedList, setCheckedList] = useState(defaultCheckedList);
  const [boothSize_width, setBoothSize_width] = useState(null);
  const [boothSize_height, setBoothSize_height] = useState(null);
  const [minReserved, setMinReserved] = useState(null);
  const [maxReserved, setMaxReserved] = useState(null);
  const [minFuture_Reserved, setMinFutureReserved] = useState(null);
  const [maxFuture_Reserved, setMaxFutureReserved] = useState(null);
  const [onLoading, setOnloading] = useState(false);
  const [checkedValue, setCheckedValue] = useState(null);

  function clear() {
    setRequired_approval(null);
    setRequired_approval(true);
    setUploadFileZone(null);
    setUploadFileFloor(null);
    setAvaiZone(null);
    setZoneName(null);
    setFloor(null);
    setContactType(null);
    setAreaType(null);
    setBooth_price(null);
    setCheckedList(null);
    setBoothSize_width(null);
    setBoothSize_height(null);
    setMinReserved(null);
    setMaxReserved(null);
    setMinFutureReserved(null);
    setMaxFutureReserved(null);
    setOnloading(false);
    setCheckedValue(null);
  }

  useEffect(() => {
    async function getBusinessType() {
      await axiosAPI
        .get(`/v1/shop/business/type`)
        .then((response) => {
          setBusinessType(response.data.data);
          console.log("getBusinessType: ", response.data.data);
        })
        .catch((error) => {
          console.log(error.responce);
          setBusinessType([]);
        });
    }
    getBusinessType();
  }, []);

  function onChangeConvinience(e) {
    setCheckedValue(e);
    console.log(`checked = ${e}`);
  }
  const contactTypeOnChange = (e) => {
    console.log("Contact checked", e.target.value);
    setContactType(e.target.value);
  };
  const reserveTypeOnChange = (e) => {
    console.log("reserve checked", e.target.value);
    setReserveType(e.target.value);
  };
  const areaTypeOnChange = (e) => {
    console.log("Area type:", e.target.value);
    setAreaType(e.target.value);
  };
  const onChangeTypeBusiness = (list) => {
    console.log(list);
    setCheckedList(list);
  };
  const handleImageChangeZone = (e) => {
    if (typeof e.fileList[0] !== "undefined") {
      console.log(e.fileList[0].originFileObj);
      setUploadFileZone(e.fileList[0].originFileObj);
    } else {
      setUploadFileZone(null);
    }
  };
  const handleImageChangeFloor = (e) => {
    if (typeof e.fileList[0] !== "undefined") {
      console.log(e.fileList[0].originFileObj);
      setUploadFileFloor(e.fileList[0].originFileObj);
    } else {
      setUploadFileFloor(null);
    }
  };
  const onFinish = async () => {
    const key = "updatable";

    var minR, maxR, minFutureR, maxFutureR;

    if (minFuture_Reserved === null || minFuture_Reserved.length === 0) {
      minFutureR = null;
    } else {
      minFutureR = minFuture_Reserved;
    }

    if (maxFuture_Reserved === null || maxFuture_Reserved.length === 0) {
      maxFutureR = null;
    } else {
      if (parseInt(maxFuture_Reserved) === 0) {
        return (
          Modal.info({
            content: <div>วันจองล่วงหน้าสูงสุดควรมีขั้นต่ำเป็น 1</div>,
          }),
          setOnloading(false)
        );
      } else {
        maxFutureR = maxFuture_Reserved;
      }
    }

    if (minReserved === null || minReserved.length === 0) {
      minR = null;
    } else {
      minR = minReserved;
    }

    if (maxReserved === null || maxReserved.length === 0) {
      maxR = null;
    } else {
      if (parseInt(maxReserved) === 0) {
        return (
          Modal.info({
            content: <div>วันจองสูงสุดควรมีขั้นต่ำเป็น 1</div>,
          }),
          setOnloading(false)
        );
      } else {
        maxR = maxReserved;
      }
    }
    if (minFutureR !== null && maxFutureR !== null) {
      if (parseFloat(minFuture_Reserved) > parseFloat(maxFuture_Reserved)) {
        return (
          Modal.info({
            content: (
              <div>วันจองล่วงหน้าสูงสุดควรมากกว่าวันจองล่วงหน้าขั้นต่ำ</div>
            ),
          }),
          setOnloading(false)
        );
      }
    }

    if (minR !== null && maxR !== null) {
      if (parseFloat(minReserved) > parseFloat(maxReserved)) {
        return (
          Modal.info({
            content: <div>วันจองสูงสุดควรมากกว่าวันจองขั้นต่ำ</div>,
          }),
          setOnloading(false)
        );
      }
    }
    setOnloading(true);
    message.loading({ content: "กำลังสร้างโซน...", key });

    var updateChecklist = {};
    if (checkedList.length !== 0) {
      for (let i = 0; i < checkedList.length; i++) {
        updateChecklist[checkedList[i]] = true;
      }
    }
    console.log(updateChecklist);

    var updatecheckedValue = {};
    if (checkedValue !== null) {
      for (let i = 0; i < checkedValue.length; i++) {
        updatecheckedValue[checkedValue[i]] = true;
      }
    }
    console.log(updatecheckedValue);

    var data = {
      name: zoneName,
      area_type: areaType,
      contract_type: contactType,
      mall_id: props.mallID,
      booth_price: booth_price,
      required_approval: required_approval,
      floor: floor,
      business_type: updateChecklist,
      booth_width: boothSize_width,
      booth_length: boothSize_height,
      min_booking_day: minR,
      max_booking_day: maxR,
      min_pre_booking_day: minFutureR,
      max_pre_booking_day: maxFutureR,
      booking_unit: reserveType,
      required_approval: required_approval,
      total_unit: avaiZone,
    };
    if (checkedValue !== null) {
      data["amenity"] = updatecheckedValue;
    }

    console.log(data);

    var promises = [];

    try {
      const newspace = await spaceService.postNewSpace(data);
      var spaceID = newspace.id;

      const paramsuploadFileZone = new FormData();
      const paramsuploadFileFloor = new FormData();
      paramsuploadFileFloor.append("file", uploadFileFloor);
      paramsuploadFileZone.append("file", uploadFileZone);

      if (uploadFileZone !== null) {
        promises.push(
          axiosAPI.post(
            `/v1/space/image/new?space_id=${spaceID}&img_type=รูปโซน`,
            paramsuploadFileZone
          )
        );
      }
      if (uploadFileFloor !== null) {
        promises.push(
          axiosAPI.post(
            `/v1/space/image/new?space_id=${spaceID}&img_type=รูปFloorPlan`,
            paramsuploadFileFloor
          )
        );
      }

      if (promises.length !== 0) {
        Promise.all(promises)
          .then(function (responses) {
            console.log(responses);
            message.success({ content: "สำเร็จ!", key, duration: 2 });
            props.getSpace(props.mallID);
            props.setCreateZoneDrawer(false);
            setOnloading(false);
            form.resetFields();
          })
          .catch((error) => {
            setOnloading(false);
            console.log(error.response);
            message.error(error.response.data.message);
          });
      } else {
        message.success({ content: "สำเร็จ!", key, duration: 2 });
        props.getSpace(props.mallID);
        props.setCreateZoneDrawer(false);
        setOnloading(false);
        form.resetFields();
      }
    } catch (error) {
      setOnloading(false);
      console.log(error.response);
      message.error({
        content: error.response.data.message,
        key,
        duration: 2,
      });
    }

    // axiosAPI
    //   .post(`/v1/space/new`, data)
    //   .then((response) => {
    //     console.log(response.data);
    //     var spaceID = response.data.data.id;

    //     const paramsuploadFileZone = new FormData();
    //     const paramsuploadFileFloor = new FormData();
    //     paramsuploadFileFloor.append("file", uploadFileFloor);
    //     paramsuploadFileZone.append("file", uploadFileZone);

    //     if (uploadFileZone !== null) {
    //       promises.push(
    //         axiosAPI.post(
    //           `/v1/space/image/new?space_id=${spaceID}&img_type=รูปโซน`,
    //           paramsuploadFileZone
    //         )
    //       );
    //     }
    //     if (uploadFileFloor !== null) {
    //       promises.push(
    //         axiosAPI.post(
    //           `/v1/space/image/new?space_id=${spaceID}&img_type=รูปFloorPlan`,
    //           paramsuploadFileFloor
    //         )
    //       );
    //     }

    //     if (promises.length !== 0) {
    //       Promise.all(promises)
    //         .then(function (responses) {
    //           console.log(responses);
    //           console.log("This");
    //           message.success({ content: "สำเร็จ!", key, duration: 2 });
    //           props.getSpace(props.mallID);
    //           props.setCreateZoneDrawer(false);
    //           setOnloading(false);
    //           form.resetFields();
    //         })
    //         .catch((error) => {
    //           setOnloading(false);
    //           console.log(error.response);
    //           message.error(error.response.data.message);
    //         });
    //     } else {
    //       message.success({ content: "สำเร็จ!", key, duration: 2 });
    //       props.getSpace(props.mallID);
    //       props.setCreateZoneDrawer(false);
    //       setOnloading(false);
    //       form.resetFields();
    //     }
    //   })
    //   .catch((error) => {
    //     setOnloading(false);
    //     console.log(error.response);
    //     message.error({
    //       content: error.response.data.message,
    //       key,
    //       duration: 2,
    //     });
    //   });
  };
  const handleChange = (e) => {
    if (e.target.name === "zoneName") {
      setZoneName(e.target.value);
      console.log("zoneName: ", e.target.value);
    }
    if (e.target.name === "floor") {
      setFloor(e.target.value);
      console.log("floor : ", e.target.value);
    }
    if (e.target.name === "boothSize_width") {
      setBoothSize_width(e.target.value);
      console.log("boothSize_width : ", e.target.value);
    }
    if (e.target.name === "boothSize_height") {
      setBoothSize_height(e.target.value);
      console.log("boothSize_height : ", e.target.value);
    }
    if (e.target.name === "minReserved") {
      setMinReserved(e.target.value);
      console.log("minReserved : ", e.target.value);
    }
    if (e.target.name === "maxReserved") {
      setMaxReserved(e.target.value);
      console.log("maxReserved : ", e.target.value);
    }
    if (e.target.name === "minFuture_Reserved") {
      setMinFutureReserved(e.target.value);
      console.log("minFuture_Reserved : ", e.target.value);
    }
    if (e.target.name === "maxFuture_Reserved") {
      setMaxFutureReserved(e.target.value);
      console.log("maxFuture_Reserved : ", e.target.value);
    }
    if (e.target.name === "booth_price") {
      setBooth_price(e.target.value);
      console.log("booth_price : ", e.target.value);
    }
    if (e.target.name === "avaiZone") {
      setAvaiZone(e.target.value);
      console.log("avaiZone : ", e.target.value);
    }
  };
  const handleminFuture_Reserved = (e) => {
    setMinFutureReserved(e);
    console.log("min_Future_Reserved : ", e);
  };
  const handlemaxFuture_Reserved = (e) => {
    setMaxFutureReserved(e);
    console.log("max_Future_Reserved : ", e);
  };
  const handleminReserved = (e) => {
    setMinReserved(e);
    console.log("minReserved : ", e);
  };
  const handlemaxReserved = (e) => {
    setMaxReserved(e);
    console.log("maxReserved : ", e);
  };
  function onswitchChange(checked) {
    console.log(checked);
    setRequired_approval(checked);
  }

  if (businessType === null) {
    return (
      <div
        id="loading_div"
        style={{ top: "50%", left: "50%", position: "absolute" }}
      >
        <Loader active size="medium">
          Loading...
        </Loader>
      </div>
    );
  }

  return (
    <>
      <div
        style={{
          backgroundColor: "white",
          display: "block",
          marginLeft: "auto",
          marginRight: "auto",
          borderRadius: "12px",
          padding: "5px",
          fontFamily: "'Kanit', sans-serif",
        }}
      >
        <div
          style={{
            color: "#5ABBAD",
            fontSize: "24px",
            fontWeight: "bold",
            marginTop: "23px",
            textAlign: "left",
          }}
        >
          สร้างโซน
        </div>
        <div
          style={{
            textAlign: "left",
            overflow: "scroll",
            height: "780px",
            overflowX: "visible",
            marginTop: "20px",
          }}
        >
          <div style={{ marginRight: "10px" }}>
            <div
              style={{ fontSize: "15px", color: "#869594", fontWeight: "bold" }}
            >
              เพิ่มรูปภาพ
            </div>
            <div>
              <Form
                name="basic"
                labelCol={{ span: 8 }}
                wrapperCol={{ span: 24 }}
                requiredMark
                form={form}
                layout="vertical"
                onFinish={onFinish}
              >
                <div style={{ textAlign: "left", marginTop: "17px" }}>
                  <Form.Item name="uploadFileZone">
                    <Upload
                      // listType="picture"
                      listType="picture-card"
                      name="uploadFileZone"
                      onChange={(e) => handleImageChangeZone(e)}
                      beforeUpload={(file) => {
                        if (file.size > 6000000) {
                          message.error(`${file.name} มีขนาดเกิน 6 MB`);
                        }
                        return file.size < 6000000 ? false : Upload.LIST_IGNORE;
                      }}
                      maxCount={1}
                    >
                      <div>
                        <PlusOutlined
                          style={{
                            fontSize: "14px",
                            fontWeight: "bold",
                            color: "#5ABBAD",
                          }}
                        />
                        &nbsp;อัพโหลด
                      </div>
                    </Upload>
                  </Form.Item>
                </div>
                <div style={{ marginTop: "20px" }}>
                  <Form.Item
                    name="zoneName"
                    label="ตั้งชื่อโซน"
                    style={{ textAlign: "left" }}
                    rules={[{ required: true, message: "กรุณาระบุชื่อโซน" }]}
                  >
                    <Input
                      placeholder="โซนพาวิลเลี่ยน, เปิดท้าย"
                      name="zoneName"
                      value={zoneName}
                      onChange={(e) => handleChange(e)}
                      style={{
                        borderBottom: "2px solid #F2F2F2",
                        fontFamily: "'Kanit', sans-serif",
                      }}
                      bordered={false}
                    />
                  </Form.Item>

                  <Collapse defaultActiveKey={["1"]}>
                    <Panel header="สิ่งอำนวยความสะดวก" key="1">
                      <Form.Item name="amenity">
                        <Checkbox.Group
                          style={{ width: "100%" }}
                          defaultValue={checkedValue}
                          onChange={onChangeConvinience}
                        >
                          <div
                            style={{ marginTop: "10px", marginBottom: "10px" }}
                          >
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                              }}
                            >
                              <div
                                style={{ color: "#5D6968", fontSize: "16px" }}
                              >
                                {" "}
                                โต๊ะ
                              </div>
                              <div>
                                <Checkbox value="โต๊ะ"></Checkbox>
                              </div>
                            </div>
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                                marginTop: "36px",
                              }}
                            >
                              <div
                                style={{ color: "#5D6968", fontSize: "16px" }}
                              >
                                {" "}
                                สายไฟ
                              </div>
                              <div>
                                <Checkbox value="สายไฟ"></Checkbox>
                              </div>
                            </div>
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                                marginTop: "36px",
                              }}
                            >
                              <div
                                style={{ color: "#5D6968", fontSize: "16px" }}
                              >
                                {" "}
                                เก้าอี้
                              </div>
                              <div>
                                <Checkbox value="เก้าอี้"></Checkbox>
                              </div>
                            </div>
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                                marginTop: "36px",
                              }}
                            >
                              <div
                                style={{ color: "#5D6968", fontSize: "16px" }}
                              >
                                {" "}
                                ปลั๊กไฟ
                              </div>
                              <div>
                                <Checkbox value="ปลั๊กไฟ"></Checkbox>
                              </div>
                            </div>
                          </div>
                        </Checkbox.Group>
                      </Form.Item>
                    </Panel>
                  </Collapse>

                  <Divider />
                  <Form.Item
                    name="floor"
                    label="ระบุชั้น"
                    style={{ textAlign: "left" }}
                    rules={[{ required: true, message: "กรุณาระบุชั้น" }]}
                  >
                    <Input
                      placeholder="B, G, 1"
                      name="floor"
                      value={floor}
                      onChange={(e) => handleChange(e)}
                      style={{
                        borderBottom: "2px solid #F2F2F2",
                        fontFamily: "'Kanit', sans-serif",
                      }}
                      bordered={false}
                    />
                  </Form.Item>
                  <Form.Item
                    label="รูปแบบการจอง"
                    rules={[
                      { required: true, message: "กรุณาเลือกรูปแบบการจอง" },
                    ]}
                    name="reserveType"
                  >
                    <Radio.Group
                      onChange={reserveTypeOnChange}
                      value={reserveType}
                    >
                      <Radio.Button value="วัน">รายวัน</Radio.Button>
                      <Radio.Button value="เดือน">รายเดือน</Radio.Button>
                    </Radio.Group>
                  </Form.Item>
                  <Form.Item
                    label="สัญญาในพื้นที่ที่เปิดรับ"
                    rules={[
                      { required: true, message: "กรุณาเลือกรูปแบบสัญญา" },
                    ]}
                    name="contactType"
                  >
                    <Radio.Group
                      onChange={contactTypeOnChange}
                      value={contactType}
                    >
                      <Radio.Button value="ระยะสั้น">ระยะสั้น</Radio.Button>
                      <Radio.Button value="ระยะยาว">ระยะยาว</Radio.Button>
                    </Radio.Group>
                  </Form.Item>
                  <Form.Item
                    label="รูปแบบพื้นที่"
                    rules={[
                      { required: true, message: "กรุณาเลือกรูปแบบพื้นที่" },
                    ]}
                    name="areaType"
                  >
                    <Radio.Group onChange={areaTypeOnChange} value={areaType}>
                      <Radio.Button value="indoor">indoor</Radio.Button>
                      <Radio.Button value="outdoor">outdoor</Radio.Button>
                    </Radio.Group>
                  </Form.Item>
                  <Form.Item
                    name="checkbox-group"
                    label="รูปแบบร้านค้าที่สามารถจองได้"
                  >
                    <div style={{ color: "#869594", fontSize: "14px" }}>
                      สามารถเลือกได้มากกว่า 1 รายการ
                    </div>
                    <div style={{ marginTop: "10px" }}>
                      <Form.Item
                        name="checkedList"
                        rules={[
                          { required: true, message: "กรุณาระบุรูปแบบร้านค้า" },
                        ]}
                      >
                        <CheckboxGroup
                          options={businessType}
                          value={checkedList}
                          onChange={onChangeTypeBusiness}
                        />
                      </Form.Item>
                    </div>
                  </Form.Item>
                  <Divider />
                  <Form.Item
                    label="ขนาดต่อล็อก"
                    style={{ marginBottom: 0 }}
                    rules={[
                      { required: true, message: "กรุณาระบุขนาดต่อล็อก" },
                    ]}
                  >
                    <Form.Item
                      style={{
                        display: "inline-block",
                        width: "calc(50% - 12px)",
                      }}
                      name="boothSize_width"
                      rules={[
                        { required: true, message: "กรุณาระบุขนาดต่อล็อก" },
                      ]}
                    >
                      <Input
                        placeholder="100ตร.ม"
                        name="boothSize_width"
                        value={boothSize_width}
                        onChange={(e) => handleChange(e)}
                        style={{
                          borderBottom: "2px solid #F2F2F2",
                          fontFamily: "'Kanit', sans-serif",
                        }}
                        bordered={false}
                      />
                    </Form.Item>
                    <span
                      style={{
                        display: "inline-block",
                        width: "24px",
                        lineHeight: "32px",
                        textAlign: "center",
                      }}
                    >
                      x
                    </span>
                    <Form.Item
                      style={{
                        display: "inline-block",
                        width: "calc(50% - 12px)",
                      }}
                      name="boothSize_height"
                      rules={[
                        { required: true, message: "กรุณาระบุขนาดต่อล็อก" },
                      ]}
                    >
                      <Input
                        placeholder="100ตร.ม"
                        name="boothSize_height"
                        value={boothSize_height}
                        onChange={(e) => handleChange(e)}
                        style={{
                          borderBottom: "2px solid #F2F2F2",
                          fontFamily: "'Kanit', sans-serif",
                        }}
                        bordered={false}
                      />
                    </Form.Item>

                    <Form.Item
                      name="avaiZone"
                      label="ล็อกว่าง"
                      style={{ textAlign: "left" }}
                      rules={[
                        { required: true, message: "กรุณาระบุจำนวนล็อกว่าง" },
                        {
                          pattern: new RegExp(/^[0-9]+$/),
                          message: "ระบุเป็นตัวเลข",
                        },
                      ]}
                    >
                      <Input
                        placeholder="100"
                        name="avaiZone"
                        value={avaiZone}
                        onChange={(e) => handleChange(e)}
                        style={{
                          borderBottom: "2px solid #F2F2F2",
                          fontFamily: "'Kanit', sans-serif",
                        }}
                        bordered={false}
                      />
                    </Form.Item>

                    <Form.Item
                      name="booth_price"
                      label="ราคาต่อล็อค"
                      style={{ textAlign: "left" }}
                      rules={[
                        { required: true, message: "กรุณาราคา" },
                        {
                          pattern: new RegExp(/^[0-9]+$/),
                          message: "ระบุเป็นตัวเลข",
                        },
                      ]}
                    >
                      <Input
                        placeholder="0.00"
                        name="booth_price"
                        value={booth_price}
                        suffix={reserveType === "เดือน" ? "ต่อเดือน" : "ต่อวัน"}
                        onChange={(e) => handleChange(e)}
                        style={{
                          borderBottom: "2px solid #F2F2F2",
                          fontFamily: "'Kanit', sans-serif",
                        }}
                        bordered={false}
                      />
                    </Form.Item>
                  </Form.Item>
                  <Row>
                    <Col span={12}>
                      <div style={{ marginBottom: "10px" }}>
                        จำนวนวันจองล่วงหน้าขั้นต่ำ
                      </div>
                      <Form.Item
                        rules={[
                          {
                            pattern: new RegExp(/^[0-9]+$/),
                            message: "ระบุเป็นตัวเลข",
                          },
                        ]}
                        style={{ width: "50%" }}
                        name="minFuture_Reserved"
                      >
                        <Input
                          name="minFuture_Reserved"
                          value={minFuture_Reserved}
                          onChange={(e) =>
                            handleminFuture_Reserved(e.target.value)
                          }
                          suffix={
                            <CalendarOutlined style={{ color: "#5ABBAD" }} />
                          }
                        />
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <div style={{ marginBottom: "10px" }}>
                        จำนวนวันจองล่วงหน้าสูงสุด
                      </div>
                      <Form.Item
                        rules={[
                          {
                            pattern: new RegExp(/^[0-9]+$/),
                            message: "ระบุเป็นตัวเลข",
                          },
                        ]}
                        style={{ width: "50%" }}
                        name="maxFuture_Reserved"
                      >
                        <Input
                          name="maxFuture_Reserved"
                          value={maxFuture_Reserved}
                          onChange={(e) =>
                            handlemaxFuture_Reserved(e.target.value)
                          }
                          suffix={
                            <CalendarOutlined style={{ color: "#5ABBAD" }} />
                          }
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row>
                    <Col span={12}>
                      <div style={{ marginBottom: "10px" }}>
                        จำนวนวันขั้นต่ำในการจอง
                      </div>
                      <Form.Item
                        name="minReserved"
                        style={{ width: "50%" }}
                        rules={[
                          {
                            pattern: new RegExp(/^[0-9]+$/),
                            message: "ระบุเป็นตัวเลข",
                          },
                        ]}
                      >
                        <Input
                          value={minReserved}
                          name="minReserved"
                          onChange={(e) => handleminReserved(e.target.value)}
                          suffix={
                            <CalendarOutlined style={{ color: "#5ABBAD" }} />
                          }
                        />
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <div style={{ marginBottom: "10px" }}>
                        จำนวนวันสูงสุดในการจอง
                      </div>
                      <Form.Item
                        style={{ width: "50%" }}
                        rules={[
                          {
                            pattern: new RegExp(/^[0-9]+$/),
                            message: "ระบุเป็นตัวเลข",
                          },
                        ]}
                        name="maxReserved"
                      >
                        <Input
                          value={maxReserved}
                          name="maxReserved"
                          onChange={(e) => handlemaxReserved(e.target.value)}
                          suffix={
                            <CalendarOutlined style={{ color: "#5ABBAD" }} />
                          }
                        />
                      </Form.Item>
                    </Col>
                  </Row>

                  <div style={{ marginTop: "10px" }}>
                    <div>
                      ตรวจสอบการจอง{" "}
                      <Switch
                        style={{ marginLeft: "15px" }}
                        defaultChecked={true}
                        onChange={onswitchChange}
                        value={required_approval}
                      />
                    </div>
                    <p style={{ marginTop: "10px", color: "#869594" }}>
                      การจองที่ต้องตรวจสอบก่อนยืนยันให้ร้านค้าเข้าพื้นที่
                    </p>
                  </div>

                  <Divider />

                  <Upload
                    onChange={(e) => handleImageChangeFloor(e)}
                    beforeUpload={(file) => {
                      if (file.size > 6000000) {
                        message.error(`${file.name} มีขนาดเกิน 6 MB`);
                      }
                      return file.size < 6000000 ? false : Upload.LIST_IGNORE;
                    }}
                    listType="picture"
                    name="uploadPictureFloor"
                    maxCount={1}
                  >
                    <div
                      style={{
                        color: "#18A0FB",
                        fontSize: "18px",
                        cursor: "pointer",
                      }}
                    >
                      +เพิ่มภาพ Floorplan
                    </div>
                  </Upload>
                </div>

                <div style={{ marginTop: "45px" }}>
                  <Button
                    size="large"
                    htmlType="submit"
                    loading={onLoading}
                    style={{
                      fontSize: "18px",
                      backgroundColor: "#5ABBAD",
                      color: "white",
                      borderRadius: "6px",
                      width: "100%",
                      fontFamily: "'Kanit', sans-serif",
                    }}
                  >
                    ยืนยัน
                  </Button>
                </div>
              </Form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
