import React from "react";
import { BrowserRouter, Switch } from "react-router-dom";
import Router from "routes/Router";
import { QueryClient, QueryClientProvider, useQuery } from "react-query";
import Layout from "common/components/layout";
import ModalModule from "common/components/modal/modals";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export default function App() {
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        retry: false,
        refetchOnWindowFocus: false,
      },
    },
  });

  return (
    <QueryClientProvider client={queryClient}>
      <Layout>
        <ModalModule />
        <BrowserRouter>
          <Router />
        </BrowserRouter>
        <ToastContainer
          position="top-center"
          autoClose={5000}
          hideProgressBar
          theme="colored"
          style={{ minHeight: 0 }}
        />
      </Layout>
    </QueryClientProvider>
  );
}
