import React, { useState } from "react";
import { Button, Form, Input, Modal } from "antd";
import "../css/admin.css";
import { EyeInvisibleOutlined, EyeTwoTone } from "@ant-design/icons";
import { Icon } from "semantic-ui-react";
import * as userService from "services/user.service";
import { useNavigate } from "react-router-dom";

export default function ResetPassword({ setStep }) {
  const [password, setPassword] = useState(null);
  const [repassword, setRepassword] = useState(null);
  const [onLoading, setOnloading] = useState(false);
  const navigate = useNavigate();

  const onFinish = () => {
    setOnloading(true);

    if (password === null || repassword === null) {
      return (
        Modal.error({
          title: "ผิดพลาด",
          content: "กรุณากรอกข้อมูลให้ครบทุกช่อง",
        }),
        setOnloading(false)
      );
    } else if (repassword === "" || password === "") {
      return (
        Modal.error({
          title: "ผิดพลาด",
          content: "กรุณากรอกข้อมูลให้ครบทุกช่อง",
        }),
        setOnloading(false)
      );
    } else if (password.length < 8) {
      return (
        Modal.error({
          title: "ผิดพลาด",
          content: "รหัสผ่านต้องมีอย่างน้อย 8 ตัวอักษร",
        }),
        setOnloading(false)
      );
    } else if (!/[0-9]/.test(password)) {
      return (
        Modal.error({
          title: "ผิดพลาด",
          content: "รหัสผ่านต้องมีเลข",
        }),
        setOnloading(false)
      );
    } else if (!/[-!$%^&*()_+|~=`{}\[\]:\/;<>?,.@#]/.test(password)) {
      return (
        Modal.error({
          title: "ผิดพลาด",
          content: "รหัสผ่านต้องมีอักขระพิเศษ",
        }),
        setOnloading(false)
      );
    } else if (!/[A-Z]/.test(password)) {
      return (
        Modal.error({
          title: "ผิดพลาด",
          content: "รหัสผ่านต้องมีตัวพิมพ์ใหญ่",
        }),
        setOnloading(false)
      );
    } else if (!/[a-z]/.test(password)) {
      return (
        Modal.error({
          title: "ผิดพลาด",
          content: "รหัสผ่านต้องมีตัวพิมพ์เล็ก",
        }),
        setOnloading(false)
      );
    } else if (password !== repassword) {
      return (
        Modal.error({
          title: "ผิดพลาด",
          content: "รหัสผ่านไม่ตรงกัน",
        }),
        setOnloading(false)
      );
    } else {
      const { confirm } = Modal;
      var data = {
        password: password,
      };
      return new Promise((resolve, reject) => {
        confirm({
          title: "คุณยืนยันการเปลี่ยนรหัสผ่าน?",
          onOk: () => {
            async function update() {
              try {
                const update = await userService.updateUserInfo(data);
                localStorage.removeItem("token_reset_password");
                localStorage.removeItem("encrypted_code");
                localStorage.removeItem("ref_code");
                localStorage.removeItem("email");

                return Modal.success({
                  content: "เปลี่ยนรหัสสำเร็จ",
                  onOk: () => {
                    navigate("/login");
                  },
                });
              } catch (error) {
                console.log(error.response);
                setOnloading(false);
                Modal.error({
                  title: "ผิดพลาด",
                  content: error.response.data.message,
                });
              }
            }
            update();
          },
          onCancel: () => {
            reject();
            setOnloading(false);
          },
        });
      });
    }
  };

  const passwordDetail = (iconName, color, detail) => {
    return (
      <div style={{ marginTop: "8px" }}>
        <Icon name={iconName} style={{ color: color }} />
        {detail}
      </div>
    );
  };

  return (
    <>
      <Form
        name="basic"
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 24 }}
        requiredMark
        layout="vertical"
        onFinish={onFinish}
      >
        <div style={{ marginTop: "42px" }}>
          <Form.Item
            bordered={false}
            name="password"
            label="รหัสผ่าน"
            rules={[{ required: true, message: "กรุณากรอกรหัสผ่าน" }]}
          >
            <Input.Password
              bordered={false}
              value={password}
              style={{
                borderBottom: "2px solid #F2F2F2",
                fontFamily: "'Kanit', sans-serif",
              }}
              placeholder="กรอกรหัสผ่าน"
              onChange={(e) => setPassword(e.target.value)}
              name="password"
              iconRender={(visible) =>
                visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
              }
            />
          </Form.Item>
        </div>
        <div
          style={{
            marginTop: "28px",
            color: "6D7A79",
            fontSize: "14px",
            textAlign: "left",
          }}
        >
          {passwordDetail(
            password !== null
              ? password.length >= 8
                ? "check circle"
                : "info circle"
              : "info circle",
            password !== null
              ? password.length >= 8
                ? "#5ABBAD"
                : "gray"
              : "gray",
            "รหัสผ่านต้องมีอย่างน้อย 8 ตัวอักษร"
          )}
          {passwordDetail(
            password !== null
              ? /[0-9]/.test(password)
                ? "check circle"
                : "info circle"
              : "info circle",
            password !== null
              ? /[0-9]/.test(password)
                ? "#5ABBAD"
                : "gray"
              : "gray",
            "มีตัวเลขอยู่ในรหัสผ่าน (0-9)"
          )}
          {passwordDetail(
            password !== null
              ? /[-!$%^&*()_+|~=`{}\[\]:\/;<>?,.@#]/.test(password)
                ? "check circle"
                : "info circle"
              : "info circle",
            password !== null
              ? /[-!$%^&*()_+|~=`{}\[\]:\/;<>?,.@#]/.test(password)
                ? "#5ABBAD"
                : "gray"
              : "gray",
            "มีอักขระพิเศษอย่างน้อย 1 ตัว"
          )}
          {passwordDetail(
            password !== null
              ? /[A-Z]/.test(password)
                ? "check circle"
                : "info circle"
              : "info circle",
            password !== null
              ? /[A-Z]/.test(password)
                ? "#5ABBAD"
                : "gray"
              : "gray",
            "มีตัวพิมพ์ใหญ่"
          )}
          {passwordDetail(
            password !== null
              ? /[a-z]/.test(password)
                ? "check circle"
                : "info circle"
              : "info circle",
            password !== null
              ? /[a-z]/.test(password)
                ? "#5ABBAD"
                : "gray"
              : "gray",
            "มีตัวพิมพ์เล็ก"
          )}
          {passwordDetail(
            password !== null && password !== ""
              ? password === repassword
                ? "check circle"
                : "info circle"
              : "info circle",
            password !== null && password !== ""
              ? password === repassword
                ? "#5ABBAD"
                : "gray"
              : "gray",
            "รหัสผ่านทั้ง 2 ต้องตรงกัน"
          )}
        </div>
        <div style={{ marginTop: "22px" }}>
          <Form.Item
            bordered={false}
            name="repassword"
            label="กรอกรหัสผ่านอีกครั้ง"
            rules={[{ required: true, message: "กรุณากรอกรหัสผ่านอีกครั้ง" }]}
          >
            <Input.Password
              bordered={false}
              value={repassword}
              style={{
                borderBottom: "2px solid #F2F2F2",
                fontFamily: "'Kanit', sans-serif",
              }}
              placeholder="กรอกรหัสผ่าน"
              onChange={(e) => setRepassword(e.target.value)}
              name="repassword"
              iconRender={(visible) =>
                visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
              }
            />
          </Form.Item>
        </div>
        <div style={{ marginTop: "40px" }}>
          <Button
            type="primary"
            htmlType="submit"
            block
            style={{
              width: "100%",
              height: "3rem",
              fontSize: "18px",
              backgroundColor: "#ED4556",
              color: "white",
              borderRadius: "6px",
              fontFamily: "'Kanit', sans-serif",
              border: "transparent",
            }}
            loading={onLoading}
          >
            ยืนยัน
          </Button>
        </div>
      </Form>
    </>
  );
}
