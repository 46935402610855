import React, { useState, useEffect } from "react";
import { Segment, Icon, Image } from "semantic-ui-react";
import { Button, Form, Input, Row, Col, Modal, Popover } from "antd";
import "../css/admin.css";
import {
  EyeInvisibleOutlined,
  EyeTwoTone,
  ArrowLeftOutlined,
} from "@ant-design/icons";
import axiosAPI from "assets/js/axiosApi";
import { useNavigate } from "react-router-dom";

export default function RegisterItem(props) {
  const [firstName, setfirstName] = useState(null);
  const [lastName, setlastName] = useState(null);
  const [email, setEmail] = useState(null);
  const [password, setPassword] = useState(null);
  const [repassword, setRepassword] = useState(null);
  const [onLoading, setOnloading] = useState(false);

  const navigate = useNavigate();

  const onFinish = () => {
    setOnloading(true);
    if (password !== repassword) {
      return onFinishFailed("รหัสผ่านไม่ตรงกัน");
    } else if (!/[a-z]/.test(password)) {
      return onFinishFailed("รหัสผ่านต้องมีตัวพิมพ์เล็ก");
    } else if (!/[A-Z]/.test(password)) {
      return onFinishFailed("รหัสผ่านต้องมีตัวพิมพ์ใหญ่");
    } else if (!/[-!$%^&*()_+|~=`{}\[\]:\/;<>?,.@#]/.test(password)) {
      return onFinishFailed("รหัสผ่านต้องมีอักขระ");
    } else if (!/[0-9]/.test(password)) {
      return onFinishFailed("รหัสผ่านต้องมีตัวเลข");
    }
    const Registerdata = {
      password: password,
      email: email,
      first_name: firstName,
      last_name: lastName,
    };

    axiosAPI
      .post("/v1/user/register", Registerdata)
      .then((response) => {
        console.log(response);
        localStorage.setItem("user_id", response.data.data.id);
        const loginData = {
          email: email,
          password: password,
        };
        axiosAPI
          .post("/v1/auth/login", loginData)
          .then((response) => {
            console.log(response);
            localStorage.setItem("token", response.data.data.token);
            localStorage.setItem(
              "refresh_token",
              response.data.data.refresh_token
            );
            localStorage.setItem(
              "malls_access",
              JSON.stringify(response.data.data.malls_access)
            );

            navigate("/space/setting/named");
          })
          .catch((error) => {
            setOnloading(false);
            console.log(error);
          });
      })
      .catch((error) => {
        console.log(error.response);
        onFinishFailed(error.response.data.message);
      });
  };
  const onFinishFailed = (failed) => {
    return (
      Modal.error({
        title: "ผิดพลาด",
        content: failed,
      }),
      setOnloading(false)
    );
  };

  const handleChange = (e) => {
    if (e.target.name === "regis_firstname") {
      setfirstName(e.target.value);
      console.log("firstname", e.target.value);
    }
    if (e.target.name === "regis_lastname") {
      setlastName(e.target.value);
      console.log("lastname", e.target.value);
    }
    if (e.target.name === "regis_email") {
      setEmail(e.target.value);
      console.log("email", e.target.value);
    }
    if (e.target.name === "regis_password") {
      setPassword(e.target.value);
      console.log("password", e.target.value);
    }
    if (e.target.name === "regis_re-password") {
      setRepassword(e.target.value);
      console.log("re-password", e.target.value);
    }
  };

  const passwordRecommented = (
    <ul>
      <li>รหัสผ่านควรมีอย่างน้อย 8 ตัวอักษร</li>
      <li>ควรมีตัวเลขอยู่ในรหัสผ่าน (0-9)</li>
      <li>ควรมีอักขระพิเศษอย่างน้อย 1 ตัว</li>
      <li>ควรมีตัวพิมพ์ใหญ่</li>
      <li>ควรมีตัวพิมพ์เล็ก</li>
    </ul>
  );

  return (
    <>
      <div>
        <div
          style={{
            color: "#5ABBAD",
            fontSize: "24px",
            fontWeight: "bold",
            textAlign: "left",
          }}
        >
          <ArrowLeftOutlined onClick={() => navigate("/login")} />
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            paddingTop: "15px",
          }}
        >
          <div
            style={{ color: "#5ABBAD", fontSize: "24px", fontWeight: "bold" }}
          >
            สมัครสมาชิก
          </div>
          <div style={{ color: "#5ABBAD", fontSize: "14px" }}>
            <Popover
              content={passwordRecommented}
              title="แนะนำการสร้างพาสเวิร์ด"
              trigger="hover"
            >
              <object
                style={{
                  color: "#999999",
                  textDecoration: "underline",
                  cursor: "pointer",
                }}
              >
                แนะนำการสร้างพาสเวิร์ด
              </object>
            </Popover>
          </div>
        </div>
        <div style={{ marginTop: "42px" }}>
          <Form requiredMark layout="vertical" onFinish={onFinish}>
            <Row>
              <Col span={11}>
                <Form.Item
                  label="ชื่อจริง"
                  name="regis_firstname"
                  style={{ textAlign: "left" }}
                  rules={[{ required: true, message: "กรุณากรอกชื่อจริง" }]}
                >
                  <Input
                    placeholder="กรอกชื่อจริง"
                    style={{
                      borderBottom: "2px solid #F2F2F2",
                      fontFamily: "'Kanit', sans-serif",
                    }}
                    bordered={false}
                    name="regis_firstname"
                    onChange={(e) => handleChange(e)}
                  />
                </Form.Item>
              </Col>
              <Col span={2}></Col>
              <Col span={11}>
                <Form.Item
                  label="นามสกุล"
                  name="regis_lastname"
                  style={{ textAlign: "left" }}
                  rules={[{ required: true, message: "กรุณากรอกนามสกุล" }]}
                >
                  <Input
                    placeholder="กรอกนามสกุล"
                    style={{
                      borderBottom: "2px solid #F2F2F2",
                      fontFamily: "'Kanit', sans-serif",
                    }}
                    bordered={false}
                    name="regis_lastname"
                    onChange={(e) => handleChange(e)}
                  />
                </Form.Item>
              </Col>
            </Row>

            <div>
              <Form.Item
                name="regis_email"
                label="อีเมล"
                style={{ textAlign: "left" }}
                rules={[{ required: true, message: "กรุณากรอกอีเมล!" }]}
              >
                <Input
                  placeholder="กรอกอีเมล"
                  style={{
                    borderBottom: "2px solid #F2F2F2",
                    fontFamily: "'Kanit', sans-serif",
                  }}
                  bordered={false}
                  type="email"
                  name="regis_email"
                  onChange={(e) => handleChange(e)}
                />
              </Form.Item>
              <Row>
                <Col span={11}>
                  <Form.Item
                    bordered={false}
                    style={{ textAlign: "left" }}
                    label="พาสเวิร์ด"
                    name="regis_password"
                    rules={[{ required: true, message: "กรุณากรอกพาสเวิร์ด!" }]}
                  >
                    <Input.Password
                      bordered={false}
                      style={{
                        borderBottom: "2px solid #F2F2F2",
                        fontFamily: "'Kanit', sans-serif",
                      }}
                      placeholder="กรอกพาสเวิร์ด"
                      iconRender={(visible) =>
                        visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                      }
                      name="regis_password"
                      maxLength={20}
                      minLength={8}
                      onChange={(e) => handleChange(e)}
                    />
                  </Form.Item>
                </Col>
                <Col span={2}></Col>
                <Col span={11}>
                  <Form.Item
                    bordered={false}
                    style={{ textAlign: "left" }}
                    label="รี-พาสเวิร์ด"
                    name="regis_re-password"
                    rules={[{ required: true, message: "กรุณากรอกพาสเวิร์ด!" }]}
                  >
                    <Input.Password
                      bordered={false}
                      style={{
                        borderBottom: "2px solid #F2F2F2",
                        fontFamily: "'Kanit', sans-serif",
                      }}
                      placeholder="กรอกพาสเวิร์ดอีกครั้ง"
                      onChange={(e) => handleChange(e)}
                      name="regis_re-password"
                      maxLength={20}
                      minLength={8}
                      iconRender={(visible) =>
                        visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                      }
                    />
                  </Form.Item>
                </Col>
              </Row>
            </div>
            <div style={{ marginTop: "40px" }}>
              <Button
                type="primary"
                loading={onLoading}
                htmlType="submit"
                block
                style={{
                  height: "50px",
                  fontSize: "18px",
                  backgroundColor: "#5ABBAD",
                  color: "white",
                  borderRadius: "6px",
                  fontFamily: "'Kanit', sans-serif",
                  border: "transparent",
                }}
              >
                ลงทะเบียน
              </Button>
            </div>
          </Form>
        </div>
        <div style={{ color: "gray", marginTop: "25px" }}>
          By click the button above, you agree to our term of use and privacy
          policies
        </div>
      </div>
    </>
  );
}
