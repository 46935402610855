import loadable from "@loadable/component";
import { Navigate, Outlet } from "react-router-dom";

const Layout = loadable(() => import("modules/report/layout"));
const Home = loadable(() => import("pages/report"));
const Booking = loadable(() => import("pages/report/booking"));
const Space = loadable(() => import("pages/report/space"));
const Campaign = loadable(() => import("pages/report/recruit/campaign"));
const PlatformOp = loadable(() => import("pages/report/recruit/campaign/cp-platform-of-oppotunity"));

const reportRoute = [
  {
    path: "/report",
    element: <Layout />,
    children: [
      { index: true, element: <Home /> },
      {
        path: "space",
        children: [{ index: true, element: <Space /> }],
      },
      {
        path: "booking",
        children: [{ index: true, element: <Booking /> }],
      },
      {
        path: "recruit",
        children: [
          { index: true, element: <Navigate to="/report" /> },
          {
            path: "campaign",
            children: [
              { index: true, element: <Campaign /> },
              { path: "cp-platform-of-oppotunity", element: <PlatformOp /> },
            ],
          },
        ],
      },
    ],
  },
];

export default reportRoute;
