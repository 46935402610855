import { axios, HeaderDefault, GetEnvironment } from "services/base.service";

export const getShopInfo = (shop_id) => {
  return axios({
    url: `/v1/shop/info`,
    method: "get",
    params: { shop_id },
    ...HeaderDefault({}),
  }).then((res) => {
    let data = res?.data?.data;
    data.images = data.images.map((e) => {
      return {
        ...e,
        name: e.image_name,
      };
    });
    data.documents = data.documents.map((e) => {
      return {
        ...e,
        name: e.document_name ?? "untitled_file",
      };
    });
    return data;
  });
};

export const getBusinessType = () => {
  return axios({
    url: `/v1/shop/business/type`,
    method: "get",
    ...HeaderDefault({}),
  }).then((res) => res?.data?.data);
};

export const getDocumentCompress = (shop_id) => {
  return axios({
    url: `/v1/shop/document/compress`,
    method: "get",
    params: { shop_id },
    ...HeaderDefault({}),
  }).then((res) => res?.data?.data);
};
