import create from "zustand";
import {
  getApplicationStatus,
  STATUS,
} from "modules/opportunity/application/constants";
import _ from "lodash";

const useStore = create((set) => ({
  application: {},
  setApplication: (application) =>
    set({ application: application, product: application?.product }),
  newApplication: {},
  setNewApplication: (newApplication) =>
    set((e) => ({
      newApplication: { ...(e.newApplication || {}), ...newApplication },
    })),
  initialApplicationData: (application) =>
    set((e) => ({
      application: { ...application },
      newApplication: { ...application },
      product: {
        ...application?.product,
        description: application?.product?.description ?? "",
        standard: _.keys(application?.product?.standard),
        ...(application?.product?.category_detail
          ?.category_vegetable_fruit_item && {
          category_detail: {
            category_vegetable_fruit_item:
              application?.product?.category_detail
                ?.category_vegetable_fruit_item ?? [],
          },
        }),
      },
      timeSlot: {
        ...application?.time_slot_available,
        date: application?.time_slot_available?.from_datetime,
      },
      applicationStatus: getApplicationStatus({
        role: e.role,
        status: application?.status,
      }),
      editable:
        STATUS?.[
          getApplicationStatus({
            role: e.role,
            status: application?.status,
          })
        ]?.editable,
    })),
  role: "company_admin",
  setRole: (role) => set({ role: role }),
  editMode: false,
  setEditMode: (editMode) => set({ editMode: editMode }),
  product: {},
  setProduct: (product) => set({ product: product }),
  timeSlot: {},
  setTimeSlot: (timeSlot) =>
    set((e) => ({ timeSlot: { ...(e.timeSlot || {}), ...timeSlot } })),
  shop: {},
  setShop: (shop) => set({ shop: shop }),
  user: {},
  setUser: (user) => set({ user: user }),
  status: null,
  setStatus: (status) => set({ status: status }),
  rejectReason: null,
  setRejectReason: (rejectReason) => set({ rejectReason: rejectReason }),
  applicationStatus: "",
  setApplicationStatus: (applicationStatus) =>
    set({ applicationStatus: applicationStatus }),
  editable: false,
  setEditable: (editable) => set({ editable: editable }),
  purchase: "",
  setPurchase: (purchase) => set({ purchase: purchase }),
  currentTab: 0,
  setCurrentTab: (currentTab) => set({ currentTab: currentTab }),
  currentStatusTab: {
    scan: "scan_total",
    screen: "screen_date_pending",
    deal: "deal_pending",
  },
  setCurrentStatusTab: (currentStatusTab) =>
    set({ currentStatusTab: currentStatusTab }),
  ref: {},
  setRef: (ref) => set({ ref: ref }),
}));

export default useStore;
