import { axios, HeaderDefault, GetEnvironment } from "services/base.service";

export const login = (payload) => {
  return axios({
    url: `/v1/auth/login`,
    method: "post",
    data: payload,
    ...HeaderDefault({}),
  }).then((res) => {
    const user = res?.data?.data;
    return user;
  });
};

export const logout = () => {
  return axios({
    url: `/v1/auth/logout`,
    method: "post",
    ...HeaderDefault({}),
  }).then((res) => res?.data?.data);
};

export const changePassword = ({ password }) => {
  return axios({
    url: `/v1/user/update/info`,
    method: "post",
    data: { password },
    ...HeaderDefault({}),
  }).then((res) => res?.data?.data);
};

export const getOtp = ({ phone }) => {
  return axios({
    url: `/v1/user/otp/request`,
    method: "post",
    params: { phone },
    ...HeaderDefault({}),
  }).then((res) => res?.data?.data);
};
