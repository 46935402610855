import loadable from "@loadable/component";
import CategoryManagement from "pages";

// Mall Manager
import OverviewRegister from "modules/space/register/overview-register";

import SettingOnboardNamed from "modules/space/register/setting/named";
import SettingOnboardAddFloorplan from "modules/space/register/setting/add-floorplan";
import SettingOnboardCreateSpace from "modules/space/register/setting/create-space";
import SettingOnboardEditSpace from "modules/space/register/setting/edit-space";
import SettingOnboardImageUploadAddFloorplanAminities from "modules/space/register/setting/image-upload/add-floorplan-aminities";
import SettingOnboardImageUploadAddFloorplanAminitiesUpdate from "modules/space/register/setting/image-upload/add-floorplan-aminities-update";
import SettingOnboardCreateAddAvailable from "modules/space/register/setting/create-add-available";

import SpaceManagement from "modules/space/index.legacy";
import CreateSpace from "modules/space/create-space";
import AminitiesUpdate from "modules/space/aminities-update";

// Partner
const spaceRoute = [
  { path: "/space", element: <SpaceManagement /> },
  { path: "/space/create-space", element: <CreateSpace /> },
  { path: "/space/aminities-update", element: <AminitiesUpdate /> },
  {
    path: "/space/setting/named",
    element: <SettingOnboardNamed />,
  },
  {
    path: "/space/setting/add-floorplan",
    element: <SettingOnboardAddFloorplan />,
  },
  {
    path: "/space/setting/create",
    element: <SettingOnboardCreateSpace />,
  },
  {
    path: "/space/setting/edit",
    element: <SettingOnboardEditSpace />,
  },
  {
    path: "/space/setting/upload/floorplan-aminities",
    element: <SettingOnboardImageUploadAddFloorplanAminities />,
  },
  {
    path: "/space/setting/upload/floorplan-aminities-update",
    element: <SettingOnboardImageUploadAddFloorplanAminitiesUpdate />,
  },
  {
    path: "/space/setting/add/available",
    element: <SettingOnboardCreateAddAvailable />,
  },
];

export default spaceRoute;
