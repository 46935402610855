const columns = [
    {
      title: '#',
      dataIndex: 'user_id',
      fixed: 'left',
    },
    {
      title: 'ชื่อร้านค้า',
      dataIndex: 'shop_name',
      fixed: 'left',
    },
    { title: 'ประเภท', dataIndex: 'bussiness_type', key: '1' },
    { title: 'Zone', dataIndex: 'zone', key: '2' },
    { title: 'วันที่เริ่มต้นสัญญา', dataIndex: 'startDate', key: '3' },
    { title: 'วันที่สิ้นสุดสัญญา', dataIndex: 'endDate', key: '3' },

    { title: 'รูปแบบสัญญา', dataIndex: 'contact', key: '4' },
    { title: 'ค่าเช่า', dataIndex: 'payrent', key: '5', 
        sorter: { compare: (a, b) => a.payrent - b.payrent},
    },
  
    { title: 'หมายเหตุ', dataIndex: 'other', key: '5' },

];

export default columns;