import axios from "axios";

const API_URL = process.env.REACT_APP_API_URL;
const axiosInstance = axios.create({
  baseURL: API_URL,
  timeout: 5000,
  headers: {
    Authorization:
      localStorage.getItem("token") === null
        ? ""
        : "Bearer " + localStorage.getItem("token"),
    accept: "application/json",
  },
});

export default axiosInstance;

/**\
 *
'Content-Type': 'application/x-www-form-urlencoded',
'Content-Type': 'application/json',
'accept': 'application/json'
'Content-Type': 'application/x-www-form-urlencoded',
        */
