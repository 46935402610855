import React, { useState, useEffect } from "react";
import {
  Row,
  Col,
  Divider,
  Input,
  Drawer,
  Switch,
  Modal,
  Spin,
  Skeleton,
  Image,
} from "antd";
import { Icon } from "semantic-ui-react";
import axiosAPI from "assets/js/axiosApi";
import moment from "moment";
import localization from "moment/locale/th";
import "react-dates/initialize";
import { DayPickerRangeController } from "react-dates";
import "react-dates/lib/css/_datepicker.css";
import { START_DATE, END_DATE } from "react-dates/constants";
import CreateSpace from "./create-space";
import EditeSpace from "./edit-space";
import * as spaceService from "services/space.service";

moment.locale("th", localization);
const { Search } = Input;
export default function AllArea({ mallID }) {
  // API
  const [summaryAvailable, setSummaryAvailable] = useState(null);
  const [spaceAvailable, setSpaceAvailable] = useState(null);

  // Date Calendar
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [startDateShow, setStartDateShow] = useState(null);
  const [endDateShow, setEndDateShow] = useState(null);
  const [createID, setCreateID] = useState(null);
  const [focusedInput, setFocusedInput] = useState(START_DATE);

  const [calendarShow, setCalendarShow] = useState(false);
  const [chooseID, setChooseID] = useState(null);
  const [createZoneDrawer, setCreateZoneDrawer] = useState(false);
  const [editZoneDrawer, setEditZoneDrawer] = useState(false);
  const [space, setSpace] = useState(null);

  const [searchSpace, setsearchSpace] = useState(null);

  const [addAvailableDateArr, setAddAvailableDateArr] = useState([]);
  const [zonePic, setZonepic] = useState(null);
  // Intialize
  useEffect(() => {
    getSpace(mallID);
  }, [mallID]);

  // getSpace
  async function getSpace(id) {
    try {
      const getSpaceUser = await spaceService.getSpaceUser({ mall_id: id });
      setSpace(getSpaceUser);
      setsearchSpace(getSpaceUser);
      console.log("Space: ", getSpaceUser);
      if (chooseID === null && getSpaceUser.length !== 0) {
        setChooseID(getSpaceUser[0].id);
        getSpaceAvailable(getSpaceUser[0].id);
        getSpaceInfo(getSpaceUser[0].id);
      } else {
        getSpaceInfo(chooseID);
      }
    } catch (error) {
      console.log(error);
      setSpace([]);
    }
  }

  // getSpaceInfo
  async function getSpaceInfo(id) {
    try {
      const spaceInfo = await spaceService.getSpaceInfo({ space_id: id });
      setSummaryAvailable(spaceInfo);
      const zonePicture = spaceInfo.images.filter(
        (img) => img.image_type === "รูปโซน"
      );
      if (zonePicture.length > 0) {
        setZonepic(zonePicture[zonePicture.length - 1]);
      } else {
        setZonepic(null);
      }
      setChooseID(id);
      console.log("Space Info: ", spaceInfo);
    } catch (error) {
      console.log(error);
      setSummaryAvailable([]);
    }

    if (summaryAvailable === null) {
      return (
        <div
          id="loading_div"
          style={{ top: "50%", left: "50%", position: "absolute" }}
        >
          <Spin />
        </div>
      );
    }
  }

  // getSpaceAvailable
  async function getSpaceAvailable(id) {
    try {
      const spaceAvailableSummary = await spaceService.getAvailableSummary({
        space_id: id,
      });
      setSpaceAvailable(spaceAvailableSummary);
      console.log("Space Available: ", spaceAvailableSummary);
    } catch (error) {
      console.log(error);
      setSpaceAvailable([]);
    }
    if (spaceAvailable === null) {
      return (
        <div
          id="loading_div"
          style={{ top: "50%", left: "50%", position: "absolute" }}
        >
          <Spin />
        </div>
      );
    }
  }

  ////////////////////////////////////////////////////////// Calendar ////////////////////////////////////////////////////
  // Date calendar
  const availablePost = async () => {
    setCalendarShow(false);
    var data = {
      space_id: summaryAvailable.id,
      from_date: moment(startDate).format("YYYY-MM-DD"),
      to_date: moment(endDate).format("YYYY-MM-DD"),
    };
    console.log(createID);

    try {
      if (createID === null) {
        await spaceService.postAddRangeSpace(data);
      } else {
        data["create_id"] = createID;
        await spaceService.postAddRangeSpace(data);
      }
      setCalendarShow(false);
      getSpaceAvailable(chooseID);
      Modal.success({
        content: "เปลี่ยนแปลงวันที่สำเร็จ",
      });
    } catch (error) {
      console.log(error.response);
      Modal.error({
        content: error.response.data.message,
      });
    }
  };
  const handleDatesChange = ({ startDate, endDate }) => {
    setStartDate(startDate);
    setEndDate(endDate);

    if (endDate === null) {
      setEndDate(startDate);
    } else {
      setEndDate(endDate);
    }
    console.log(moment(startDate).format("YYYY-MM-DD"));
    console.log(moment(endDate).format("YYYY-MM-DD"));
  };

  function toSetAvailableandEditDate(from_date, to_date, create_id) {
    console.log(create_id);
    setCalendarShow(true);
    setStartDateShow(moment(from_date));
    setEndDateShow(moment(to_date));
    setStartDate(moment(from_date));
    setEndDate(moment(to_date));
    setCreateID(create_id);
  }
  function toAddNewAvailableandEditDate() {
    setCreateID(null);
    setCalendarShow(true);
    setStartDateShow(null);
    setEndDateShow(null);
    setStartDate(null);
    setEndDate(null);
  }
  // Add more available date
  const addAvailableDate = () => {
    if (addAvailableDateArr.length <= 2) {
      setAddAvailableDateArr((addAvailableDateArr) => [
        ...addAvailableDateArr,
        listItems,
      ]);
    }
  };
  // Block date
  const isOutsideRange = (day) => day.isBefore(moment());
  ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

  // When choose space will reset value and set new value
  function chooseSpace(id) {
    getSpaceInfo(id);
    getSpaceAvailable(id);
    setAddAvailableDateArr([]);
    setStartDate(null);
    setEndDate(null);
    setStartDateShow(null);
    setStartDateShow(null);
    setEndDateShow(null);
  }

  // For switch to which space wanna change to active
  async function onActive(checked, event, id) {
    console.log(`switch to ${checked}`);
    var updateActive = {
      id: id,
      is_active: checked,
    };
    try {
      await spaceService.postSpaceUpdate(updateActive);
    } catch (error) {
      console.log(error);
    }
  }

  // handleSearch
  function spaceSearch(value) {
    console.log(value);
    if (value === "" || value === null) {
      getSpace(mallID);
    }
    var valueSearch = value.toLowerCase();
    var filterSearch = space.filter((st) =>
      st.name.toLowerCase().includes(valueSearch)
    );
    setsearchSpace(filterSearch);
    if (filterSearch.length !== 0) {
      setSpace(filterSearch);
      getSpaceInfo(filterSearch[0].id);
      getSpaceAvailable(filterSearch[0].id);
    }
  }

  const deleteSpace = async (create_id) => {
    try {
      await spaceService.deleteRangeSpace({ create_id: create_id });
      getSpaceAvailable(chooseID);
      Modal.success({
        content: <div>โซน {chooseID.name} ถูกแก้ไขเรียบร้อย</div>,
      });
    } catch (error) {
      console.log(error.response);
      Modal.error({
        content: error.response.data.message,
      });
    }
  };

  const listItems = (
    <div
      style={{ cursor: "pointer", textAlign: "right", marginTop: "10px" }}
      onClick={() => toAddNewAvailableandEditDate()}
    >
      <Icon name="calendar outline" style={{ color: "#5ABBAD" }} />{" "}
      เลือกวันที่ว่าง
    </div>
  );

  if (summaryAvailable === null || spaceAvailable === null || space === null) {
    return (
      <div
        id="loading_div"
        style={{ top: "50%", left: "50%", position: "absolute" }}
      >
        <Spin />
      </div>
    );
  }

  return (
    <>
      <body style={{ fontFamily: "'Kanit', sans-serif" }}>
        <Row
          style={{
            border: "1px solid #DBE8E7",
            margin: "40px 100px auto 20px",
            borderRadius: "12px",
          }}
        >
          <Col span={8}>
            <div
              style={{
                padding: "30px 30px",
                overflow: "scroll",
                overflowX: "hidden",
                height: "750px",
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <div>
                  <Search placeholder="ค้นหา" onSearch={spaceSearch} />
                  <object
                    style={{
                      color: "#18A0FB",
                      fontWeight: "bold",
                      cursor: "pointer",
                    }}
                    onClick={() => getSpace(mallID)}
                  >
                    ล้างการค้นหา
                  </object>
                </div>
                <div
                  style={{
                    color: "#18A0FB",
                    textDecoration: "underline",
                    textAlign: "right",
                    cursor: "pointer",
                    fontSize: "16px",
                  }}
                  onClick={() => setCreateZoneDrawer(true)}
                >
                  สร้างโซน
                </div>
              </div>

              {searchSpace === null ? (
                <div
                  id="loading_div"
                  style={{ top: "50%", left: "50%", position: "absolute" }}
                >
                  <Spin />
                </div>
              ) : (
                <>
                  <div style={{ marginTop: "35px" }}>
                    {searchSpace !== null ? (
                      <div style={{ fontWeight: "bold", fontSize: "16px" }}>
                        ผลการค้นหาทั้งหมด {searchSpace.length} ที่
                      </div>
                    ) : null}
                  </div>
                  {searchSpace.map((sp) =>
                    searchSpace === null ? (
                      <Skeleton active />
                    ) : (
                      <div
                        onClick={() => chooseSpace(sp.id)}
                        style={{
                          fontSize: "16px",
                          cursor: "pointer",
                          border:
                            chooseID === sp.id
                              ? "1px solid #5ABBAD"
                              : "1px solid #DBE8E7",
                          padding: "15px",
                          borderRadius: "12px",
                          backgroundColor:
                            chooseID === sp.id ? "#F0FCFB" : "#FFFFFF",
                          marginTop: "18px",
                          boxShadow:
                            chooseID === sp.id &&
                            "0px 0px 8px rgba(0, 0, 0, 0.12)",
                        }}
                      >
                        <div
                          style={{
                            marginBottom: "10px",
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <div style={{ color: "#5D6968", fontWeight: "bold" }}>
                            โซน {sp.name}
                          </div>
                          <div>
                            <Switch
                              size="small"
                              defaultChecked={sp.is_active}
                              onChange={(checked, event) =>
                                onActive(checked, event, sp.id)
                              }
                            />
                          </div>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <div style={{ color: "#5D6968", fontWeight: "bold" }}>
                            สัญญา
                          </div>
                          <div>{sp.contract_type}</div>
                        </div>
                      </div>
                    )
                  )}
                </>
              )}
            </div>
          </Col>

          {summaryAvailable && (
            <Col span={16}>
              <div style={{ padding: "30px 30px" }}>
                <div
                  style={{
                    padding: "30px",
                    borderRadius: "12px",
                    fontSize: "16px",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <div style={{ color: "#5D6968", fontWeight: "bold" }}>
                      รายละเอียด
                    </div>
                    <div
                      style={{
                        color: "#18A0FB",
                        textDecoration: "underline",
                        cursor: "pointer",
                      }}
                      onClick={() => setEditZoneDrawer(true)}
                    >
                      แก้ไข
                    </div>
                  </div>
                  <div>
                    <div style={{ textAlign: "center" }}>
                      {zonePic ? (
                        <Image width={150} src={zonePic.image_url} />
                      ) : null}
                    </div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      marginTop: "40px",
                    }}
                  >
                    <div style={{ fontWeight: "bold", color: "#869594" }}>
                      ล๊อคว่าง
                    </div>
                    <div>{summaryAvailable.total_unit}</div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      marginTop: "20px",
                    }}
                  >
                    <div style={{ fontWeight: "bold", color: "#869594" }}>
                      ขนาดต่อล็อก
                    </div>
                    <div>
                      {summaryAvailable.booth_width} x{" "}
                      {summaryAvailable.booth_length}
                    </div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      marginTop: "20px",
                    }}
                  >
                    <div style={{ fontWeight: "bold", color: "#869594" }}>
                      จำนวนวันขั้นต่ำในการจอง
                    </div>
                    <div>
                      {summaryAvailable.min_booking_day === null
                        ? "-"
                        : summaryAvailable.min_booking_day}
                    </div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      marginTop: "20px",
                    }}
                  >
                    <div style={{ fontWeight: "bold", color: "#869594" }}>
                      จำนวนวันสูงสุดที่จองได้
                    </div>
                    <div>
                      {summaryAvailable.max_booking_day === null
                        ? "-"
                        : summaryAvailable.max_booking_day}
                    </div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      marginTop: "20px",
                    }}
                  >
                    <div style={{ fontWeight: "bold", color: "#869594" }}>
                      สัญญา
                    </div>
                    <div>{summaryAvailable.contract_type}</div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      marginTop: "20px",
                    }}
                  >
                    <div style={{ fontWeight: "bold", color: "#869594" }}>
                      รูปแบบการจอง
                    </div>
                    <div>{summaryAvailable.booking_unit}</div>
                  </div>
                  <Divider />
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <div style={{ fontWeight: "bold", color: "#869594" }}>
                      จองได้ระหว่างวันที่
                    </div>

                    {spaceAvailable.length === 0 ? (
                      <div
                        style={{ cursor: "pointer" }}
                        onClick={() => toAddNewAvailableandEditDate()}
                      >
                        <Icon
                          name="calendar outline"
                          style={{ color: "#5ABBAD" }}
                        />{" "}
                        เลือกวันที่ว่าง
                      </div>
                    ) : (
                      // <div style={{ cursor:"pointer" }} onClick={() => setCalendarShow(true)}><Icon name="calendar outline" style={{color:"#5ABBAD"}} />{moment(startDateShow).format('ll')} - {moment(endDateShow).format('ll')}</div>
                      <div>
                        {spaceAvailable.map((sp) => (
                          <Row style={{ cursor: "pointer", marginTop: "5px" }}>
                            <Col span={2}>
                              <Icon
                                name="calendar outline"
                                style={{ color: "#5ABBAD" }}
                              />
                            </Col>
                            <Col span={20}>
                              {moment(sp.from_date).format("ll")} -{" "}
                              {moment(sp.to_date).format("ll")}
                            </Col>
                            <Col
                              span={2}
                              onClick={() => deleteSpace(sp.create_id)}
                            >
                              <div
                                style={{
                                  color: "#ff0000",
                                  textDecoration: "underline",
                                  cursor: "pointer",
                                }}
                              >
                                ลบ
                              </div>
                            </Col>
                          </Row>
                        ))}
                      </div>
                    )}
                  </div>
                  {spaceAvailable.length !== 0 && (
                    <>
                      {addAvailableDateArr.map((item, i) => (
                        <div key={i}>{item}</div>
                      ))}
                      <div
                        style={{
                          color: "#18A0FB",
                          textDecoration: "underline",
                          cursor: "pointer",
                          textAlign: "right",
                          marginTop: "15px",
                        }}
                        onClick={() => addAvailableDate()}
                      >
                        +เพิ่มวันที่ว่าง
                      </div>
                    </>
                  )}
                </div>
              </div>
            </Col>
          )}
        </Row>

        <Modal
          title={
            <>
              <div>คุณได้เลือกวันที่</div>
              <p
                style={{
                  color: "#5ABBAD",
                  fontSize: "15px",
                  marginTop: "5px",
                  fontWeight: "bold",
                }}
              >
                {startDate === null ? null : moment(startDate).format("ll")}
                {startDate !== null && "-"}
                {endDate === "Invalid date" || endDate === null
                  ? null
                  : moment(endDate).format("ll")}
              </p>
            </>
          }
          visible={calendarShow}
          onOk={availablePost}
          onCancel={() => setCalendarShow(false)}
          okText="ยืนยัน"
          cancelText="ยกเลิก"
        >
          <div style={{ display: "flex", justifyContent: "center" }}>
            <div>
              <DayPickerRangeController
                startDate={startDate}
                startDateId="startDateId"
                endDate={endDate}
                endDateId="endDateId"
                onDatesChange={handleDatesChange}
                focusedInput={focusedInput}
                hideKeyboardShortcutsPanel
                onFocusChange={(f) => {
                  setFocusedInput(!f ? START_DATE : f);
                }}
                isOutsideRange={isOutsideRange}
              />
            </div>
          </div>
        </Modal>
        <Drawer
          title="สร้างโซน"
          width="60%"
          placement="right"
          onClose={() => setCreateZoneDrawer(false)}
          visible={createZoneDrawer}
        >
          <CreateSpace
            getSpace={getSpace}
            mallID={mallID}
            setCreateZoneDrawer={setCreateZoneDrawer}
          />
        </Drawer>
        <Drawer
          title="แก้ไขโซน"
          width="60%"
          placement="right"
          onClose={() => setEditZoneDrawer(false)}
          visible={editZoneDrawer}
        >
          <EditeSpace
            summaryAvailable={summaryAvailable}
            mallID={mallID}
            setEditZoneDrawer={setEditZoneDrawer}
            getSpace={getSpace}
            getSpaceInfo={getSpaceInfo}
          />
        </Drawer>
      </body>
    </>
  );
}
