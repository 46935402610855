import React, { useState, useEffect } from "react";
import { Button, Form, Input, Modal } from "antd";
import "../css/admin.css";
import * as userService from "services/user.service";

export default function VerifyOTP({ setStep }) {
  const [otp, setOTP] = useState(null);
  const [onLoading, setOnloading] = useState(false);

  const [seconds, setSeconds] = useState(59);
  const [otpError, setotpError] = useState(false);
  const [otpOvertime, setotpOvertime] = useState(false);

  useEffect(() => {
    if (seconds > 0) {
      setTimeout(() => setSeconds(seconds - 1), 1000);
    }
    if (seconds === 0) {
      setotpOvertime(true);
      setotpError(false);
      setOTP(null);
      setOnloading(false);
    }
  });

  const handleChange = (e) => {
    setOTP(e.target.value);
    console.log("otp", e.target.value);
  };

  const resetOTP = async () => {
    setotpOvertime(false);
    setOTP(null);
    setSeconds(59);

    var data = "";
    try {
      const resetPassword = await userService.resetPassword({
        email: localStorage.getItem("email"),
      });
      localStorage.setItem("encrypted_code", resetPassword.encrypted_code);
      localStorage.setItem("ref_code", resetPassword.ref_code);
      localStorage.setItem("token_reset_password", resetPassword.token);
      setOnloading(false);
    } catch (error) {
      console.log(error.response.data);
      return Modal.error({
        title: "ผิดพลาด",
        content: error.response.data.message,
      });
    }
  };

  async function onFinish() {
    setOnloading(true);
    var otpBody = {
      ref_code: localStorage.getItem("ref_code"),
      email: localStorage.getItem("email"),
      encrypted_code: localStorage.getItem("encrypted_code"),
      otp_code: otp,
    };
    try {
      await userService.otpVerify(otpBody);
      setStep(3);
    } catch (error) {
      console.log(error.response.data);
      setOnloading(false);
      setotpError(true);
    }
  }

  return (
    <>
      <Form
        name="basic"
        requiredMark
        onFinish={onFinish}
        style={{ marginTop: "20%" }}
      >
        <div style={{ marginTop: "32px" }}>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              marginBottom: "10px",
            }}
          >
            <div style={{ fontWeight: "bold" }}>
              รหัสอ้างอิง (Ref) : {localStorage.getItem("ref_code")}
            </div>
            <div>
              <object>{seconds === 0 ? null : seconds + " sec"}</object>
            </div>
          </div>

          <Form.Item
            name="otp"
            label="รหัส OTP"
            style={{ textAlign: "left" }}
            rules={[
              { required: true, message: "กรุณากรอกรหัส otp" },
              { pattern: new RegExp(/^[0-9]+$/), message: "ระบุเป็นตัวเลข" },
            ]}
          >
            <Input
              placeholder="กรอกรหัส otp"
              name="otp"
              onChange={(e) => handleChange(e)}
              style={{
                borderBottom: "2px solid #F2F2F2",
                fontFamily: "'Kanit', sans-serif",
              }}
              bordered={false}
              maxLength={6}
            />
          </Form.Item>
        </div>
        <div style={{ marginTop: "20px" }}>
          {seconds === 0 ? (
            <div style={{ fontSize: "14px", marginBottom: "15px" }}>
              <div style={{ color: "#6D7A79" }}>
                รหัส OTP นี้หมดอายุการใช้งานแล้ว <br />
                โปรดกด ส่งรหัส OTP อีกครั้ง เพื่อทำการขอรหัส OTP ใหม่
              </div>
              <div
                style={{
                  marginTop: "30px",
                  color: "#18A0FB",
                  cursor: "pointer",
                }}
                onClick={() => resetOTP()}
              >
                ส่งอีกครั้ง
              </div>
            </div>
          ) : null}
          {otpError ? (
            <div style={{ fontSize: "14px" }}>
              <div style={{ color: "#FF0000", textAlign: "left" }}>
                รหัส OTP ไม่ถูกต้อง โปรดลองใหม่อีกครั้ง
              </div>
            </div>
          ) : null}
          <Button
            type="primary"
            htmlType="submit"
            block
            style={{
              width: "100%",
              height: "3rem",
              fontSize: "18px",
              backgroundColor: "#ED4556",
              color: "white",
              opacity: otpOvertime && "0.5",
              borderRadius: "6px",
              fontFamily: "'Kanit', sans-serif",
              border: "transparent",
            }}
            loading={onLoading}
            disabled={otpOvertime}
          >
            Verify
          </Button>
        </div>
      </Form>
    </>
  );
}
