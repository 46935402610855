import { axios, HeaderDefault, GetEnvironment } from "services/base.service";

export const getBusinessMatching = (payload) => {
  return axios({
    url: `/v2/admin/business-matching`,
    method: "get",
    ...HeaderDefault({}),
  })
    .then((res) => res?.data?.data)
    .catch((err) => {
      throw err?.response?.data;
    });
};

export const getBusinessMatchingRegistration = (
  business_matching_id,
  payload
) => {
  return axios({
    url: `/v2/admin/business-matching/registration/search`,
    method: "post",
    data: { business_matching_id, ...payload },
    ...HeaderDefault({}),
  })
    .then((res) => res?.data?.data)
    .catch((err) => {
      throw err?.response?.data;
    });
};

export const getBusinessMatchingDatePending = () => {
  return axios({
    url: `/v2/admin/business-matching/registration/date-pending`,
    method: "get",
    ...HeaderDefault({}),
  })
    .then((res) => res?.data?.data)
    .catch((err) => {
      throw err?.response?.data;
    });
};

export const getRegistrationInfo = (registration_id) => {
  return axios({
    url: `/v2/admin/business-matching/registration/info`,
    method: "get",
    params: { registration_id },
    ...HeaderDefault({}),
  })
    .then((res) => res?.data?.data)
    .catch((err) => {
      throw err?.response?.data;
    });
};

export const getBuyerFromProductCategory = (product_category, is_own_brand) => {
  return axios({
    url: `/v2/admin/business-matching/buyer`,
    method: "get",
    params: { product_category, is_own_brand },
    ...HeaderDefault({}),
  })
    .then((res) => res?.data?.data)
    .catch((err) => {
      throw err?.response?.data;
    });
};

export const updateStatus = (payload) => {
  return axios({
    url: `/v2/admin/business-matching/update/status`,
    method: "post",
    data: payload,
    ...HeaderDefault({}),
  })
    .then((res) => res?.data?.data)
    .catch((err) => {
      throw err?.response?.data;
    });
};

export const assignBuyer = (registration_id, assignee_id) => {
  return axios({
    url: `/v2/admin/business-matching/assign`,
    method: "post",
    data: {
      registration_id,
      assignee_id,
    },
    ...HeaderDefault({}),
  })
    .then((res) => res?.data?.data)
    .catch((err) => {
      throw err?.response?.data;
    });
};

export const getVegFruitCategory = (payload) => {
  return axios({
    url: `/v2/client/product/category/veg-fruit`,
    method: "get",
    ...HeaderDefault(),
  }).then((res) => res?.data?.data);
};

export const updateRegistration = (payload) => {
  return axios({
    url: `/v2/admin/business-matching/sme/update`,
    method: "post",
    data: payload,
    ...HeaderDefault({}),
  })
    .then((res) => res?.data?.data)
    .catch((err) => {
      throw err?.response?.data;
    });
};

export const getTimeSlot = (assignee_id, business_matching_id) => {
  return axios({
    url: `/v2/admin/business-matching/timeslot`,
    method: "get",
    params: {
      business_matching_id,
      assignee_id,
    },
    ...HeaderDefault({}),
  })
    .then((res) => res?.data?.data)
    .catch((err) => {
      throw err?.response?.data;
    });
};

export const createTimeSlot = (data) => {
  return axios({
    url: `/v2/admin/business-matching/timeslot/new`,
    method: "post",
    data,
    ...HeaderDefault({}),
  })
    .then((res) => res?.data?.data)
    .catch((err) => {
      throw err?.response?.data;
    });
};

export const deleteTimeSlot = (time_slot_id) => {
  return axios({
    url: `/v2/admin/business-matching/timeslot/delete`,
    method: "delete",
    params: { time_slot_id },
    ...HeaderDefault({}),
  })
    .then((res) => res?.data?.data)
    .catch((err) => {
      throw err?.response?.data;
    });
};

export const updateTimeSlot = (time_slot_id, data) => {
  return axios({
    url: `/v2/admin/business-matching/timeslot/update`,
    method: "post",
    params: { time_slot_id },
    data,
    ...HeaderDefault({}),
  })
    .then((res) => res?.data?.data)
    .catch((err) => {
      throw err?.response?.data;
    });
};

export const getFactoryStandard = () => {
  return axios({
    url: `/v2/client/shop/factory-standard`,
    method: "get",
    ...HeaderDefault(),
  }).then((res) => res?.data?.data);
};
