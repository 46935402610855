import React, { useState, useEffect } from "react";
import { Row, Col, Form, Modal, Input, Button, Checkbox } from "antd";
import { EyeInvisibleOutlined, EyeTwoTone } from "@ant-design/icons";
import * as authenService from "services/authen.service";
import { useNavigate } from "react-router-dom";
import "modules/space/css/admin.css";
import "antd/dist/antd.css";
import "semantic-ui-css/semantic.min.css";

export default function Login() {
  const navigate = useNavigate();
  const [email, setEmail] = useState(null);
  const [password, setPassword] = useState(null);
  const [onLoading, setOnloading] = useState(false);
  const [rememberMe, setRememberMe] = useState(false);

  useEffect(() => {
    localStorage.clear();
  }, []);

  const onRemember = (e) => {
    setRememberMe(e.target.checked);
  };

  const onFinish = async () => {
    setOnloading(true);
    const payload = {
      password: password,
      email: email,
    };
    try {
      const user = await authenService.login(payload);
      localStorage.setItem("token", user.token);
      localStorage.setItem("refresh_token", user.refresh_token);
      localStorage.setItem("malls_access", JSON.stringify(user.malls_access));
      navigate("/");
    } catch (err) {
      console.log(err);
      return (
        Modal.error({
          title: "ผิดพลาด",
          content: err?.response?.data.message,
        }),
        setOnloading(false)
      );
    }
  };

  return (
    <body className="body">
      <Row>
        <Col span={14} id="sme-login-bg-container">
          <div style={{ color: "white", textAlign: "left" }}>
            <div style={{ fontWeight: "bold", fontSize: "18px" }}>
              CP Origin
            </div>
            <div
              style={{
                fontWeight: "bold",
                fontSize: "24px",
                marginTop: "37px",
              }}
            >
              SME Ecosystem platform
            </div>
            <div style={{ fontSize: "16px", marginTop: "28px" }}>
              WE ARE DEDICATED TO BUILDING & DEVELOPING SUSTAINABLE BUSINESS
              GROWTH FOR SMEs IN THAILAND
            </div>
          </div>
        </Col>
        <Col span={10}>
          <div id="right-login-container">
            <Form
              name="basic"
              requiredMark
              layout="vertical"
              onFinish={onFinish}
            >
              <div
                style={{
                  fontWeight: "bold",
                  fontSize: "30px",
                  textAlign: "left",
                }}
              >
                เข้าสู่ระบบ
              </div>
              <div style={{ marginTop: "42px" }}>
                <Form.Item
                  name="login_email"
                  label="อีเมล"
                  rules={[{ required: true, message: "กรุณากรอกอีเมล!" }]}
                  style={{ textAlign: "left" }}
                >
                  <Input
                    placeholder="กรอกอีเมล"
                    name="login_email"
                    onChange={(e) => setEmail(e.target.value)}
                    style={{
                      borderBottom: "2px solid #F2F2F2",
                      fontFamily: "'Kanit', sans-serif",
                    }}
                  />
                </Form.Item>
              </div>

              <div style={{ marginTop: "31px" }}>
                <Form.Item
                  bordered={false}
                  name="login_password"
                  label="พาสเวิร์ด"
                  rules={[{ required: true, message: "กรุณากรอกพาสเวิร์ด!" }]}
                  style={{ textAlign: "left" }}
                >
                  <Input.Password
                    style={{
                      borderBottom: "2px solid #F2F2F2",
                      fontFamily: "'Kanit', sans-serif",
                      textAlign: "left",
                    }}
                    placeholder="กรอกรหัสผ่าน"
                    onChange={(e) => setPassword(e.target.value)}
                    name="login_password"
                    iconRender={(visible) =>
                      visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                    }
                  />
                </Form.Item>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <div>
                    <Checkbox onChange={onRemember}>จดจำฉัน</Checkbox>
                  </div>
                  <div style={{ color: "#18A0FB", fontSize: "14px" }}>
                    <label onClick={() => navigate("/forget-password")}>
                      ลืมรหัสผ่าน?
                    </label>
                  </div>
                </div>
              </div>
              <div style={{ marginTop: "40px" }}>
                <Button
                  type="primary"
                  htmlType="submit"
                  block
                  style={{
                    width: "100%",
                    height: "50px",
                    fontSize: "18px",
                    backgroundColor: "#ED4556",
                    color: "white",
                    borderRadius: "6px",
                    fontFamily: "'Kanit', sans-serif",
                    border: "transparent",
                  }}
                  loading={onLoading}
                >
                  เข้าสู่ระบบ
                </Button>
              </div>
            </Form>
          </div>
        </Col>
      </Row>
    </body>
  );
}
