import React, { useState, useEffect } from "react";
import { Row, Select, Spin } from "antd";
import { DownOutlined } from "@ant-design/icons";
import userStore from "common/stores/user.store";

const { Option } = Select;

export default function Header(props) {
  const [defaultValue, setDefaultValue] = useState(null);
  const user = userStore((state) => state.user);

  useEffect(() => {
    for (let i = 0; i < props.mallDetail.length; i++) {
      console.log(props.mallDetail[i].id);
      if (props.mallDetail[i].id === user.primary_mall_id) {
        setDefaultValue(props.mallDetail[i].name);
        break;
      } else {
        setDefaultValue(props.mallDetail[0].name);
      }
    }
  }, []);

  if (defaultValue === null) {
    return (
      <div
        id="loading_div"
        style={{ top: "50%", left: "50%", position: "absolute" }}
      >
        <Spin />
      </div>
    );
  }

  return (
    <>
      <div
        style={{
          color: "#262626",
          width: "100%",
          borderBottom: "1px solid #DBE8E7",
          paddingTop: "10px",
          paddingBottom: "10px",
        }}
      >
        <Row
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginBottom: "16px",
          }}
        >
          <Row>
            <div
              style={{
                fontSize: "24px",
                fontWeight: "bold",
                display: "flex",
                alignItems: "center",
                marginLeft: "60px",
              }}
            >
              {props.activeItem}
            </div>
          </Row>
          <Row
            style={{
              marginRight: "6rem",
              padding: "10px",
              display: "flex",
              alignItems: "center",
            }}
          >
            {/* <div style={{ marginRight:"18px" }}>
                            <a style={{ backgroundColor:"#55BBB4", color: "#17524E", boxShadow:"0px 0px 4px rgba(49, 122, 117, 0.36)", borderRadius:"12px", color:"white", paddingTop:"16px", paddingBottom:"16px", padding:"16px 3px" }}>
                                <b>Lotus<object style={{ color:"#F5BE41" }}>'s</object></b>
                            </a>
                        </div>
                        <div style={{  }}>
                            <div>{props.mallDetail && props.mallDetail.name}</div>
                        </div> */}
            {/* <div>
              <a
                style={{
                  backgroundColor: "#55BBB4",
                  color: "#17524E",
                  boxShadow: "0px 0px 4px rgba(49, 122, 117, 0.36)",
                  borderRadius: "12px",
                  color: "white",
                  paddingTop: "16px",
                  paddingBottom: "16px",
                  padding: "16px 3px",
                }}
              >
                <b>
                  Lotus<object style={{ color: "#F5BE41" }}>'s</object>
                </b>
              </a>
            </div> */}
            <div>
              <Select
                defaultValue={defaultValue}
                onChange={props.getMallAccess}
                bordered={false}
                showSearch
                style={{ minWidth: 200, width: "auto" }}
                optionFilterProp="children"
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
                filterSort={(optionA, optionB) =>
                  optionA.children
                    .toLowerCase()
                    .localeCompare(optionB.children.toLowerCase())
                }
              >
                {props.mallDetail.map((md) => (
                  <Option key={md.id} value={md.id}>
                    {md.name}
                  </Option>
                ))}
              </Select>
            </div>
          </Row>
        </Row>
      </div>
    </>
  );
}
