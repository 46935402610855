import loadable from "@loadable/component";
import Home from "pages";

// Mall Manager
import OverviewRegister from "modules/space/register/overview-register";
import Login from "pages/login";
import ForgetPassword from "modules/recruit/auth/forget-password";

const isAuthenticated = localStorage.getItem("token");

const publicRoute = [
  { path: "/overview_register", element: <OverviewRegister /> },
  {
    exact: true,
    path: "/login",
    element: <Login />,
  },
  { path: "/forget-password", element: <ForgetPassword /> },

  { path: "*", element: isAuthenticated ? <Home /> : <Login /> },
];

export default publicRoute;
