import React, { useState } from "react";
import { Button, Form, Input, Modal } from "antd";
import "../css/admin.css";
import axiosAPI from "../../../assets/js/axiosApi";
import * as userService from "services/user.service";

export default function EmailInput({ setStep }) {
  const [email, setEmail] = useState(null);
  const [onLoading, setOnloading] = useState(false);

  const handleChange = (e) => {
    setEmail(e.target.value);
    console.log("email", e.target.value);
  };

  async function onFinish() {
    setOnloading(true);
    try {
      const resetPassword = await userService.resetPassword({ email: email });
      setStep(2);
      localStorage.setItem("email", email);
      localStorage.setItem("encrypted_code", resetPassword.encrypted_code);
      localStorage.setItem("ref_code", resetPassword.ref_code);
      localStorage.setItem("token_reset_password", resetPassword.token);
    } catch (error) {
      console.log(error.response);
      setOnloading(false);
      Modal.error({
        title: "ผิดพลาด",
        content: error.response.data.message,
      });
    }
  }

  return (
    <>
      <Form
        name="basic"
        requiredMark
        layout="vertical"
        onFinish={onFinish}
        style={{ marginTop: "20%" }}
      >
        <div style={{ marginTop: "42px" }}>
          <Form.Item
            name="email"
            label="อีเมล"
            style={{ textAlign: "left" }}
            rules={[{ required: true, message: "กรุณากรอกอีเมล!" }]}
          >
            <Input
              placeholder="กรอกอีเมล"
              name="email"
              onChange={(e) => handleChange(e)}
              style={{
                borderBottom: "2px solid #F2F2F2",
                fontFamily: "'Kanit', sans-serif",
              }}
              bordered={false}
            />
          </Form.Item>
        </div>
        <div style={{ marginTop: "40px" }}>
          <Button
            type="primary"
            htmlType="submit"
            block
            style={{
              width: "100%",
              height: "3rem",
              fontSize: "18px",
              backgroundColor: "#ED4556",
              color: "white",
              borderRadius: "6px",
              fontFamily: "'Kanit', sans-serif",
              border: "transparent",
            }}
            loading={onLoading}
          >
            Verify
          </Button>
        </div>
      </Form>
    </>
  );
}
