import React, { useState, useEffect } from "react";
import "modules/space/css/admin.css";
import {
  PlusOutlined,
  ArrowLeftOutlined,
  RightOutlined,
} from "@ant-design/icons";
import {
  Upload,
  Button,
  Form,
  Input,
  DatePicker,
  Checkbox,
  Radio,
  Divider,
  InputNumber,
  Row,
  Col,
  Switch,
} from "antd";
import AddfloorPlan from "./add-floorplan";
import axiosAPI from "assets/js/axiosApi";
import { Loader } from "semantic-ui-react";
import { useNavigate } from "react-router-dom";

const CheckboxGroup = Checkbox.Group;
const defaultCheckedList = [];
const plainOptions = [
  "อาหาร",
  "เครื่องดื่ม",
  "สุขภาพและความงาม",
  "บริการรถยนต์และยานยนต์",
  "การศึกษา",
  "บันเทิง",
  "เทคโนโลยี",
  "แฟชั่นและเครื่องประดับ",
  "เครื่องเพชรและทองคำ",
  "เฟอร์นิเจอร์และของตกแต่งบ้าน",
  "โปรโมชั่น",
  "งานแสดงหรือนิทรรศการ",
  "งานมอเตอร์โชว์",
  "บริการ",
  "ใบปลิวหรือสินค้าตัวอย่าง",
  "ตู้ขายของอัตโนมัติ",
  "ลอตเตอรี่",
  "ศูนย์อาหาร",
  "โอทอป",
];

export default function CreateSpace(props) {
  const navigate = useNavigate();
  const [businessType, setBusinessType] = useState(null);
  const [reserveType, setReserveType] = useState(null);
  const [required_approval, setRequired_approval] = useState(false);
  const [uploadFileZone, setUploadFileZone] = useState(null);
  const [uploadFileFloor, setUploadFileFloor] = useState(null);
  const [avaiZone, setAvaiZone] = useState(null);
  const [zoneName, setZoneName] = useState(null);
  const [floor, setFloor] = useState(null);
  const [contactType, setContactType] = useState(null);
  const [areaType, setAreaType] = useState(null);
  const [booth_price, setBooth_price] = useState(null);
  const [checkedList, setCheckedList] = useState(defaultCheckedList);
  const [boothSize_width, setBoothSize_width] = useState(null);
  const [boothSize_height, setBoothSize_height] = useState(null);
  const [minReserved, setMinReserved] = useState(null);
  const [maxReserved, setMaxReserved] = useState(null);

  const [minFuture_Reserved, setMinFutureReserved] = useState(null);
  const [maxFuture_Reserved, setMaxFutureReserved] = useState(null);
  const [onLoading, setOnloading] = useState(false);

  const [indeterminate, setIndeterminate] = useState(true);

  useEffect(() => {
    async function getBusinessType() {
      await axiosAPI
        .get(`/v1/shop/business/type`)
        .then((response) => {
          setBusinessType(response.data.data);
          console.log("getBusinessType: ", response.data.data);
        })
        .catch((error) => {
          console.log(error.responce);
          setBusinessType([]);
        });
    }
    getBusinessType();
  }, []);

  const contactTypeOnChange = (e) => {
    console.log("Contact checked", e.target.value);
    setContactType(e.target.value);
  };
  const reserveTypeOnChange = (e) => {
    console.log("reserve checked", e.target.value);
    setReserveType(e.target.value);
  };
  const areaTypeOnChange = (e) => {
    console.log("Area type:", e.target.value);
    setAreaType(e.target.value);
  };
  const onChangeTypeBusiness = (list) => {
    console.log(list);
    setCheckedList(list);
    setIndeterminate(!!list.length && list.length < plainOptions.length);
  };
  const handleImageChangeZone = (e) => {
    console.log(e.file);
    setUploadFileZone(e.file);
  };
  const handleImageChangeFloor = (e) => {
    console.log(e.file);
    setUploadFileFloor(e.file);
  };
  const onFinish = () => {
    setOnloading(true);

    var data = {
      name: zoneName,
      area_type: areaType,
      contract_type: contactType,
      mall_id: parseInt(localStorage.getItem("mall_id")),
      floor: floor,
      booth_price: booth_price,
      shop_specification: { business_type: checkedList },
      space_booth_width: boothSize_width,
      space_booth_length: boothSize_height,
      amenity: { free: JSON.parse(localStorage.getItem("aminitie")) },
      min_booking_day: minReserved,
      max_booking_day: maxReserved,
      min_pre_booking_day: minFuture_Reserved,
      max_pre_booking_day: maxFuture_Reserved,
      booking_unit: reserveType,
      required_approval: required_approval,
      total_unit: avaiZone,
    };

    console.log(data);

    axiosAPI
      .post(`/v1/space/new`, data)
      .then((response) => {
        console.log(response.data);
        var spaceID = response.data.data.id;
        const paramsuploadFileZone = new FormData();
        paramsuploadFileZone.append("file", uploadFileZone);
        axiosAPI
          .post(
            `/v1/space/image/new?space_id=${spaceID}&img_type=รูปโซน`,
            paramsuploadFileZone
          )
          .then((response) => {
            console.log(response.data);
            const paramsuploadFileFloor = new FormData();
            paramsuploadFileFloor.append("file", uploadFileFloor);
            axiosAPI
              .post(
                `/v1/space/image/new?space_id=${spaceID}&img_type=รูปFloorPlan`,
                paramsuploadFileFloor
              )
              .then((response) => {
                console.log(response.data);
                navigate("/space/setting/add/available");
              })
              .catch((error) => {
                setOnloading(false);
                console.log(error.response);
              });
          })
          .catch((error) => {
            setOnloading(false);
            console.log(error.response);
          });
      })
      .catch((error) => {
        setOnloading(false);
        console.log(error.response);
      });
  };
  const handleChange = (e) => {
    if (e.target.name === "zoneName") {
      setZoneName(e.target.value);
      console.log("zoneName: ", e.target.value);
    }
    if (e.target.name === "floor") {
      setFloor(e.target.value);
      console.log("floor : ", e.target.value);
    }
    if (e.target.name === "boothSize_width") {
      setBoothSize_width(e.target.value);
      console.log("boothSize_width : ", e.target.value);
    }
    if (e.target.name === "boothSize_height") {
      setBoothSize_height(e.target.value);
      console.log("boothSize_height : ", e.target.value);
    }
    if (e.target.name === "minReserved") {
      setMinReserved(e.target.value);
      console.log("minReserved : ", e.target.value);
    }
    if (e.target.name === "maxReserved") {
      setMaxReserved(e.target.value);
      console.log("maxReserved : ", e.target.value);
    }
    if (e.target.name === "minFuture_Reserved") {
      setMinFutureReserved(e.target.value);
      console.log("minFuture_Reserved : ", e.target.value);
    }
    if (e.target.name === "maxFuture_Reserved") {
      setMaxFutureReserved(e.target.value);
      console.log("maxFuture_Reserved : ", e.target.value);
    }
    if (e.target.name === "booth_price") {
      setBooth_price(e.target.value);
      console.log("booth_price : ", e.target.value);
    }
    if (e.target.name === "avaiZone") {
      setAvaiZone(e.target.value);
      console.log("avaiZone : ", e.target.value);
    }
  };
  const handleminFuture_Reserved = (e) => {
    setMinFutureReserved(e);
    console.log("min_Future_Reserved : ", e);
  };
  const handlemaxFuture_Reserved = (e) => {
    setMaxFutureReserved(e);
    console.log("max_Future_Reserved : ", e);
  };
  const handleminReserved = (e) => {
    setMinReserved(e);
    console.log("minReserved : ", e);
  };
  const handlemaxReserved = (e) => {
    setMaxReserved(e);
    console.log("maxReserved : ", e);
  };
  function onswitchChange(checked) {
    setRequired_approval(checked);
  }

  if (businessType === null) {
    return (
      <div
        id="loading_div"
        style={{ top: "50%", left: "50%", position: "absolute" }}
      >
        <Loader active size="medium">
          Loading...
        </Loader>
      </div>
    );
  }

  return (
    <>
      <body style={{ fontFamily: "'Kanit', sans-serif" }}>
        <div id="admin-bg">
          <div
            style={{
              width: "800px",
              backgroundColor: "white",
              display: "block",
              marginLeft: "auto",
              marginRight: "auto",
              marginTop: "10px",
              borderRadius: "12px",
              padding: "50px",
            }}
          >
            <div
              style={{
                color: "#5ABBAD",
                fontSize: "16px",
                fontWeight: "bold",
                textAlign: "left",
              }}
            >
              <p
                onClick={() => navigate("/space/setting/add-floorplan")}
                style={{ cursor: "pointer" }}
              >
                <ArrowLeftOutlined />
              </p>
            </div>
            <div
              style={{
                color: "#5ABBAD",
                fontSize: "24px",
                fontWeight: "bold",
                marginTop: "23px",
                textAlign: "left",
              }}
            >
              สร้างโซน
            </div>
            <div
              style={{
                textAlign: "left",
                overflow: "scroll",
                height: "780px",
                overflowX: "visible",
                marginTop: "20px",
              }}
            >
              <div style={{ marginRight: "10px" }}>
                <div
                  style={{
                    fontSize: "15px",
                    color: "#869594",
                    fontWeight: "bold",
                  }}
                >
                  เพิ่มรูปภาพ
                </div>
                <div>
                  <Form
                    name="basic"
                    labelCol={{ span: 8 }}
                    wrapperCol={{ span: 24 }}
                    requiredMark
                    layout="vertical"
                    onFinish={onFinish}
                  >
                    <div style={{ textAlign: "left", marginTop: "17px" }}>
                      <Upload
                        // listType="picture"
                        listType="picture-card"
                        name="uploadFileZone"
                        onChange={(e) => handleImageChangeZone(e)}
                        beforeUpload={() => false}
                        maxCount={1}
                      >
                        <div>
                          <PlusOutlined
                            style={{
                              fontSize: "14px",
                              fontWeight: "bold",
                              color: "#5ABBAD",
                            }}
                          />
                          &nbsp;อัพโหลด
                        </div>
                      </Upload>
                    </div>
                    <div style={{ marginTop: "20px" }}>
                      <Form.Item
                        name="zoneName"
                        label="ตั้งชื่อโซน"
                        style={{ textAlign: "left" }}
                        rules={[
                          { required: true, message: "กรุณาระบุชื่อโซน" },
                        ]}
                      >
                        <Input
                          placeholder="โซนพาวิลเลี่ยน, เปิดท้าย"
                          name="zoneName"
                          value={zoneName}
                          onChange={(e) => handleChange(e)}
                          style={{
                            borderBottom: "2px solid #F2F2F2",
                            fontFamily: "'Kanit', sans-serif",
                          }}
                          bordered={false}
                        />
                      </Form.Item>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          cursor: "pointer",
                          marginTop: "10px",
                        }}
                        onClick={() =>
                          navigate("/space/setting/upload/floorplan-aminities")
                        }
                      >
                        <div>
                          สิ่งอำนวยความสะดวก{" "}
                          <RightOutlined
                            size="medium"
                            style={{ color: "#5ABBAD", fontWeight: "bold" }}
                          />
                        </div>
                      </div>
                      <Divider />
                      <Form.Item
                        name="floor"
                        label="ระบุชั้น"
                        style={{ textAlign: "left" }}
                        rules={[{ required: true, message: "กรุณาระบุชั้น" }]}
                      >
                        <Input
                          placeholder="B, G, 1"
                          name="floor"
                          value={floor}
                          onChange={(e) => handleChange(e)}
                          style={{
                            borderBottom: "2px solid #F2F2F2",
                            fontFamily: "'Kanit', sans-serif",
                          }}
                          bordered={false}
                        />
                      </Form.Item>
                      <Form.Item
                        label="รูปแบบการจอง"
                        rules={[
                          { required: true, message: "กรุณาเลือกรูปแบบการจอง" },
                        ]}
                      >
                        <Radio.Group
                          onChange={reserveTypeOnChange}
                          value={reserveType}
                        >
                          <Radio.Button value="วัน">รายวัน</Radio.Button>
                          <Radio.Button value="เดือน">รายเดือน</Radio.Button>
                        </Radio.Group>
                      </Form.Item>
                      <Form.Item
                        label="สัญญาในพื้นที่ที่เปิดรับ"
                        rules={[
                          { required: true, message: "กรุณาเลือกรูปแบบสัญญา" },
                        ]}
                      >
                        <Radio.Group
                          onChange={contactTypeOnChange}
                          value={contactType}
                        >
                          <Radio.Button value="ระยะสั้น">ระยะสั้น</Radio.Button>
                          <Radio.Button value="ระยะยาว">ระยะยาว</Radio.Button>
                        </Radio.Group>
                      </Form.Item>
                      <Form.Item
                        label="รูปแบบพื้นที่"
                        rules={[
                          {
                            required: true,
                            message: "กรุณาเลือกรูปแบบพื้นที่",
                          },
                        ]}
                      >
                        <Radio.Group
                          onChange={areaTypeOnChange}
                          value={areaType}
                        >
                          <Radio.Button value="indoor">indoor</Radio.Button>
                          <Radio.Button value="outdoor">outdoor</Radio.Button>
                        </Radio.Group>
                      </Form.Item>
                      <Form.Item
                        name="checkbox-group"
                        label="รูปแบบร้านค้าที่สามารถจองได้"
                      >
                        <div style={{ color: "#869594", fontSize: "14px" }}>
                          สามารถเลือกได้มากกว่า 1 รายการ
                        </div>
                        <div style={{ marginTop: "10px" }}>
                          <CheckboxGroup
                            options={businessType}
                            value={checkedList}
                            onChange={onChangeTypeBusiness}
                          />
                        </div>
                      </Form.Item>
                      <Divider />
                      <Form.Item
                        label="ขนาดต่อล็อก"
                        style={{ marginBottom: 0 }}
                        rules={[
                          { required: true, message: "กรุณาระบุขนาดต่อล็อก" },
                        ]}
                      >
                        <Form.Item
                          style={{
                            display: "inline-block",
                            width: "calc(50% - 12px)",
                          }}
                          name="boothSize_width"
                          rules={[
                            { required: true, message: "กรุณาระบุขนาดต่อล็อก" },
                            {
                              pattern: new RegExp(/^[0-9]+$/),
                              message: "ระบุเป็นตัวเลข",
                            },
                          ]}
                        >
                          <Input
                            placeholder="100ตร.ม"
                            name="boothSize_width"
                            value={boothSize_width}
                            onChange={(e) => handleChange(e)}
                            style={{
                              borderBottom: "2px solid #F2F2F2",
                              fontFamily: "'Kanit', sans-serif",
                            }}
                            bordered={false}
                          />
                        </Form.Item>
                        <span
                          style={{
                            display: "inline-block",
                            width: "24px",
                            lineHeight: "32px",
                            textAlign: "center",
                          }}
                        >
                          x
                        </span>
                        <Form.Item
                          style={{
                            display: "inline-block",
                            width: "calc(50% - 12px)",
                          }}
                          name="boothSize_height"
                          rules={[
                            { required: true, message: "กรุณาระบุขนาดต่อล็อก" },
                            {
                              pattern: new RegExp(/^[0-9]+$/),
                              message: "ระบุเป็นตัวเลข",
                            },
                          ]}
                        >
                          <Input
                            placeholder="100ตร.ม"
                            name="boothSize_height"
                            value={boothSize_height}
                            onChange={(e) => handleChange(e)}
                            style={{
                              borderBottom: "2px solid #F2F2F2",
                              fontFamily: "'Kanit', sans-serif",
                            }}
                            bordered={false}
                          />
                        </Form.Item>

                        <Form.Item
                          name="avaiZone"
                          label="ล็อกว่าง"
                          style={{ textAlign: "left" }}
                          rules={[
                            {
                              required: true,
                              message: "กรุณาระบุจำนวนล็อกว่าง",
                            },
                            {
                              pattern: new RegExp(/^[0-9]+$/),
                              message: "ระบุเป็นตัวเลข",
                            },
                          ]}
                        >
                          <Input
                            placeholder="100"
                            name="avaiZone"
                            value={avaiZone}
                            onChange={(e) => handleChange(e)}
                            style={{
                              borderBottom: "2px solid #F2F2F2",
                              fontFamily: "'Kanit', sans-serif",
                            }}
                            bordered={false}
                          />
                        </Form.Item>

                        <Form.Item
                          name="booth_price"
                          label="ราคาต่อล็อค"
                          style={{ textAlign: "left" }}
                          rules={[
                            { required: true, message: "กรุณาราคา" },
                            {
                              pattern: new RegExp(/^[0-9]+$/),
                              message: "ระบุเป็นตัวเลข",
                            },
                          ]}
                        >
                          <Input
                            placeholder="0.00"
                            name="booth_price"
                            value={booth_price}
                            suffix="ต่อวัน"
                            onChange={(e) => handleChange(e)}
                            style={{
                              borderBottom: "2px solid #F2F2F2",
                              fontFamily: "'Kanit', sans-serif",
                            }}
                            bordered={false}
                          />
                        </Form.Item>
                      </Form.Item>
                      <Row>
                        <Col span={12}>
                          <div style={{ marginBottom: "10px" }}>
                            จำนวนวันจองล่วงหน้าขั้นต่ำ
                          </div>
                          <Form.Item
                            rules={[
                              {
                                required: true,
                                message: "กรุณาระบุจำนวนวันจองล่วงหน้าขั้นต่ำ",
                              },
                            ]}
                            name="minFuture_Reserved"
                          >
                            <InputNumber
                              min={0}
                              max={365}
                              defaultValue={0}
                              name="minFuture_Reserved"
                              value={minFuture_Reserved}
                              onChange={(e) => handleminFuture_Reserved(e)}
                            />
                          </Form.Item>
                        </Col>
                        <Col span={12}>
                          <div style={{ marginBottom: "10px" }}>
                            จำนวนวันจองล่วงหน้าสูงสุด
                          </div>
                          <Form.Item
                            rules={[
                              {
                                required: true,
                                message: "กรุณาระบุจำนวนวันจองล่วงหน้าสูงสุด",
                              },
                            ]}
                            name="maxFuture_Reserved"
                          >
                            <InputNumber
                              min={0}
                              max={365}
                              defaultValue={0}
                              name="maxFuture_Reserved"
                              value={maxFuture_Reserved}
                              onChange={(e) => handlemaxFuture_Reserved(e)}
                            />
                          </Form.Item>
                        </Col>
                      </Row>
                      <Row>
                        <Col span={12}>
                          <div style={{ marginBottom: "10px" }}>
                            จำนวนวันขั้นต่ำในการจอง
                          </div>
                          <Form.Item
                            name="minReserved"
                            rules={[
                              {
                                required: true,
                                message: "กรุณาระบุจำนวนวันขั้นต่ำในการจอง",
                              },
                            ]}
                          >
                            <InputNumber
                              min={0}
                              value={minReserved}
                              name="minReserved"
                              max={365}
                              defaultValue={0}
                              onChange={(e) => handleminReserved(e)}
                            />
                          </Form.Item>
                        </Col>
                        <Col span={12}>
                          <div style={{ marginBottom: "10px" }}>
                            จำนวนวันสูงสุดในการจอง
                          </div>
                          <Form.Item
                            rules={[
                              {
                                required: true,
                                message: "กรุณาระบุจำนวนวันขั้นต่ำในการจอง",
                              },
                            ]}
                            name="maxReserved"
                          >
                            <InputNumber
                              min={0}
                              max={365}
                              value={maxReserved}
                              name="maxReserved"
                              defaultValue={0}
                              onChange={(e) => handlemaxReserved(e)}
                            />
                          </Form.Item>
                        </Col>
                      </Row>

                      <div style={{ marginTop: "10px" }}>
                        <div>
                          ตรวจสอบการจอง{" "}
                          <Switch
                            style={{ marginLeft: "15px" }}
                            onChange={onswitchChange}
                            value={required_approval}
                          />
                        </div>
                        <p style={{ marginTop: "10px", color: "#869594" }}>
                          การจองที่ต้องตรวจสอบก่อนยืนยันให้ร้านค้าเข้าพื้นที่
                        </p>
                      </div>

                      <Divider />

                      <Upload
                        onChange={(e) => handleImageChangeFloor(e)}
                        beforeUpload={() => false}
                        listType="picture"
                        name="uploadPictureFloor"
                        maxCount={1}
                      >
                        <div
                          style={{
                            color: "#18A0FB",
                            fontSize: "18px",
                            cursor: "pointer",
                          }}
                        >
                          +เพิ่มภาพ Floorplan
                        </div>
                      </Upload>
                    </div>

                    <div style={{ marginTop: "45px" }}>
                      <Button
                        size="large"
                        htmlType="submit"
                        loading={onLoading}
                        style={{
                          fontSize: "18px",
                          backgroundColor: "#5ABBAD",
                          color: "white",
                          borderRadius: "6px",
                          width: "100%",
                          fontFamily: "'Kanit', sans-serif",
                        }}
                      >
                        ยืนยัน
                      </Button>
                    </div>
                    <div style={{ marginTop: "25px" }}>
                      <Button
                        size="large"
                        // onClick={() => }
                        style={{
                          fontSize: "16px",
                          backgroundColor: "transparent",
                          borderRadius: "6px",
                          width: "100%",
                          fontFamily: "'Kanit', sans-serif",
                          border: "transparent",
                        }}
                      >
                        ยกเลิก
                      </Button>
                    </div>
                  </Form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </body>
    </>
  );
}
