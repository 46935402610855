import React, { useState, useEffect } from "react";
import { Row, Table } from "antd";
import { Icon, Image, Menu, Button } from "semantic-ui-react";
import floorIcon from "assets/img/floor_Icon.png";
import allZoneTable from "./table/all-zone-table";

export default function Alltenant({ dataTable }) {
  function onChange(pagination, filters, sorter, extra) {
    console.log("params", pagination, filters, sorter, extra);
  }

  return (
    <>
      <body style={{ fontFamily: "'Kanit', sans-serif" }}>
        <div
          style={{ marginLeft: "60px", marginTop: "30px", marginRight: "60px" }}
        >
          <div style={{ marginTop: "14px" }}>
            <Table
              columns={allZoneTable}
              dataSource={dataTable}
              scroll={{ x: 1300 }}
              style={{ marginRight: "60px" }}
              onChange={onChange}
            />
          </div>
        </div>
      </body>
    </>
  );
}
