import moment from 'moment';

const columns = [
    {
      title: 'วันที่จอง',
      dataIndex: 'date',
      fixed: 'left',
      // sorter: (a, b) =>  moment(a.date).unix() - moment(b.date).unix()
      sorter: (a, b) => {
        if (moment(a.Created).isBefore(moment(b.Created))) {
           return -1;
        }
        return 1;
      }
    },
    { title: 'โซน', dataIndex: 'zone_name', fixed:"left"},
    { title: 'ชื่อร้านค้า', dataIndex: 'shop_name'},
    { title: 'ประเภท', dataIndex: 'bussiness_type' },
    { title: 'พื้นที่', dataIndex: 'booth_size' },
    { title: 'ช่วงเวลา (วัน)', dataIndex: 'duringTime', sorter: (a, b) => a.duringTime - b.duringTime },
    { title: 'ราคา', dataIndex: 'price' },
    { title: 'สถานะ', dataIndex: 'status'},
    { title: '', dataIndex: 'link', fixed:"right"},
];

export default columns;