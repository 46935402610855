import React, { useEffect } from "react";
import { useRoutes } from "react-router-dom";
import { useUserQuery, useMallQuery } from "common/hooks/use-query";

// Partner
import protectedRoute from "./protected";
import publicRoute from "./public";
import { Navigate } from "react-router-dom";

export default function Router() {
  const isAuthenticated = localStorage.getItem("token");
  const { refetch: userRefetch } = useUserQuery();
  const { refetch: mallRefeUserRefetch } = useMallQuery();

  useEffect(() => {
    userRefetch();
    mallRefeUserRefetch();
  }, [isAuthenticated]);

  //prettier-ignore
  const routing = useRoutes([
    ...(isAuthenticated ? 
      protectedRoute 
      : [{ path: "*", element: <Navigate to="/login" /> }]), 
    ...publicRoute]);
  return routing;
}
