import React, { useState } from "react";
import { Box, Grid, Toolbar, IconButton, Typography } from "@mui/material";
import { ExitToApp } from "@mui/icons-material";
import originHorizon from "assets/img/origin-horizon.png";
import campaign from "assets/img/category/campaign.png";
import space from "assets/img/category/space.png";
import messaging from "assets/img/category/messaging.png";
import report from "assets/img/category/report.png";
import content from "assets/img/category/content.png";
import survey from "assets/img/category/survey.png";
import setting from "assets/img/category/setting.png";
import opportunity from "assets/img/category/opportunity.png";

import { useNavigate } from "react-router-dom";
import wave from "assets/img/wave.png";
import "App.css";
import Authen from "common/hooks/authen";

const categories = [
  {
    title: "จัดการพื้นที่",
    description: "ระบบที่ช่วยจัดการพื้นที่\nภายใต้คอมมิวนิตี้ของคุณ",
    to: "space",
    src: space,
  },
  {
    title: "จัดการแคมเปญ",
    description: "ระบบที่ช่วยจัดการ\nโอกาสทางธุรกิจของคุณกับ SMEs",
    to: "recruit/campaign",
    src: campaign,
  },
  // {
  //   title: "จัดการข้อความ",
  //   description: "ระบบที่ช่วยบริการจัดการ\nข้อความจากลูกค้าของคุณ",
  //   to: "messaging",
  //   src: messaging,
  // },
  {
    title: "รายงาน",
    description: "ดาวน์โหลดรายงาน\nเพื่อติดตามสถานะบริการของคุณ",
    to: "report",
    src: report,
  },
  {
    title: "แพลตฟอร์มแห่งโอกาส",
    description: "ระบบที่จะช่วยคุณจัดการใบสมัคร\nและสถานะของเหล่าผู้ประกอบการ",
    to: "opportunity",
    src: opportunity,
  },
  // {
  //   title: "คอนเทนต์มาร์เกตติ้ง",
  //   description: "ระบบที่ช่วยจัดวางคอนเทนต์ให้เหมาะสมกับธุรกิจของคุณ",
  //   to: "content",
  //   src: content,
  // },
  // {
  //   title: "แบบประเมินจากลูกค้า",
  //   description:
  //     "ระบบที่จะช่วยวัดความพึงพอใจจากลูกค้าที่เข้าใช้งานในแต่ละบริการของคุณ",
  //   to: "survey",
  //   src: survey,
  // },
  // {
  //   title: "ตั้งค่า",
  //   description: "ตั้งค่าระบบ รวมถึงโปรไฟล์ของคุณ",
  //   to: "setting",
  //   src: setting,
  // },
];

export default function Home() {
  return (
    <>
      <Box
        sx={{
          display: "flex",
          height: "100%",
          backgroundImage: { sm: `url(${wave})` },
          backgroundPosition: "bottom",
          backgroundSize: "contain",
          backgroundRepeat: "no-repeat",
        }}
      >
        <Box
          sx={{
            mx: { xs: 2, md: 10 },
          }}
        >
          <Header />
          <Typography fontSize={24} fontWeight="bold" sx={{ mb: 2 }}>
            บริการทั้งหมด
          </Typography>
          <Grid container spacing={2.5}>
            {categories.map((e) => (
              <Category {...e} />
            ))}
          </Grid>
        </Box>
      </Box>
    </>
  );
}

function Header() {
  const { logout } = Authen();

  return (
    <Grid
      container
      mt={4}
      mb={3}
      sx={{
        justifyContent: "space-between",
      }}
    >
      <Box>
        <img src={originHorizon} height={36} alt="sme-origin-logo-horizontal" />
      </Box>
      <IconButton
        size="large"
        aria-label="account of current user"
        aria-controls="menu-appbar"
        aria-haspopup="true"
        onClick={logout}
        color="red"
      >
        <ExitToApp />
      </IconButton>
    </Grid>
  );
}

function Category(props) {
  const navigate = useNavigate();
  return (
    <Grid
      xs={12}
      sm={4}
      md={3}
      item
      onClick={() => navigate(`/${props.to}`)}
      sx={{ cursor: "pointer" }}
    >
      <Grid
        container
        sx={{
          bgcolor: "white",
          height: "100%",
          border: "1px solid #DEE0E0",
          borderRadius: 3,
          alignContent: "flex-start",
          pb: { xs: 0, sm: 1 },
        }}
      >
        <Grid
          item
          xs={3}
          sm={12}
          component="img"
          src={props.src}
          sx={{
            m: 1,
            borderRadius: 3,
            height: { xs: 80, sm: 150 },
            width: "-webkit-fill-available",
            objectFit: "cover",
          }}
        />
        <Grid item xs={7.8} sm={12} m={1}>
          <Typography fontSize={20} fontWeight="bold" sx={{ mb: 0.5 }}>
            {props.title}
          </Typography>
          <Typography fontSize={16} sx={{ whiteSpace: " break-spaces" }}>
            {props.description}
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
}
