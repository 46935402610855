import { axios, HeaderDefault, GetEnvironment } from "services/base.service";

const API_URL = process.env.REACT_APP_API_URL;

export const getUserDetail = ({ user_id }) => {
  return axios({
    url: `/v1/user/detail`,
    method: "get",
    params: { user_id },
    ...HeaderDefault({}),
  }).then((res) => res?.data?.data);
};

export const getUserInfo = () => {
  return axios({
    url: `/v1/user/info`,
    method: "get",
    ...HeaderDefault({}),
  }).then((res) => res?.data?.data);
};

export const updateUserInfo = (payload) => {
  return axios({
    url: `/v1/user/update/info`,
    method: "post",
    data: payload,
    baseURL: API_URL,
    headers: {
      Authorization: "Bearer " + localStorage.getItem("token_reset_password"),
    },
  }).then((res) => res?.data?.data);
};

export const resetPassword = ({ email }) => {
  return axios({
    url: `/v1/user/reset/password`,
    method: "post",
    params: { email },
    ...HeaderDefault({}),
  }).then((res) => res?.data?.data);
};

export const otpVerify = (payload) => {
  return axios({
    url: `/v1/user/otp/verify`,
    method: "post",
    data: payload,
    ...HeaderDefault({}),
  }).then((res) => res?.data?.data);
};

export const primaryMall = ({ mall_id }) => {
  return axios({
    url: `/v1/user/primary/mall`,
    method: "post",
    params: { mall_id },
    ...HeaderDefault({}),
  }).then((res) => res?.data?.data);
};
