import React, { useState, useEffect } from "react";
import { Row, Col, Divider, Modal } from "antd";
import "modules/space/css/admin.css";
import { RightOutlined } from "@ant-design/icons";
import addFlooricon from "assets/img/addFlooricon.png";
import axiosAPI from "assets/js/axiosApi";
import { Label, Loader, Icon } from "semantic-ui-react";
import moment from "moment";
import { START_DATE, END_DATE } from "react-dates/constants";
import { DayPickerRangeController } from "react-dates";
import { useNavigate } from "react-router-dom";

export default function CreateAddAvailable(props) {
  const navigate = useNavigate();
  const [space, setSpace] = useState(null);
  const [summaryAvailable, setSummaryAvailable] = useState(null);
  const [spaceAvailable, setSpaceAvailable] = useState(null);

  const [chooseID, setChooseID] = useState(null);
  const [isModalVisible, setIsModalVisible] = useState(false);

  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [startDateShow, setStartDateShow] = useState(null);
  const [endDateShow, setEndDateShow] = useState(null);
  const [focusedInput, setFocusedInput] = useState(START_DATE);

  var [count, setcount] = useState(null);
  localStorage.removeItem("aminitie");

  const handleOk = () => {
    var data = {
      space_id: summaryAvailable.id,
      from_date: moment(startDate).format("YYYY-MM-DD"),
      to_date: moment(endDate).format("YYYY-MM-DD"),
    };
    axiosAPI
      .post(`/v1/space/add/range/available`, data)
      .then((response) => {
        console.log(response.data);
        setIsModalVisible(false);
      })
      .catch((error) => {
        console.log(error.response);
      });
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };
  const clearDate = () => {
    setStartDateShow(null);
    setEndDateShow(null);
    setStartDate(null);
    setEndDate(null);
  };

  const handleDatesChange = ({ startDate, endDate }) => {
    setStartDate(startDate);
    setStartDateShow(moment(startDate).format("YYYY-MM-DD"));

    if (endDate === "Invalid date") {
      setEndDate(startDate);
      setEndDateShow(moment(startDate).format("YYYY-MM-DD"));
    } else {
      setEndDate(endDate);
      setEndDateShow(moment(endDate).format("YYYY-MM-DD"));
    }
    console.log(
      moment(startDate).format("YYYY-MM-DD"),
      moment(endDate).format("YYYY-MM-DD")
    );
  };

  useEffect(() => {
    async function getSpaceDetail() {
      await axiosAPI
        .get(`/v1/space/all?mall_id=${localStorage.getItem("mall_id")}`)
        .then((response) => {
          setSpace(response.data);
          console.log("Space detail: ", response.data);
          response.data.data.map((space) =>
            space.mall_id === parseInt(localStorage.getItem("mall_id"))
              ? setcount((count += 1))
              : setcount(count)
          );
          getAvailableSpace(response.data.data[0].id);
          getSpaceAvailable(response.data.data[0].id);
        })
        .catch((error) => {
          console.log(error.response);
          setSpace(null);
        });
    }
    getSpaceDetail();
  }, []);

  async function getAvailableSpace(id) {
    setChooseID(id);
    await axiosAPI
      .get(`/v1/space/info?space_id=${id}`)
      .then((response) => {
        setSummaryAvailable(response.data.data);
        console.log("Summary Available: ", response.data);
      })
      .catch((error) => {
        console.log(error.responce);
        setSummaryAvailable([]);
      });
    if (spaceAvailable === null) {
      return (
        <div
          id="loading_div"
          style={{ top: "50%", left: "50%", position: "absolute" }}
        >
          <Loader active size="medium">
            Loading...
          </Loader>
        </div>
      );
    }
  }

  async function getSpaceAvailable(id) {
    await axiosAPI
      .get(`/v1/space/available/summary?space_id=${id}`)
      .then((response) => {
        setSpaceAvailable(response.data);
        console.log("Space Available: ", response.data);
      })
      .catch((error) => {
        console.log(error.response);
        setSpaceAvailable([]);
      });
  }

  function editZone(param) {
    localStorage.setItem("edit_zone", JSON.stringify(param));
    navigate("/space/setting/edit");
  }

  if (space === null || summaryAvailable === null) {
    return (
      <div
        id="loading_div"
        style={{ top: "50%", left: "50%", position: "absolute" }}
      >
        <Loader active size="medium">
          Loading...
        </Loader>
      </div>
    );
  }

  return (
    <>
      <body style={{ fontFamily: "'Kanit', sans-serif" }}>
        <div id="admin-bg">
          <div
            style={{
              width: "950px",
              backgroundColor: "white",
              display: "block",
              marginLeft: "auto",
              marginRight: "auto",
              marginTop: "200px",
              borderRadius: "12px",
              padding: "60px",
            }}
          >
            <Row>
              <Col span={11}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignContent: "center",
                  }}
                >
                  <div
                    style={{
                      color: "#5ABBAD",
                      fontSize: "24px",
                      textAlign: "left",
                      fontWeight: "bold",
                    }}
                  >
                    โซน ({count})
                  </div>
                  <div
                    style={{
                      color: "#18A0FB",
                      fontSize: "16px",
                      cursor: "pointer",
                      textDecoration: "underline",
                    }}
                    onClick={() => navigate("/space/setting/create")}
                  >
                    สร้างโซน
                  </div>
                </div>
                <div
                  style={{
                    overflowY: "hidden",
                    height: "400px",
                    overflowX: "visible",
                    marginTop: "20px",
                  }}
                >
                  {space.data.map((space) =>
                    space.mall_id ===
                    parseInt(localStorage.getItem("mall_id")) ? (
                      <>
                        <div
                          style={{
                            border:
                              chooseID === space.id && "1px solid #5ABBAD",
                            padding: "15px",
                            borderRadius: "12px",
                            backgroundColor:
                              chooseID === space.id ? "#F0FCFB" : "#F8F9FB",
                            marginTop: "18px",
                            boxShadow: "0px 0px 8px rgba(0, 0, 0, 0.12)",
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              marginBottom: "10px",
                            }}
                          >
                            <div
                              style={{ color: "#5D6968", fontWeight: "bold" }}
                            >
                              โซน {space.name}
                            </div>
                            <div
                              style={{
                                color: "#18A0FB",
                                cursor: "pointer",
                                textDecoration: "underline",
                              }}
                              onClick={() => (
                                getAvailableSpace(space.id),
                                getSpaceAvailable(space.id)
                              )}
                            >
                              ดูรายละเอียด
                            </div>
                          </div>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <div
                              style={{ color: "#5D6968", fontWeight: "bold" }}
                            >
                              สัญญา
                            </div>
                            <div style={{ color: "#5D6968" }}>
                              {space.contract_type}
                            </div>
                          </div>
                        </div>
                      </>
                    ) : null
                  )}
                </div>
              </Col>
              <Col span={11} offset={2}>
                <>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <div
                      style={{
                        fontWeight: "bold",
                        fontSize: "20px",
                        color: "#869594",
                      }}
                    >
                      รายละเอียด
                    </div>
                    <div
                      style={{
                        color: "#18A0FB",
                        fontSize: "16px",
                        cursor: "pointer",
                        textDecoration: "underline",
                      }}
                      onClick={() => editZone(summaryAvailable)}
                    >
                      แก้ไข
                    </div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      marginTop: "40px",
                    }}
                  >
                    <div
                      style={{
                        fontSize: "18px",
                        fontWeight: "bold",
                        color: "#869594",
                      }}
                    >
                      ล๊อคว่าง
                    </div>
                    <div>{summaryAvailable.total_unit}/100</div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      marginTop: "20px",
                    }}
                  >
                    <div
                      style={{
                        fontSize: "18px",
                        fontWeight: "bold",
                        color: "#869594",
                      }}
                    >
                      ขนาดต่อล็อก
                    </div>
                    <div>
                      {summaryAvailable.space_booth_width} x{" "}
                      {summaryAvailable.space_booth_length}
                    </div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      marginTop: "20px",
                    }}
                  >
                    <div
                      style={{
                        fontSize: "18px",
                        fontWeight: "bold",
                        color: "#869594",
                      }}
                    >
                      จำนวนวันขั้นต่ำในการจอง
                    </div>
                    <div>{summaryAvailable.min_booking_day}</div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      marginTop: "20px",
                    }}
                  >
                    <div
                      style={{
                        fontSize: "18px",
                        fontWeight: "bold",
                        color: "#869594",
                      }}
                    >
                      จำนวนวันสูงสุดที่จองได้
                    </div>
                    <div>{summaryAvailable.max_booking_day}</div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      marginTop: "20px",
                    }}
                  >
                    <div
                      style={{
                        fontSize: "18px",
                        fontWeight: "bold",
                        color: "#869594",
                      }}
                    >
                      สัญญา
                    </div>
                    <div>{summaryAvailable.contract_type}</div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      marginTop: "20px",
                    }}
                  >
                    <div
                      style={{
                        fontSize: "18px",
                        fontWeight: "bold",
                        color: "#869594",
                      }}
                    >
                      รูปแบบการจอง
                    </div>
                    <div>{summaryAvailable.booking_unit}</div>
                  </div>

                  <Divider />
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <div
                      style={{
                        fontSize: "18px",
                        fontWeight: "bold",
                        color: "#869594",
                      }}
                    >
                      จองได้ระหว่างวันที่
                    </div>

                    {spaceAvailable && spaceAvailable.data[0] ? (
                      <div>
                        <Icon
                          name="calendar outline"
                          style={{ color: "#5ABBAD" }}
                        />{" "}
                        {moment(spaceAvailable.data[0].from_date).format("ll")}{" "}
                        - {moment(spaceAvailable.data[0].to_date).format("ll")}
                      </div>
                    ) : startDate === null || endDate === null ? (
                      <div
                        style={{ cursor: "pointer" }}
                        onClick={() => setIsModalVisible(true)}
                      >
                        <Icon
                          name="calendar outline"
                          style={{ color: "#5ABBAD" }}
                        />{" "}
                        เลือกวันที่ว่าง
                      </div>
                    ) : (
                      <div
                        style={{ cursor: "pointer" }}
                        onClick={() => setIsModalVisible(true)}
                      >
                        <Icon
                          name="calendar outline"
                          style={{ color: "#5ABBAD" }}
                        />
                        {moment(startDateShow).format("ll")} -{" "}
                        {moment(endDateShow).format("ll")}
                      </div>
                    )}
                  </div>
                </>
              </Col>
            </Row>
            <div
              style={{
                color: "#5ABBAD",
                fontSize: "18px",
                textAlign: "center",
                fontWeight: "bold",
                marginTop: "4rem",
                cursor: "pointer",
              }}
              onClick={() => navigate("/")}
            >
              เข้าสู่หน้าหลัก
            </div>
          </div>

          <Modal
            title={
              <>
                <div>คุณได้เลือกวันที่</div>
                <p
                  style={{
                    color: "#5ABBAD",
                    fontSize: "15px",
                    marginTop: "5px",
                    fontWeight: "bold",
                  }}
                >
                  {startDateShow === null
                    ? null
                    : moment(startDateShow).format("ll")}
                  {startDateShow !== null && "-"}
                  {endDateShow === "Invalid date" || endDateShow === null
                    ? null
                    : moment(endDateShow).format("ll")}
                </p>
              </>
            }
            visible={isModalVisible}
            onOk={handleOk}
            onCancel={handleCancel}
            okText="ยืนยัน"
            cancelText="ยกเลิก"
          >
            <div style={{ display: "flex", justifyContent: "center" }}>
              <div>
                <DayPickerRangeController
                  startDate={startDate}
                  startDateId="startDateId"
                  endDate={endDate}
                  endDateId="endDateId"
                  onDatesChange={handleDatesChange}
                  focusedInput={focusedInput}
                  hideKeyboardShortcutsPanel
                  onFocusChange={(f) => {
                    setFocusedInput(!f ? START_DATE : f);
                  }}
                />
              </div>
            </div>
          </Modal>
        </div>
      </body>
    </>
  );
}
