import React, { useState, useEffect } from "react";
import { Upload, Button, Form, Input } from "antd";
import "modules/space/css/admin.css";
import { PlusOutlined } from "@ant-design/icons";
import axiosAPI from "assets/js/axiosApi";
import { useNavigate } from "react-router-dom";

export default function SettingOnboardNamed(props) {
  const navigate = useNavigate();
  const [uploadFile, setUploadFile] = useState(null);
  const [productName, setProductName] = useState(null);
  const [onLoading, setOnloading] = useState(false);

  const handleImageChange = (e) => {
    console.log(e.file);
    setUploadFile(e.file);
  };

  const onFinish = () => {
    setOnloading(true);
    var data = [{ name: productName }];
    axiosAPI
      .post(`/v1/mall/new`, data)
      .then((response) => {
        console.log(response.data);
        const params = new FormData();
        params.append("file", uploadFile);
        localStorage.setItem("mall_id", response.data.data[0].id);
        axiosAPI
          .post(
            `/v1/mall/image/new?mall_id=${response.data.data[0].id}&img_type=general`,
            params
          )
          .then((response) => {
            console.log(response.data);
            navigate("/space/setting/add-floorplan");
          })
          .catch((error) => {
            setOnloading(false);
            console.log(error.response);
          });
      })
      .catch((error) => {
        setOnloading(false);
        console.log(error.response);
      });
  };

  const handleChange = (e) => {
    if (e.target.name === "productName") {
      setProductName(e.target.value);
      console.log("productName", e.target.value);
    }
  };

  return (
    <>
      <body style={{ fontFamily: "'Kanit', sans-serif" }}>
        <div id="admin-bg">
          <div
            style={{
              width: "708px",
              backgroundColor: "white",
              display: "block",
              marginLeft: "auto",
              marginRight: "auto",
              marginTop: "200px",
              borderRadius: "12px",
              padding: "77px",
            }}
          >
            <div>
              <div
                style={{
                  color: "#5ABBAD",
                  fontSize: "24px",
                  textAlign: "left",
                  fontWeight: "bold",
                }}
              >
                เริ่มต้นสร้างสถานที่ใหม่
              </div>

              <div
                style={{
                  color: "#869594",
                  fontSize: "15px",
                  textAlign: "left",
                  fontWeight: "bold",
                  marginTop: "37px",
                }}
              >
                อัพโหลดรูปภาพ
              </div>

              <div style={{ textAlign: "left", marginTop: "17px" }}>
                <Upload
                  listType="picture-card"
                  name="uploadFile"
                  onChange={handleImageChange}
                  beforeUpload={() => false}
                  maxCount={1}
                >
                  <div>
                    <Button
                      style={{
                        border: "transparent",
                        backgroundColor: "transparent",
                        borderRadius: "50%",
                        width: "80px",
                        height: "80px",
                      }}
                    >
                      <PlusOutlined
                        style={{
                          fontSize: "18px",
                          fontWeight: "bold",
                          color: "#5ABBAD",
                        }}
                      />
                    </Button>
                  </div>
                </Upload>
              </div>

              <div>
                <Form
                  name="basic"
                  labelCol={{ span: 8 }}
                  wrapperCol={{ span: 24 }}
                  requiredMark
                  layout="vertical"
                  onFinish={onFinish}
                >
                  <div style={{ marginTop: "42px" }}>
                    <Form.Item
                      name="productName"
                      label="ชื่อสถานที่"
                      style={{ textAlign: "left" }}
                      rules={[
                        { required: true, message: "กรุณากรอกชื่อสถานที่!" },
                      ]}
                    >
                      <Input
                        placeholder="เซเว่น, โลตัส, แมคโคร"
                        name="productName"
                        onChange={(e) => handleChange(e)}
                        style={{
                          borderBottom: "2px solid #F2F2F2",
                          fontFamily: "'Kanit', sans-serif",
                        }}
                        bordered={false}
                      />
                    </Form.Item>
                  </div>

                  <div style={{ marginTop: "45px" }}>
                    <Button
                      size="large"
                      loading={onLoading}
                      htmlType="submit"
                      style={{
                        fontSize: "18px",
                        backgroundColor: "#5ABBAD",
                        color: "white",
                        borderRadius: "6px",
                        width: "100%",
                        fontFamily: "'Kanit', sans-serif",
                      }}
                    >
                      ถัดไป
                    </Button>
                  </div>
                  <div style={{ marginTop: "25px" }}>
                    {/* <Button 
                                            size="large"
                                            // onClick={() => }
                                            style={{  fontSize:"16px", backgroundColor:"transparent",  borderRadius:"6px", width:"100%", fontFamily: "'Kanit', sans-serif", border:"transparent" }} 
                                        >
                                            ยกเลิก
                                        </Button> */}
                    <div
                      style={{
                        backgroundColor: "transparent",
                        borderRadius: "6px",
                        fontFamily: "'Kanit', sans-serif",
                        cursor: "pointer",
                      }}
                    >
                      <div
                        style={{
                          textDecoration: "underline",
                          fontSize: "16px",
                          color: "gray",
                        }}
                        onClick={() => navigate("/")}
                      >
                        ภายหลัง
                      </div>
                    </div>
                  </div>
                </Form>
              </div>
            </div>
          </div>
        </div>
      </body>
    </>
  );
}
