import { createTheme, ThemeProvider, useTheme } from "@mui/material/styles";

const font = "Kanit, sans-serif";

const theme = createTheme({
  components: {
    MuiFormLabel: {
      styleOverrides: {
        asterisk: { color: "red" },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: { borderRadius: "10px" },
      },
      variants: [
        {
          props: { size: "large" },
          style: {
            height: "45px",
          },
        },
      ],
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          fieldset: {
            borderRadius: "8px",
          },
          "&.Mui-focused  fieldset": {
            // "&.Mui-focused": {
            borderColor: "#1976d2 !important",
            // },
          },
        },
      },
    },
  },
  typography: {
    fontFamily: font,
    display: {
      fontSize: "16px",
      fontWeight: "bold",
      display: "block",
      lineHeight: 2,
    },
    display2: {
      fontSize: "14px",
      fontWeight: "bold",
      display: "block",
      lineHeight: 1.7,
    },
  },
  introText: {
    fontFamily: font,
    color: "white",
  },
  palette: {
    lightBlue: {
      main: "#18A0FB",
    },
    red: {
      main: "#F01C31",
      dark: "#d11427",
      light: "#EDEDED",
      contrastText: "white",
    },
    green: {
      main: "#55BBB4",
      dark: "#388c86",
      light: "#EDEDED",
      contrastText: "white",
    },
    yellow: {
      main: "#DEB047",
      dark: "#b18c38",
      light: "#EDEDED",
      contrastText: "white",
    },
  },
});

export default theme;
