import loadable from "@loadable/component";
const Campaign = loadable(() => import("pages/recruit/campaign"));
const CampaignManage = loadable(() => import("pages/recruit/campaign/manage"));
const CampaignEdit = loadable(() => import("pages/recruit/campaign/edit"));
const CampaignCreate = loadable(() => import("pages/recruit/campaign/create"));
const Layout = loadable(() => import("modules/recruit/campaign/layout"));

// const campaignRoute = [{ path: "/campaign", element: <Campaign /> }];

const campaignRoute = [
  {
    path: "/recruit/campaign",
    element: <Layout />,
    children: [
      { index: true, element: <Campaign /> },
      {
        path: "manage",
        element: <CampaignManage />,
      },
      {
        path: "create",
        element: <CampaignCreate />,
      },
      {
        path: "edit",
        element: <CampaignEdit />,
      },
    ],
  },
];

export default campaignRoute;
