import React, { useState, useEffect } from "react";
import { Segment, Image, Menu, Sidebar } from "semantic-ui-react";
import { Badge, Spin, Modal } from "antd";
import * as authenService from "services/authen.service";
import * as spaceService from "services/space.service";
import * as bookingService from "services/booking.service";
import * as userService from "services/user.service";
import * as mallService from "services/mall.service";

import shopIcon from "assets/img/store_mall_directory.png";
import post_office from "assets/img/post_office.png";
import originicon from "assets/img/originicon2.png";
import dashboardicon from "assets/img/dashboardicon.png";
import SMEsicon from "assets/img/SMEs.png";
import settingIcon from "assets/img/settingicon.png";
import logouticon from "assets/img/logouticon.png";
import axiosAPI from "assets/js/axiosApi";
import HeaderZone from "./header";
import moment from "moment";
import localization from "moment/locale/th";
import AllAreas from "./all-areas";
import AllTenants from "./all-tenant";
// import BookingManage from "./booking-manage/index.legacy";
import BookingManage from "./booking-manage/index.legacy";
import "./css/admin.css";
import { useNavigate } from "react-router-dom";
import userStore from "common/stores/user.store";
import Authen from "common/hooks/authen";

moment.locale("th", localization);

export default function Index(props) {
  const user = userStore((state) => state.user);
  const navigate = useNavigate();
  const { logout } = Authen();

  // Switch
  const [activeItem, setActiveItem] = useState("จัดการการจอง");

  // API GET
  const [mallDetail, setMallDetail] = useState(null);
  const [space, setSpace] = useState(null);
  const [booking, setBooking] = useState(null);
  const [mallID, setMallID] = useState(null);
  const [pending_approval, setPending_approval] = useState(null);

  // Table
  const [dataTable, setdataTable] = useState([]);
  var [dataTablecount, setdataTablecount] = useState(0);

  // getMallDetail
  useEffect(() => {
    getMallDetail();
  }, [user.primary_mall_id]);

  async function getMallDetail() {
    try {
      const mallUser = await mallService.getMallByUser();
      console.log("Mall detail: ", mallUser);

      setMallDetail(mallUser);

      if (mallUser.length === 0) {
        getBooking([]);
        getSpace([]);
        setMallID(null);
      } else {
        getBooking(user.primary_mall_id);
        getSpace(user.primary_mall_id);
        setMallID(user.primary_mall_id);
      }
    } catch (error) {
      console.log(error);
      setMallDetail([]);
    }
  }

  async function getSpace(id) {
    try {
      const spaceUser = await spaceService.getSpaceUser({ mall_id: id });
      setSpace(spaceUser);
      console.log("Space detail: ", spaceUser);
    } catch (error) {
      console.log(error);
      setSpace([]);
    }
  }

  async function getBooking(id) {
    if (!id) return;

    try {
      const allBooking = await bookingService.getBookingByMallId({
        mall_id: id,
      });
      setBooking(allBooking);
      console.log("booking detail: ", allBooking);
      setdataTable(
        allBooking.map(
          (book, index) => (
            (dataTablecount += 1),
            {
              user_id: dataTablecount,
              date: moment(book.booking_created_at).format("LLL"),
              shop_name: book.shop_name,
              bussiness_type: book.shop_business_type,
              booth_size:
                book.space_booth_width + " x " + book.space_booth_length,
              duringTime: book.period,
              status: (
                <div
                  style={{
                    color:
                      book.booking_status === "ชำระเงินแล้ว"
                        ? "#55BBB4"
                        : "#E78831",
                  }}
                >
                  {book.booking_status}
                </div>
              ),
              zone: book.space_name,
              startDate: book.booking_date_range_info[0].from_date,
              endDate: book.booking_date_range_info[0].to_date,
              contact: book.space_contract_type,
              payrent: parseFloat(book.space_booth_price),
            }
          )
        )
      );
      var pending_approvalCount = [];
      allBooking.map(
        (bk) =>
          bk.booking_status === "pending_approval" &&
          pending_approvalCount.push(bk)
      );
      console.log(pending_approvalCount);
    } catch (error) {
      console.log(error.response);
      setBooking([]);
    }
  }
  //  Switch Mall
  async function getMallAccess(mallID) {
    console.log(mallID);
    try {
      await userService.primaryMall({ mall_id: mallID });
    } catch (error) {
      console.log(error.response);
      Modal.error({
        content: error.response.data,
      });
    }

    console.log(mallID);
    var filterMall = mallDetail.filter((md) => md.id === mallID);
    getBooking(filterMall[0].id);
    getSpace(filterMall[0].id);
    setMallID(filterMall[0].id);
  }

  // Logout
  const logoutFunc = async () => {
    setActiveItem("logouticon");
    logout();
  };
  const Menuitem = (pic, activeItemClicked) => {
    return (
      <Menu.Item
        as="a"
        style={{
          color: "#A1CDCA",
          fontWeight: "bold",
          marginTop: "10px",
          marginBottom: "10px",
          borderLeft:
            activeItem === activeItemClicked ? "4px solid #55BBB4" : null,
        }}
        active={activeItem === activeItemClicked}
        onClick={() => setActiveItem(activeItemClicked)}
      >
        <Image src={pic[0]} />
        <Image src={pic[1]} style={{ marginTop: "5px" }} />
      </Menu.Item>
    );
  };

  if (booking === null || mallDetail === null || space === null) {
    return (
      <div
        id="loading_div"
        style={{ top: "50%", left: "50%", position: "absolute" }}
      >
        <Spin />
      </div>
    );
  }

  const filterCase = (activeItem) => {
    switch (activeItem) {
      case "พื้นที่ทั้งหมด":
        return <AllAreas space={space} mallID={mallID} />;
      case "จัดการการจอง":
        return (
          <BookingManage
            getBooking={getBooking}
            activeItem={activeItem}
            mallID={mallID}
            dataTable={dataTable}
            space={space}
            mallDetail={mallDetail}
            setPending_approval={setPending_approval}
            pending_approvals={pending_approval}
          />
        );
      case "ร้านค้าทั้งหมด":
        return (
          <AllTenants
            activeItem={activeItem}
            booking={booking}
            dataTable={dataTable}
          />
        );
      //   case "ตั้งค่า": return <AllTenants />;
      case "logouticon":
        return <h1>Log out...</h1>;

      default:
        return <h1>Coming Soon...</h1>;
    }
  };

  return (
    <>
      <Sidebar.Pushable as={Segment}>
        <Sidebar
          as={Menu}
          animation="push"
          direction="left"
          icon="labeled"
          dimmed={false}
          inverted
          vertical
          style={{ backgroundColor: "#EAF7F6", height: "100%" }}
          visible
          width="very thin"
        >
          <Menu.Item
            as="a"
            style={{
              color: "#A1CDCA",
              fontWeight: "bold",
              marginTop: "10px",
              marginBottom: "10px",
            }}
            active={activeItem === "originicon"}
            onClick={() => navigate("/")}
          >
            <Image src={originicon} />
            <Image src={SMEsicon} style={{ marginTop: "5px" }} />
          </Menu.Item>
          {Menuitem([dashboardicon], "พื้นที่ทั้งหมด")}
          <Menu.Item
            as="a"
            style={{
              color: activeItem === "จัดการการจอง" ? "#5ABBAD" : "#A1CDCA",
              fontWeight: "bold",
              borderLeft:
                activeItem === "จัดการการจอง" ? "4px solid #55BBB4" : null,
              marginTop: "5px",
              marginBottom: "5px",
            }}
            active={activeItem === "จัดการการจอง"}
            onClick={() => setActiveItem("จัดการการจอง")}
          >
            <div style={{ display: "flex", alignItems: "center" }}>
              <Badge
                count={pending_approval}
                size="small"
                style={{ backgroundColor: "#F5BE41" }}
              >
                <Image src={post_office} />
              </Badge>
            </div>
          </Menu.Item>
          {Menuitem([shopIcon], "ร้านค้าทั้งหมด")}

          {/* Bottom Zone */}
          <div style={{ bottom: "0px", position: "fixed" }}>
            {/* <Menu.Item
                as="a"
                style={{
                  color: activeItem === "settingIcon" ? "#5ABBAD" : "#A1CDCA",
                  fontWeight: "bold",
                  borderLeft:
                    activeItem === "settingIcon" ? "4px solid #55BBB4" : null,
                  marginTop: "5px",
                  marginBottom: "5px",
                }}
                active={activeItem === "settingIcon"}
                onClick={() => setActiveItem("settingIcon")}
              >
                <Image src={settingIcon} />
              </Menu.Item> */}
            <Menu.Item
              as="a"
              style={{
                color: "#A1CDCA",
                fontWeight: "bold",
                borderLeft:
                  activeItem === "logouticon" ? "4px solid #55BBB4" : null,
                marginTop: "5px",
                marginBottom: "5px",
              }}
              active={activeItem === "logouticon"}
              onClick={() => logoutFunc()}
            >
              <Image src={logouticon} />
            </Menu.Item>
          </div>
        </Sidebar>
        <Sidebar.Pusher>
          <Segment basic style={{ textAlign: "left" }}>
            <HeaderZone
              activeItem={activeItem}
              mallDetail={mallDetail}
              getMallAccess={getMallAccess}
            />
            {filterCase(activeItem)}
          </Segment>
        </Sidebar.Pusher>
      </Sidebar.Pushable>
    </>
  );
}
