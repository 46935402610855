import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import { Typography, Box, Button, Popper, Paper } from "@mui/material";
import { useNavigate } from "react-router-dom";
import applicationStore from "common/stores/application.store";

const orange = "#EB983F";
const red = "#DE0E0E";
const green = "#66DD7C";

export function ApplicationStatus({ status }) {
  const role = applicationStore((e) => e.role);

  const STATUS = {
    company_admin: {
      scan_pending: {
        statusText: "ใบสมัครใหม่",
        recordColor: orange,
      },
      scan_auto_reject: {
        statusText: "ไม่ระบุมาตรฐาน",
        recordColor: red,
      },
      scan_auto_reject_admin: {
        statusText: "ไม่ระบุมาตรฐาน",
        recordColor: red,
      },
      scan_reject: {
        statusText: "ปฏิเสธ",
        recordColor: red,
      },
      scan_sent_to_buyer: {
        statusText: "ส่งให้จัดซื้อแล้ว",
        recordColor: green,
      },
      screen_reject: {
        statusText: "ไม่ผ่านการคัดกรอง",
        recordColor: red,
      },
      screen_date_pending: {
        statusText: "ผ่านการคัดกรอง - รอ",
        recordColor: orange,
      },
      screen_pass: {
        statusText: "ผ่านการคัดกรอง - สำเร็จ",
        recordColor: orange,
      },
      deal_pending: {
        statusText: "อยู่ระหว่างดำเนินการ",
        recordColor: orange,
      },
      deal_3to6_accept: {
        statusText: "สนใจ : รอ 3-6 เดือน",
        recordColor: orange,
      },
      deal_accept: {
        statusText: "สนใจ : พร้อมสั่งซื้อ",
        recordColor: orange,
      },
      deal_failed: {
        statusText: "ไม่ผ่าน",
        recordColor: red,
      },
      deal_success: {
        statusText: "สำเร็จ - เปิดหน้าบัญชี",
        recordColor: green,
      },
    },
    company_buyer: {
      scan_pending: {
        statusText: "ใบสมัครใหม่",
        recordColor: orange,
      },
      scan_auto_reject: {
        statusText: "ไม่ผ่านการคัดกรอง",
        recordColor: red,
      },
      scan_auto_reject_admin: {
        statusText: "ไม่ผ่านการคัดกรอง",
        recordColor: red,
      },
      scan_reject: {
        statusText: "ไม่ผ่านการคัดกรอง",
        recordColor: red,
      },
      scan_sent_to_buyer: {
        statusText: "ส่งให้จัดซื้อแล้ว",
        recordColor: green,
      },
      screen_reject: {
        statusText: "ไม่ผ่านการคัดกรอง",
        recordColor: red,
      },
      screen_date_pending: {
        statusText: "ผ่านการคัดกรอง - รอ",
        recordColor: orange,
      },
      screen_pass: {
        statusText: "ผ่านการคัดกรอง - สำเร็จ",
        recordColor: orange,
      },
      deal_pending: {
        statusText: "อยู่ระหว่างดำเนินการ",
        recordColor: orange,
      },
      deal_3to6_accept: {
        statusText: "สนใจ และคาดว่าจะสั่งซื้อภายใน 3-6 เดือน",
        recordColor: orange,
      },
      deal_accept: {
        statusText: "สนใจและจะสั่งซื้อทันที",
        recordColor: orange,
      },
      deal_failed: {
        statusText: "ไม่ผ่าน",
        recordColor: red,
      },
      deal_success: {
        statusText: "สำเร็จ - เปิดหน้าบัญชี",
        recordColor: green,
      },
    },
    sme: {},
  };

  return (
    <>
      <Marker recordColor={STATUS?.[role ?? "sme"][status]?.recordColor} />
      <Typography fontSize={16}>
        {STATUS?.[role ?? "sme"][status]?.statusText}
      </Typography>
    </>
  );
}

export const Marker = (props) => (
  <FiberManualRecordIcon
    htmlColor={props?.recordColor ?? green}
    fontSize="10"
    sx={{
      mr: 1,
    }}
  />
);

export function ActionButton({ cellValue }) {
  const navigate = useNavigate();
  const role = "company_admin";

  const applicationStatus = getApplicationStatus({
    role,
    status: cellValue.row.status,
  });

  return (
    <Button
      {...STATUS[applicationStatus]}
      sx={{
        fontSize: 14,
        py: 1.5,
        borderRadius: 2.5,
        minWidth: 100,
      }}
      onClick={() => navigate(`/opportunity/application/${cellValue.id}`)}
    >
      {applicationStatus}
    </Button>
  );
}

export function getApplicationStatus({ role, status }) {
  if (role === "company_admin" && status === "scan_pending")
    return "ตรวจใบสมัคร";
  if (role === "company_admin" && status === "scan_sent_to_buyer")
    return "เปลี่ยนสถานะ";
  if (
    ["scan_auto_reject_admin", "scan_auto_reject", "scan_reject"].includes(
      status
    )
  )
    return "ดูข้อมูล";
  if (role === "company_admin") return "ดูข้อมูล";
  return "จัดการ";
}

export const STATUS = {
  ตรวจใบสมัคร: {
    color: "red",
    variant: "contained",
    editable: true,
  },
  เปลี่ยนสถานะ: {
    color: "red",
    variant: "contained",
    editable: false,
  },
  ดูข้อมูล: {
    color: "red",
    variant: "outlined",
    editable: false,
  },
  จัดการ: {
    color: "red",
    variant: "outlined",
    editable: false,
  },
};

export const TAB_STATE = {
  scan: { 1: true, 2: true, 3: true, 4: false, 5: false },
  screen: { 1: true, 2: true, 3: false, 4: true, 5: true },
  deal: { 1: true, 2: true, 3: false, 4: true, 5: true },
  default: { 1: true, 2: true, 3: true, 4: false, 5: false },
};
