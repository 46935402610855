import React, { useState, useEffect } from "react";
import {
  Table,
  Drawer,
  Button,
  Image,
  Row,
  Select,
  Modal,
  DatePicker,
  Input,
  Spin,
  Divider,
  message,
} from "antd";
import { EyeOutlined } from "@ant-design/icons";
import { Menu, Label, Icon } from "semantic-ui-react";
import moment from "moment";

import requestTable from "../table/request-table";
import axiosAPI from "assets/js/axiosApi";
import numberwithcommas from "assets/js/numberwithcommas";

import rejectedIcon from "assets/img/rejected.png";
import * as shopService from "services/shop.service";
import * as bookingService from "services/booking.service";
import * as userService from "services/user.service";
import _ from "lodash";

const { TextArea } = Input;

export default function BookingManage(props) {
  // Switch
  const [status, setStatus] = useState("pending_approval");
  const [detailshop, setDetailshop] = useState("ข้อมูลร้านค้า");

  // Show detail information of shop
  const [visible, setVisible] = useState(false);

  // API
  const [booking, setBooking] = useState(null);
  const [drawerShow, setDraweShow] = useState(null);
  const [shopInfo, setShopInfo] = useState(null);
  const [spaceID, setSpaceID] = useState(null);
  const [customerDetail, setCustomerDetail] = useState(null);

  const [noteBooking, setNoteBooking] = useState(null);

  const [dataTableBookManage, setdataTableBookManage] = useState(null);
  var [dataTablecount, setdataTablecount] = useState(0);

  // Show Badge count
  var [pending_approval_count, setPending_approval_count] = useState(null);
  var [approved_count, setApproved_count] = useState(null);
  var [disapproved_count, setDisapproved_count] = useState(null);
  var [pending_payment_count, setPending_payment_count] = useState(null);
  var [paid_count, setPaid_count] = useState(null);
  var [cancelled_count, setCancelled_count] = useState(null);
  var [failed_count, setFailed_count] = useState(null);
  //

  // fromdate todate for Export
  var fromDate = null;
  var toDate = null;
  //

  // initialize
  useEffect(() => {
    getBooking(props.mallID);
    async function getBooking(id) {
      try {
        const allBooking = await bookingService.getBookingByMallIdLegacy({
          mall_id: props.mallID,
        });
        setBooking(allBooking);
        console.log("booking detail: ", allBooking);
        filterSpace("ทั้งหมด");
      } catch (error) {
        console.log(error);
        setBooking([]);
      }
    }
  }, [props.mallID]);
  //

  async function getDetailCustomer(id) {
    try {
      const useDetail = await userService.getUserDetail({ user_id: id });
      setCustomerDetail(useDetail);
      console.log("Customer detail: ", useDetail);
    } catch (error) {
      console.log(error);
      setCustomerDetail([]);
    }
  }

  const handleDetail = (e, { name }) => {
    setDetailshop(name);
  };
  const showDrawer = (book) => {
    console.log(book);
    setVisible(true);
    setDraweShow(book);
    setNoteBooking(book.booking_note);
    var perLength = [];

    if (!_.isEmpty(book.booking_date_range_info)) {
      for (let i = 0; i < book.booking_date_range_info.length; i++) {
        var a = moment(book.booking_date_range_info[i].from_date);
        var b = moment(book.booking_date_range_info[i].to_date);
        for (var m = moment(a); m.isBefore(b); m.add(1, "days")) {
          perLength.push(m.format("YYYY-MM-DD"));
        }
        perLength.push(book.booking_date_range_info[i].to_date);
      }
    }
  };

  async function getShopInfo(id) {
    setShopInfo(null);
    try {
      const shopInfo = await shopService.getShopInfo(id);
      setShopInfo(shopInfo);
      console.log("Shop Info detail: ", shopInfo);
    } catch (error) {
      console.log(error.responce);
      setShopInfo([]);
    }

    if (shopInfo === null) {
      return (
        <div
          id="loading_div"
          style={{ top: "50%", left: "50%", position: "absolute" }}
        >
          <Spin />
        </div>
      );
    }
  }

  const filterCase = (activeItem) => {
    switch (activeItem) {
      case "pending_approval":
        return (
          <div>
            <Label size="tiny" circular color="yellow" empty key="yellow" />
            &nbsp;รอการอนุมัติ
          </div>
        );
      case "approved":
        return (
          <div>
            <Label size="tiny" circular color="green" empty key="green" />
            &nbsp;อนุมัติ
          </div>
        );
      case "disapproved":
        return (
          <div>
            <Label size="tiny" circular color="red" empty key="red" />
            &nbsp;ไม่อนุมัติ
          </div>
        );
      case "pending_payment":
        return (
          <div>
            <Label size="tiny" circular color="blue" empty key="blue" />
            &nbsp;รอการชำระเงิน
          </div>
        );
      case "paid":
        return (
          <div>
            <Label size="tiny" circular color="green" empty key="green" />
            &nbsp;ชำระเงินแล้ว
          </div>
        );
      case "cancelled":
        return (
          <div>
            <Label size="tiny" circular color="black" empty key="black" />
            &nbsp;ยกเลิก
          </div>
        );
      case "failed":
        return (
          <div>
            <Label size="tiny" circular color="red" empty key="red" />
            &nbsp;ล้มเหลว
          </div>
        );
      default:
        return <h1>Coming Soon...</h1>;
    }
  };

  function transformBooking(book) {
    return {
      user_id: dataTablecount,
      bookingStatus: book.booking_status,
      zone_name: book.space_name,
      date: moment(book.booking_created_at).format("lll"),
      shop_name: book.shop_name,
      bussiness_type: book.shop_business_type,
      booth_size: book.space_booth_width + " x " + book.space_booth_length,
      duringTime: book.booking_total,
      status: filterCase(book.booking_status),
      zone: book.space_name,
      price: (
        <>{numberwithcommas(book.space_booth_price * book.booking_total)} ฿</>
      ),
      startDate: book.booking_date_range_info?.[0].from_date,
      endDate: book.booking_date_range_info?.[0].to_date,
      contact: book.space_contract_type,
      payrent: parseFloat(book.space_booth_price),
      link: (
        <div
          style={{ cursor: "pointer" }}
          onClick={() => (
            showDrawer(book),
            getShopInfo(book.shop_id),
            getDetailCustomer(book.user_id)
          )}
        >
          <EyeOutlined style={{ color: "#5ABBAD" }} /> ดูข้อมูลร้านค้า
        </div>
      ),
    };
  }

  function showStatusFunc(filArr) {
    var pending_approval_inFunc = 0;
    var approved_inFunc = 0;
    var disapproved_inFunc = 0;
    var pending_payment_inFunc = 0;
    var paid_inFunc = 0;
    var cancelled_inFunc = 0;
    var failed_inFunc = 0;

    for (let i = 0; i < filArr.length; i++) {
      if (filArr[i].booking_status === "pending_approval") {
        pending_approval_inFunc += 1;
      }
      if (filArr[i].booking_status === "approved") {
        approved_inFunc += 1;
      }
      if (filArr[i].booking_status === "disapproved") {
        disapproved_inFunc += 1;
      }
      if (filArr[i].booking_status === "pending_payment") {
        pending_payment_inFunc += 1;
      }
      if (filArr[i].booking_status === "paid") {
        paid_inFunc += 1;
      }
      if (filArr[i].booking_status === "cancelled") {
        cancelled_inFunc += 1;
      }
      if (filArr[i].booking_status === "failed") {
        failed_inFunc += 1;
      }
    }

    setPending_approval_count(pending_approval_inFunc);
    props.setPending_approval(pending_approval_inFunc);
    setApproved_count(approved_inFunc);
    setDisapproved_count(disapproved_inFunc);
    setPending_payment_count(pending_payment_inFunc);
    setPaid_count(paid_inFunc);
    setCancelled_count(cancelled_inFunc);
    setFailed_count(failed_inFunc);

    if (
      props.pending_approvals === null ||
      pending_approval_count === null ||
      approved_count === null ||
      disapproved_count === null ||
      pending_payment_count === null ||
      paid_count === null ||
      cancelled_count === null ||
      failed_count === null
    ) {
      return (
        <div
          id="loading_div"
          style={{ top: "50%", left: "50%", position: "absolute" }}
        >
          <Spin />
        </div>
      );
    }
  }

  async function filterSpace(key) {
    var filArr = [];
    setdataTableBookManage(null);
    try {
      var data = {
        mall_id: props.mallID,
      };
      const allBooking = await bookingService.getBookingByMallIdLegacy({
        mall_id: props.mallID,
      });

      const condition = (e) => {
        return key === "ทั้งหมด"
          ? e.booking_status === status
          : e.space_id === parseInt(key) && e.booking_status === status;
      };

      filArr = allBooking
        .map((book) => {
          if (condition(book)) {
            dataTablecount += 1;
            return transformBooking(book);
          }
          return {};
        })
        .filter((e) => !_.isEmpty(e));

      setSpaceID(key);
      setdataTableBookManage(filArr);

      if (key === "ทั้งหมด") {
        showStatusFunc(allBooking);
      } else {
        showStatusFunc(
          allBooking.map(
            (book, index) => book.space_id === parseInt(key) && book
          )
        );
      }
    } catch (error) {
      console.log(error);
      setBooking([]);
      setdataTableBookManage([]);
    }

    if (dataTableBookManage === null) {
      return (
        <div
          id="loading_div"
          style={{ top: "50%", left: "50%", position: "absolute" }}
        >
          <Spin />
        </div>
      );
    }
  }

  const handleClick = async (e, { name }) => {
    setdataTableBookManage(null);
    setStatus(name);
    console.log(name);
    var filArr = [];
    try {
      const allBooking = await bookingService.getBookingByMallIdLegacy({
        mall_id: props.mallID,
      });
      if (spaceID === "ทั้งหมด") {
        allBooking.map(
          (book, index) =>
            book.booking_status === name &&
            filArr.push(
              ((dataTablecount += 1),
              {
                user_id: dataTablecount,
                bookingStatus: book.booking_status,
                zone_name: book.space_name,
                date: moment(book.booking_created_at).format("lll"),
                shop_name: book.shop_name,
                bussiness_type: book.shop_business_type,
                booth_size:
                  book.space_booth_width + " x " + book.space_booth_length,
                duringTime: book.booking_total,
                status: filterCase(book.booking_status),
                zone: book.space_name,
                price: (
                  <>
                    {numberwithcommas(
                      book.space_booth_price * book.booking_total
                    )}{" "}
                    ฿
                  </>
                ),
                startDate: book.booking_date_range_info?.[0].from_date,
                endDate: book.booking_date_range_info?.[0].to_date,
                contact: book.space_contract_type,
                payrent: parseFloat(book.space_booth_price),
                link: (
                  <div
                    style={{ cursor: "pointer" }}
                    onClick={() => (
                      showDrawer(book),
                      getShopInfo(book.shop_id),
                      getDetailCustomer(book.user_id)
                    )}
                  >
                    <EyeOutlined style={{ color: "#5ABBAD" }} /> ดูข้อมูลร้านค้า
                  </div>
                ),
              })
            )
        );
      } else {
        allBooking.map(
          (book, index) =>
            book.space_id === parseInt(spaceID) &&
            book.booking_status === name &&
            filArr.push(
              ((dataTablecount += 1),
              {
                user_id: dataTablecount,
                bookingStatus: book.booking_status,
                date: moment(book.booking_created_at).format("lll"),
                shop_name: book.shop_name,
                zone_name: book.space_name,
                bussiness_type: book.shop_business_type,
                booth_size:
                  book.space_booth_width + " x " + book.space_booth_length,
                duringTime: book.booking_total,
                status: filterCase(book.booking_status),
                zone: book.space_name,
                price: (
                  <>
                    {numberwithcommas(
                      book.space_booth_price * book.booking_total
                    )}{" "}
                    ฿
                  </>
                ),
                startDate: book.booking_date_range_info?.[0].from_date,
                endDate: book.booking_date_range_info?.[0].to_date,
                contact: book.space_contract_type,
                payrent: parseFloat(book.space_booth_price),
                link: (
                  <div
                    style={{ cursor: "pointer" }}
                    onClick={() => (
                      showDrawer(book),
                      getShopInfo(book.shop_id),
                      getDetailCustomer(book.user_id)
                    )}
                  >
                    <EyeOutlined style={{ color: "#5ABBAD" }} /> ดูข้อมูลร้านค้า
                  </div>
                ),
              })
            )
        );
      }
      console.log(filArr);
      setdataTableBookManage(filArr);
    } catch (error) {
      console.log(error);
      setdataTableBookManage([]);
    }

    if (dataTableBookManage === null) {
      return (
        <div
          id="loading_div"
          style={{ top: "50%", left: "50%", position: "absolute" }}
        >
          <Spin />
        </div>
      );
    }
  };

  async function updateStatus(drawerShow, statusSV) {
    var data = {
      id: drawerShow.booking_id,
      status: statusSV,
      note: noteBooking,
    };
    console.log(data);

    try {
      await bookingService.postUpdateBooking(data);
      setVisible(false);
      filterSpace(spaceID);
      props.getBooking(props.mallID);
      setNoteBooking(null);
    } catch (error) {
      const modal = Modal.error({
        icon: false,
        okButtonProps: { style: { display: "none" } },
        content: (
          <div>
            <div style={{ display: "flex", justifyContent: "center" }}>
              <Image src={rejectedIcon} preview={false} />
            </div>
            <div
              style={{
                fontSize: "22px",
                fontWeight: "bold",
                marginTop: "30px",
                textAlign: "center",
              }}
            >
              ขออภัย คุณได้กดรับผู้เช่าจนครบ จำนวนผู้เช่า เต็มในพื้นที่แล้ว
            </div>
            <div style={{ textAlign: "center", marginTop: "60px" }}>
              <object
                style={{
                  fontSize: "18px",
                  color: "#3FA7DB",
                  cursor: "pointer",
                }}
                onClick={() => closeModal()}
              >
                ปิด
              </object>
            </div>
          </div>
        ),
      });
      const closeModal = () => modal.destroy();
      console.log(error.response);
      setVisible(false);
      if (error.response.status === 400) {
        return modal;
      }
    }
  }
  function handleChange(value, { key }) {
    console.log(`selected ${value}`);
    filterSpace(key);
  }

  function fromDateOnChange(date, dateString) {
    console.log(date);
    console.log(dateString);
    fromDate = dateString;
  }
  function toDateOnChange(date, dateString) {
    console.log(date);
    console.log(dateString);
    toDate = dateString;
  }
  async function exportFileAlert() {
    const { confirm } = Modal;

    return confirm({
      content: (
        <>
          <div
            style={{
              fontSize: "20px",
              fontWeight: "bold",
              fontFamily: "'Kanit', sans-serif",
            }}
          >
            Export ข้อมูลผู้จอง
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              marginTop: "25px",
              padding: "5px",
            }}
          >
            <div>
              เลือกวันที่
              <DatePicker
                onChange={fromDateOnChange}
                style={{ width: "90%" }}
              />
            </div>
            <div>
              ถึงวันที่
              <DatePicker onChange={toDateOnChange} style={{ width: "90%" }} />
            </div>
          </div>
        </>
      ),
      icon: false,
      footer: false,
      cancelText: "ยกเลิก",
      okText: "Send Report to Email",
      onOk() {
        exportFile();
      },
    });
  }
  async function exportFile() {
    console.log(fromDate);
    console.log(toDate);

    if (fromDate === null) {
      return Modal.info({
        content: (
          <div>
            <p>กรุณาเลือกวันที่ต้องการนำออกข้อมูล</p>
          </div>
        ),
      });
    }

    if (moment(toDate).diff(moment(fromDate), "days") < 0) {
      return Modal.info({
        content: (
          <div>
            <p>วันที่ต้องการนำข้อมูลออกไม่ถูกต้อง</p>
          </div>
        ),
      });
    }
    if (toDate === null) {
      toDate = fromDate;
    }

    const key = "updatable";
    message.loading({ content: "กำลังทำการนำออกข้อมูล...", key });

    console.log(props.mallID);
    console.log(fromDate);
    console.log(toDate);

    try {
      await bookingService.getExportBooking({
        mall_id: props.mallID,
        from_date: fromDate,
        to_date: toDate,
        send_email: true,
      });
      message.success({ content: "สำเร็จ!", key, duration: 2 });
    } catch (error) {
      console.log(error.response);
      Modal.error({
        content: "ผิดพลาด กรุณาลองอีกครั้ง",
      });
    }
  }

  function showDoc(doc) {
    console.log(doc);
    for (let i = 0; i < shopInfo.documents.length; i++) {
      if (shopInfo.documents[i].document_type === doc) {
        return Modal.info({
          content: (
            <>
              <div
                style={{
                  fontSize: "20px",
                  fontWeight: "bold",
                  fontFamily: "'Kanit', sans-serif",
                  textAlign: "center",
                }}
              >
                {shopInfo.documents[i].document_type}
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginTop: "40px",
                }}
              >
                <Image src={shopInfo.documents[i].document_url} />
              </div>
            </>
          ),
          icon: false,
          footer: false,
          onOk() {},
        });
      }
    }
  }
  function handleNoteChange(e) {
    setNoteBooking(e.target.value);
    console.log(e.target.value);
  }
  if (booking === null) {
    return (
      <div
        id="loading_div"
        style={{ top: "50%", left: "50%", position: "absolute" }}
      >
        <Spin />
      </div>
    );
  }

  async function onDowloandAllDocument() {
    try {
      const getDocumentCompress = await shopService.getDocumentCompress(
        shopInfo.id
      );
      const link = document.createElement("a");
      link.href = getDocumentCompress;
      link.download = `shop-${shopInfo.id}`;
      link.click();
    } catch (error) {
      console.log(error.response);
      Modal.error({
        content: "ผิดพลาด กรุณาลองอีกครั้ง",
      });
    }
  }

  return (
    <>
      <body style={{ fontFamily: "'Kanit', sans-serif" }}>
        <div
          style={{ marginLeft: "60px", marginTop: "30px", marginRight: "60px" }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              marginRight: "60px",
            }}
          >
            <div>
              <Row>
                <div>
                  โซน
                  <Select
                    bordered={false}
                    style={{
                      width: 120,
                      borderRadius: "12px",
                      border: "1px solid #DBE8E7",
                      width: "400px",
                      marginLeft: "20px",
                    }}
                    onChange={(e, key) => handleChange(e, key)}
                    defaultValue="ทั้งหมด"
                  >
                    <Select.Option key="ทั้งหมด" value="ทั้งหมด">
                      ทั้งหมด
                    </Select.Option>
                    {props.space.map((sp) => (
                      <Select.Option key={sp.id} value={sp.name}>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                          }}
                        >
                          <div>{sp.name}</div>
                          <div></div>
                        </div>
                      </Select.Option>
                    ))}
                  </Select>
                </div>
              </Row>
            </div>
            <div>
              <Button
                type="primary"
                size="large"
                style={{
                  fontFamily: "'Kanit', sans-serif",
                  color: "white",
                  backgroundColor: "#5ABBAD",
                  borderRadius: "12px",
                  padding: "0px 50px",
                  fontWeight: "bold",
                  border: "none",
                }}
                onClick={() => exportFileAlert()}
              >
                Send Report to Email
              </Button>
            </div>
          </div>
          <div style={{ marginTop: "14px", marginRight: "60px" }}>
            <Menu tabular>
              <Menu.Item
                name="pending_approval"
                active={status === "pending_approval"}
                onClick={handleClick}
              >
                รอการอนุมัติ
                <div
                  style={{
                    color: "white",
                    backgroundColor:
                      pending_approval_count !== null ? "#E78831" : null,
                    padding: "3px 5px",
                    marginLeft: "5px",
                    borderRadius: "7px",
                  }}
                >
                  {pending_approval_count}
                </div>
              </Menu.Item>

              <Menu.Item
                name="approved"
                active={status === "approved"}
                onClick={handleClick}
              >
                อนุมัติ
                <div
                  style={{
                    fontWeight: "bold",
                    color: "#E78831",
                    padding: "3px 5px",
                    marginLeft: "5px",
                    borderRadius: "7px",
                  }}
                >
                  {approved_count}
                </div>
              </Menu.Item>

              <Menu.Item
                name="disapproved"
                active={status === "disapproved"}
                onClick={handleClick}
              >
                ไม่อนุมัติ
                <div
                  style={{
                    fontWeight: "bold",
                    color: "#E78831",
                    padding: "3px 5px",
                    marginLeft: "5px",
                    borderRadius: "7px",
                  }}
                >
                  {disapproved_count}
                </div>
              </Menu.Item>

              <Menu.Item
                name="pending_payment"
                active={status === "pending_payment"}
                onClick={handleClick}
              >
                รอการชำระเงิน
                <div
                  style={{
                    fontWeight: "bold",
                    color: "#E78831",
                    padding: "3px 5px",
                    marginLeft: "5px",
                    borderRadius: "7px",
                  }}
                >
                  {pending_payment_count}
                </div>
              </Menu.Item>

              <Menu.Item
                name="paid"
                active={status === "paid"}
                onClick={handleClick}
              >
                ชำระเงินแล้ว
                <div
                  style={{
                    fontWeight: "bold",
                    color: "#E78831",
                    padding: "3px 5px",
                    marginLeft: "5px",
                    borderRadius: "7px",
                  }}
                >
                  {paid_count}
                </div>
              </Menu.Item>

              <Menu.Item
                name="cancelled"
                active={status === "cancelled"}
                onClick={handleClick}
              >
                ยกเลิก
                <div
                  style={{
                    fontWeight: "bold",
                    color: "#E78831",
                    padding: "3px 5px",
                    marginLeft: "5px",
                    borderRadius: "7px",
                  }}
                >
                  {cancelled_count}
                </div>
              </Menu.Item>

              <Menu.Item
                name="failed"
                active={status === "failed"}
                onClick={handleClick}
              >
                ล้มเหลว
                <div
                  style={{
                    fontWeight: "bold",
                    color: "#E78831",
                    padding: "3px 5px",
                    marginLeft: "5px",
                    borderRadius: "7px",
                  }}
                >
                  {failed_count}
                </div>
              </Menu.Item>
            </Menu>
            <Table
              columns={requestTable}
              dataSource={dataTableBookManage}
              scroll={{ x: 1300 }}
              style={{ marginRight: "60px" }}
              loading={dataTableBookManage === null ? true : false}
            />
          </div>
        </div>
        <Drawer
          closable={false}
          onClose={() => (setVisible(false), setDetailshop("ข้อมูลร้านค้า"))}
          visible={visible}
          width={500}
        >
          {drawerShow && (
            <div style={{ fontFamily: "'Kanit', sans-serif" }}>
              <div style={{ fontSize: "25px", fontWeight: "bold" }}>
                ข้อมูลร้านค้า
              </div>
              <Menu pointing secondary widths={2} color="green">
                <Menu.Item
                  name="ข้อมูลร้านค้า"
                  active={detailshop === "ข้อมูลร้านค้า"}
                  onClick={handleDetail}
                />
                <Menu.Item
                  name="เอกสารผู้เช่า"
                  active={detailshop === "เอกสารผู้เช่า"}
                  onClick={handleDetail}
                />
              </Menu>
              {detailshop === "ข้อมูลร้านค้า" ? (
                <>
                  <div
                    style={{
                      padding: "20px",
                      backgroundColor: "#DEE0E0",
                      borderRadius: "12px",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <div style={{ fontWeight: "bold" }}>วันที่ขอจอง</div>
                      <div>
                        {drawerShow.booking_date_range_info?.[0] &&
                          drawerShow.booking_date_range_info.map((bd) => (
                            <div>
                              {moment(bd.from_date).format("ll")} -{" "}
                              {moment(bd.to_date).format("ll")} ({bd.total_days}{" "}
                              วัน)
                            </div>
                          ))}
                      </div>
                    </div>

                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        marginTop: "30px",
                      }}
                    >
                      <div style={{ fontWeight: "bold" }}>โซน</div>
                      <div>{drawerShow.space_floor}</div>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        marginTop: "30px",
                      }}
                    >
                      <div style={{ fontWeight: "bold" }}>สัญญา</div>
                      <div>{drawerShow.space_contract_type}</div>
                    </div>
                  </div>

                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      marginTop: "25px",
                      color: "#869594",
                    }}
                  >
                    <div style={{ fontWeight: "bold" }}>ชื่อร้าน</div>
                    <div>{drawerShow.shop_name}</div>
                  </div>
                  <Divider />
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      marginTop: "25px",
                      color: "#869594",
                    }}
                  >
                    <div style={{ fontWeight: "bold" }}>ชื่อ-สกุล</div>
                    <div>
                      {drawerShow.user_first_name} {drawerShow.user_last_name}
                    </div>
                  </div>
                  <Divider />
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      marginTop: "25px",
                      color: "#869594",
                    }}
                  >
                    <div style={{ fontWeight: "bold" }}>เบอร์ติดต่อ</div>
                    <div>{drawerShow.user_phone}</div>
                  </div>
                  <Divider />
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      marginTop: "25px",
                      color: "#869594",
                    }}
                  >
                    <div style={{ fontWeight: "bold" }}>รูปภาพ</div>
                    <div>
                      ({shopInfo?.images?.[0] && shopInfo.images.length})
                    </div>
                  </div>
                  <Divider />
                  <div style={{ marginTop: "25px" }}>
                    <div
                      style={{
                        overflowX: "scroll",
                        overflowY: "hidden",
                        whiteSpace: "nowrap",
                        height: "200px",
                      }}
                    >
                      {shopInfo === null ? (
                        <div
                          id="loading_div"
                          style={{
                            top: "50%",
                            left: "50%",
                            position: "absolute",
                          }}
                        >
                          <Spin />
                        </div>
                      ) : (
                        shopInfo.images.map((img) => (
                          <div style={{ display: "inline-block" }}>
                            <Image
                              src={img.image_url}
                              style={{
                                width: "200px",
                                height: "200px",
                                objectFit: "cover",
                                display: "block",
                                borderRadius: "12px",
                                marginLeft: "5px",
                              }}
                            />
                          </div>
                        ))
                      )}
                    </div>
                  </div>

                  <div style={{ marginTop: "25px" }}>
                    <TextArea
                      style={{ fontFamily: "'Kanit', sans-serif" }}
                      disabled={
                        drawerShow.booking_status !== "pending_approval"
                      }
                      value={noteBooking}
                      onChange={handleNoteChange}
                      rows={4}
                      placeholder="ระบุหมายเหตุ"
                    />
                  </div>

                  {drawerShow.booking_status === "pending_approval" && (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-around",
                        alignItems: "center",
                        marginTop: "25px",
                        color: "#869594",
                      }}
                    >
                      <Button
                        type="dashed"
                        size="large"
                        onClick={() => updateStatus(drawerShow, "ไม่อนุมัติ")}
                      >
                        ปฏิเสธ
                      </Button>
                      <Button
                        type="primary"
                        size="large"
                        onClick={() => updateStatus(drawerShow, "อนุมัติ")}
                      >
                        ยืนยัน
                      </Button>
                    </div>
                  )}
                </>
              ) : (
                <>
                  <div style={{ marginTop: "25px", color: "#869594" }}>
                    <div style={{ fontWeight: "bold" }}>เอกสารยืนยันตัวตน</div>
                  </div>

                  {shopInfo &&
                    shopInfo.documents.map((sd) => (
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                          marginTop: "25px",
                          color: "#5D6968",
                          backgroundColor: "#DBE8E7",
                          padding: "18px",
                          borderRadius: "12px",
                          fontWeight: "bold",
                        }}
                      >
                        <div>เอกสาร {sd.document_type}</div>
                        <div
                          style={{
                            color: "#18A0FB",
                            cursor: "pointer",
                            textDecoration: "underline",
                          }}
                          onClick={() => showDoc(sd.document_type)}
                        >
                          ดูเอกสาร
                        </div>
                      </div>
                    ))}
                  <div
                    style={{
                      marginTop: "50px",
                      textAlign: "center",
                      fontWeight: "bold",
                      fontSize: "15px",
                      color: "#18A0FB",
                      textDecoration: "underline",
                    }}
                  >
                    <div
                      style={{ cursor: "pointer" }}
                      onClick={() => onDowloandAllDocument()}
                    >
                      <Icon name="download" /> ดาวน์โหลดเอกสารผู้เช่า
                    </div>
                  </div>
                </>
              )}
            </div>
          )}
        </Drawer>
      </body>
    </>
  );
}
