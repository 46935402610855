import React from "react";
import CssBaseline from "@mui/material/CssBaseline";
import { Box, Container, Paper } from "@mui/material/";
import { createTheme, ThemeProvider, useTheme } from "@mui/material/styles";
import { isMobile } from "common/utils/mediaQuery";
import themeStore from "common/stores/theme.store";
import Snackbar from "common/components/snackbar";
import theme from "common/styles/theme";

export default function Components(props) {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      {props.children}
      <Snackbar />
    </ThemeProvider>
  );
}
