import React from "react";

export default function useNetwork() {
  const [isOnline, setIsOnline] = React.useState(true);
  const [showModal, setShowModal] = React.useState(false);

  const handleCancel = () => {
    setShowModal(false);
  };

  const handleRetry = () => {
    if (isOnline) {
      setShowModal(false);
    }
  };

  React.useEffect(() => {
    function check_connection() {
      window.addEventListener("offline", () => {
        setIsOnline(false);
        setShowModal(true);
      });
      window.addEventListener("online", () => {
        setIsOnline(true);
      });
    }
    check_connection();
  });

  return { showModal, setShowModal, handleCancel, handleRetry };
}
