import { axios, HeaderDefault, GetEnvironment } from "services/base.service";

export const postNewSpace = (payload) => {
  return axios({
    url: `/v1/space/new`,
    method: "post",
    data: payload,
    ...HeaderDefault({}),
  }).then((res) => res?.data?.data);
};

export const getSpacefromSearch = (payload) => {
  return axios({
    url: `/v1/space/search`,
    method: "post",
    data: payload,
    ...HeaderDefault({}),
  }).then((res) => res?.data?.data);
};

export const getSpaceUser = ({ mall_id }) => {
  return axios({
    url: `/v1/space/user`,
    params: { mall_id },
    method: "get",
    ...HeaderDefault({}),
  }).then((res) => res?.data?.data);
};

export const getSpaceInfo = ({ space_id }) => {
  return axios({
    url: `/v1/space/info`,
    params: { space_id },
    method: "get",
    ...HeaderDefault({}),
  }).then((res) => res?.data?.data);
};

export const getAvailableofSpace = ({ space_id, fully_booked }) => {
  return axios({
    url: `/v1/space/available`,
    params: { space_id, fully_booked },
    method: "get",
    ...HeaderDefault({}),
  }).then((res) => res?.data?.data);
};

export const getAvailableSummary = ({ space_id }) => {
  return axios({
    url: `/v1/space/available/summary`,
    params: { space_id },
    method: "get",
    ...HeaderDefault({}),
  }).then((res) => res?.data?.data);
};

export const postSpaceUpdate = (payload) => {
  return axios({
    url: `/v1/space/update`,
    data: payload,
    method: "post",
    ...HeaderDefault({}),
  }).then((res) => res?.data?.data);
};

export const postAddRangeSpace = (payload) => {
  return axios({
    url: `/v1/space/add/range/available`,
    data: payload,
    method: "post",
    ...HeaderDefault({}),
  }).then((res) => res?.data?.data);
};

export const postUpdateRangeSpace = (payload) => {
  return axios({
    url: `/v1/space/update/range/available`,
    data: payload,
    method: "post",
    ...HeaderDefault({}),
  }).then((res) => res?.data?.data);
};

export const deleteRangeSpace = ({ create_id }) => {
  return axios({
    url: `/v1/space/delete/range/available`,
    params: { create_id },
    method: "delete",
    ...HeaderDefault({}),
  }).then((res) => res?.data?.data);
};

export const postNewSpaceImage = ({ img_type, space_id, payload }) => {
  return axios({
    url: `/v1/space/image/new`,
    params: { img_type, space_id },
    data: payload,
    method: "post",
    ...HeaderDefault({}),
  }).then((res) => res?.data?.data);
};

export const deleteSpaceImage = ({ image_id }) => {
  return axios({
    url: `/v1/space/image/delete`,
    params: { image_id },
    method: "delete",
    ...HeaderDefault({}),
  }).then((res) => res?.data?.data);
};
