import React, { useState, useEffect } from "react";
import { Container } from "semantic-ui-react";
import { Row, Col, Form, Modal, Input, Button, Checkbox } from "antd";
import { ArrowLeftOutlined } from "@ant-design/icons";
import "../css/admin.css";
import axiosAPI from "../../../assets/js/axiosApi";
import EmailInput from "./email-input";
import ResetPassword from "./reset-password";
import VerifyPassword from "./verify-otp";
import { useNavigate } from "react-router-dom";

export default function ForgetPassword() {
  const navigate = useNavigate();
  const [step, setStep] = useState(1);

  return (
    <body className="body">
      <Row>
        <Col span={14}>
          <div id="sme-login-bg">
            <div
              style={{
                color: "white",
                textAlign: "left",
                padding: "250px 90px 110px 90px",
              }}
            >
              <div style={{ fontWeight: "bold", fontSize: "18px" }}>
                CP Origin
              </div>
              <div
                style={{
                  fontWeight: "bold",
                  fontSize: "30px",
                  marginTop: "37px",
                }}
              >
                SME Ecosystem platform
              </div>
              <div style={{ fontSize: "16px", marginTop: "28px" }}>
                WE ARE DEDICATED TO BUILDING & DEVELOPING SUSTAINABLE BUSINESS
                GROWTH FOR SMEs IN THAILAND
              </div>
            </div>
          </div>
        </Col>
        <Col span={10}>
          <ArrowLeftOutlined
            style={{ float: "left", padding: "45px", cursor: "pointer" }}
            onClick={() => navigate("/login")}
          />
          <Container>
            <div
              style={{
                display: "block",
                marginLeft: "auto",
                marginRight: "auto",
                marginTop: "20%",
                padding: "45px",
              }}
            >
              <div
                style={{
                  fontWeight: "bold",
                  fontSize: "30px",
                  textAlign: "left",
                }}
              >
                ลืมรหัสผ่าน
              </div>
              {step === 1 ? (
                <EmailInput setStep={setStep} />
              ) : step === 2 ? (
                <VerifyPassword setStep={setStep} />
              ) : step === 3 ? (
                <ResetPassword setStep={setStep} />
              ) : null}
            </div>
          </Container>
        </Col>
      </Row>
    </body>
  );
}
