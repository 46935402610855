import * as businessMatchingService from "services/business-matching.v2.service";
import businessMatchingStore from "common/stores/business-matching-v2.store";
import { useQuery } from "react-query";
import _ from "lodash";
import applicationStore from "common/stores/application.store";

export const useBusinessMatchingRegistrationQuery = (
  business_matching_id,
  filterState
) => {
  const [setRegistrations, setFetchErrorModal] = businessMatchingStore((e) => [
    e.setRegistrations,
    e.setFetchErrorModal,
  ]);
  return useQuery({
    queryKey: [
      "getBusinessMatchingRegistration",
      business_matching_id,
      filterState,
    ],
    queryFn: () =>
      businessMatchingService.getBusinessMatchingRegistration(
        business_matching_id,
        filterState
      ),
    onSuccess: (registrations) => {
      setRegistrations(registrations);
    },
    onError: (error) => {
      setFetchErrorModal(true);
      console.log("userQuery error");
    },
  });
};
export const useBusinessMatchingInfo = () => {
  const { setCampaigns, setFetchErrorModal } = businessMatchingStore();

  return useQuery({
    queryKey: "getBusinessMatching",
    queryFn: () => businessMatchingService.getBusinessMatching(),
    onSuccess: (campaigns) => {
      setCampaigns(campaigns);
    },
    onError: (error) => {
      setFetchErrorModal(true);
    },
  });
};

export const useRegistrationInfoQuery = (registration_id) => {
  const { initialApplicationData } = applicationStore();
  const setModal = businessMatchingStore((e) => e.setModal);
  return useQuery({
    queryKey: ["useRegistrationInfoQuery", registration_id],
    queryFn: () => businessMatchingService.getRegistrationInfo(registration_id),
    onSuccess: (registration) => {
      console.log("Registration on success");
      if (!registration) {
        return setModal({ visible: true, type: "deleted" });
      }
      initialApplicationData(registration);
    },
    onError: (error) => {
      console.log("userQuery error");
    },
  });
};

export const useBusinessMatchingSelectProductNumberQuery = () => {
  const [setProductNumberList, setFetchErrorModal] = businessMatchingStore(
    (state) => [state.setProductNumberList, state.setFetchErrorModal]
  );
  return useQuery({
    queryKey: ["useBusinessMatchingSelectProductNumberList"],
    queryFn: () => businessMatchingService.getBusinessMatchingDatePending(),
    onSuccess: (registrations) => {
      setProductNumberList(registrations);
    },
    onError: (error) => {
      setFetchErrorModal(true);
      console.log("userQuery error");
    },
  });
};

export const useBusinessMatchingGetTimeSlot = (assignee_id) => {
  const [setTimeSlots, setFetchErrorModal, campaign_id] = businessMatchingStore(
    (state) => [state.setTimeSlots, state.setFetchErrorModal, state.campaign_id]
  );
  return useQuery({
    queryKey: ["useBusinessMatchingGetTimeSlot", assignee_id],
    queryFn: () =>
      businessMatchingService.getTimeSlot(assignee_id, campaign_id),
    enabled: !!assignee_id,
    onSuccess: (timeSlotList) => {
      let timeSlotMeeting = [];
      if (timeSlotList.length > 0) {
        timeSlotMeeting = timeSlotList.map((el) => {
          return {
            title: "เจรจาธุรกิจ",
            start: el.time_slot_available.from_datetime,
            end: el.time_slot_available.to_datetime,
            extendedProps: {
              data: el,
            },
          };
        });
      }
      setTimeSlots(timeSlotMeeting);
    },
    onError: (error) => {
      setFetchErrorModal(true);
      console.log("userQuery error");
    },
  });
};

export const useBusinessMatchingGetBuyerEmails = () => {
  const [emailChecked, setEmailChecked, setBuyerEmails, setFetchErrorModal] =
    businessMatchingStore((state) => [
      state.emailChecked,
      state.setEmailChecked,
      state.setBuyerEmails,
      state.setFetchErrorModal,
    ]);
  return useQuery({
    queryFn: () => businessMatchingService.getBuyerFromProductCategory(),
    onSuccess: (buyerEmailList) => {
      setEmailChecked(emailChecked ?? buyerEmailList[0].id);
      setBuyerEmails(buyerEmailList);
    },
    onError: (error) => {
      setFetchErrorModal(true);
      console.log("userQuery error");
    },
  });
};
