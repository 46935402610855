import { axios, HeaderDefault, GetEnvironment } from "services/base.service";

export const getBookingAll = (payload) => {
  return axios({
    url: `/v2/admin/booking/search`,
    method: "post",
    data: payload,
    ...HeaderDefault({}),
  })
    .then((res) => res?.data?.data)
    .catch((err) => {
      throw err?.response?.data;
    });
};

export const getBookingByMallId = (payload) => {
  return axios({
    url: `/v2/admin/booking/search`,
    method: "post",
    data: payload,
    params: { page: payload?.page, size: payload?.size },
    ...HeaderDefault({}),
  })
    .then((res) =>
      res?.data?.data.map((e, i) => {
        return { ...e, id: i };
      })
    )
    .catch((err) => {
      throw err?.response?.data;
    });
};

export const getBookingByMallIdLegacy = (payload) => {
  return axios({
    url: `/v1/booking/admin/search`,
    method: "post",
    data: payload,
    params: { page: payload?.page, size: payload?.size },
    ...HeaderDefault({}),
  })
    .then((res) => res?.data?.data)
    .catch((err) => {
      throw err?.response?.data;
    });
};

export const postUpdateBooking = (payload) => {
  return axios({
    url: `/v1/booking/update/status`,
    method: "post",
    data: payload,
    ...HeaderDefault({}),
  })
    .then((res) => res?.data?.data)
    .catch((err) => {
      throw err?.response?.data;
    });
};

export const getExportBooking = ({
  mall_id,
  from_date,
  to_date,
  send_email,
}) => {
  return axios({
    url: `/v1/booking/export`,
    method: "get",
    params: { mall_id, from_date, to_date, send_email },
    ...HeaderDefault({}),
  })
    .then((res) => res?.data?.data)
    .catch((err) => {
      throw err?.response?.data;
    });
};
