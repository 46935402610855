import { useNavigate } from "react-router-dom";
import * as authenService from "services/authen.service";
import mallStore from "common/stores/mall.store";
import userStore from "common/stores/user.store";

export default function Authen() {
  const navigate = useNavigate();
  const { clearMalls } = mallStore();
  const { clearUser } = userStore();

  function logout() {
    authenService.logout();
    localStorage.removeItem("token");
    localStorage.removeItem("refresh_token");
    localStorage.clear();
    clearMalls();
    clearUser();
    navigate("/login");
  }

  return { logout };
}
