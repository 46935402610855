/* eslint-disable react-hooks/rules-of-hooks */
import React from "react";
import { createTheme, ThemeProvider, useTheme } from "@mui/material/styles";
// import useMediaQuery from "@material-ui/core/useMediaQuery";
import useMediaQuery from "@mui/material/useMediaQuery";

export const isMobile = (props) => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("sm"));
  return matches;
};

export const isTablet = (props) => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("sm"));
  return matches;
};
export const isLaptop = (props) => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("md"));
  return matches;
};

export const isDesktop = (props) => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("lg"));
  return matches;
};
