import create from "zustand";

const useStore = create((set) => ({
  open: false,
  setOpen: (open) => set((state) => ({ open: open })),
  error: {},
  setError: (error) => set((state) => ({ open: true, error: error })),
}));

export default useStore;
