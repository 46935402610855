import React, { useState, useEffect } from "react";
import "modules/space/css/admin.css";
import { RightOutlined, ArrowLeftOutlined } from "@ant-design/icons";
import { Button, Form, Input, Row, Col, Checkbox, Divider } from "antd";
import { Label } from "semantic-ui-react";
import axiosAPI from "assets/js/axiosApi";
import { useNavigate } from "react-router-dom";

export default function SettingOnboardAddFloor(props) {
  const navigate = useNavigate();
  const [editZone] = useState(JSON.parse(localStorage.getItem("edit_zone")));
  const [checkedValue, setCheckedValue] = useState(editZone.amenity.free);
  console.log(editZone);

  function onChangeConvinience(e) {
    setCheckedValue(e);
    console.log(`checked = ${e}`);
  }

  const saveAminitie = () => {
    var data = {
      id: editZone.id,
      amenity: { free: checkedValue },
    };
    axiosAPI
      .post("/v1/space/update", data)
      .then((response) => {
        console.log(response);
        navigate("/space/setting/edit");
      })
      .catch((error) => {
        console.log(error.response);
      });
  };

  return (
    <>
      <body style={{ fontFamily: "'Kanit', sans-serif" }}>
        <div id="admin-bg">
          <div
            style={{
              width: "708px",
              backgroundColor: "white",
              display: "block",
              marginLeft: "auto",
              marginRight: "auto",
              marginTop: "200px",
              borderRadius: "12px",
              padding: "50px",
            }}
          >
            <div style={{ textAlign: "left" }}>
              <div
                style={{
                  color: "#5ABBAD",
                  fontSize: "16px",
                  fontWeight: "bold",
                }}
              >
                <p
                  onClick={() => navigate("/space/setting/edit")}
                  style={{ cursor: "pointer" }}
                >
                  <ArrowLeftOutlined />
                </p>
              </div>
              <div
                style={{
                  color: "#5ABBAD",
                  fontSize: "24px",
                  fontWeight: "bold",
                  marginTop: "23px",
                }}
              >
                สิ่งอำนวยความสะดวก
              </div>

              <Checkbox.Group
                style={{ width: "100%" }}
                defaultValue={checkedValue}
                onChange={onChangeConvinience}
              >
                <div style={{ marginTop: "36px", marginBottom: "36px" }}>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <div style={{ color: "#5D6968", fontSize: "16px" }}>
                      <Label circular color="#5D6968" empty key="#C4C4C4" />{" "}
                      โต๊ะ
                    </div>
                    <div>
                      <Checkbox value="โต๊ะ"></Checkbox>
                    </div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      marginTop: "36px",
                    }}
                  >
                    <div style={{ color: "#5D6968", fontSize: "16px" }}>
                      <Label circular color="#5D6968" empty key="#C4C4C4" />{" "}
                      สายไฟ
                    </div>
                    <div>
                      <Checkbox value="สายไฟ"></Checkbox>
                    </div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      marginTop: "36px",
                    }}
                  >
                    <div style={{ color: "#5D6968", fontSize: "16px" }}>
                      <Label circular color="#5D6968" empty key="#C4C4C4" />{" "}
                      เก้าอี้
                    </div>
                    <div>
                      <Checkbox value="เก้าอี้"></Checkbox>
                    </div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      marginTop: "36px",
                    }}
                  >
                    <div style={{ color: "#5D6968", fontSize: "16px" }}>
                      <Label circular color="#5D6968" empty key="#C4C4C4" />{" "}
                      ปลั๊กไฟ
                    </div>
                    <div>
                      <Checkbox value="ปลั๊กไฟ"></Checkbox>
                    </div>
                  </div>
                </div>
              </Checkbox.Group>

              <div style={{ marginTop: "90px" }}>
                <Button
                  size="large"
                  onClick={() => saveAminitie()}
                  style={{
                    fontSize: "18px",
                    backgroundColor: "#5ABBAD",
                    color: "white",
                    borderRadius: "6px",
                    width: "100%",
                    fontFamily: "'Kanit', sans-serif",
                  }}
                >
                  ตกลง
                </Button>
              </div>
            </div>
          </div>
        </div>
      </body>
    </>
  );
}
