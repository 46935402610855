import loadable from "@loadable/component";
import Home from "pages";

// Partner
import space from "./space";
import recruit from "./recruit";
import report from "./report";
import opportunity from "./opportunity";

const protectedRoute = [
  ...space,
  ...recruit,
  ...report,
  ...opportunity,
  {
    exact: true,
    path: "/",
    element: <Home />,
  },
];

export default protectedRoute;
