import React from "react";
import sorryImage from "assets/img/sorry.png";
import {
  Dialog,
  Button,
  Box,
  Typography,
  Grid,
  Snackbar,
  Alert,
} from "@mui/material";
import { LazyLoadImage as Image } from "react-lazy-load-image-component";
import useNetwork from "common/hooks/use_network";
import businessMatchingStore from "common/stores/business-matching-v2.store";
import { Navigate } from "react-router-dom";
import applicationStore from "common/stores/application.store";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";

export default function ErrorModal() {
  return (
    <>
      <ErrorNetWorkModal />
      <ErrorGotoHomePageModal />
      <ServerDownModal />
    </>
  );
}

export function ErrorNetWorkModal() {
  const { handleRetry, handleCancel, showModal } = useNetwork();
  return (
    <Dialog
      backdropClick={handleRetry}
      onClose={(event, reason) => {
        if (reason === "backdropClick" || reason === "escapeKeyDown") {
          return 0;
        } else {
          handleCancel();
        }
      }}
      open={showModal}
      sx={{
        ".MuiPaper-root-MuiDialog-paper": { borderRadius: 2.5 },
      }}
    >
      <Box p={2} sx={{ maxWidth: 300 }}>
        <Typography textAlign="center" fontSize={16} fontWeight="bold">
          การเชื่อมต่ออินเตอร์เน็ตขัดข้อง
        </Typography>
        <Grid container alignItems="center" mt={4}>
          <Box
            component={Image}
            src={sorryImage}
            width={167}
            height={142}
            style={{
              objectFit: "cover",
              borderRadius: 2.5,
              backgroundColor: "#C4C4C4",
              margin: "0 auto",
            }}
          />
        </Grid>
        <Typography textAlign="center" my={4}>
          ขออภัยในความไม่สะดวก
        </Typography>
        <Typography textAlign="center" mt={3} fontSize={12}>
          เนื่องจากไม่พบสัญญาณอินเตอร์เน็ตทำให้ไม่ระบบไม่สามารถดำเนินการต่อได้
        </Typography>
        <Typography textAlign="center" fontSize={12}>
          โปรดตรวจสอบการเชื่อมต่ออินเตอร์เน็ตของท่านอีกครั้ง
        </Typography>
        <Button
          variant="contained"
          onClick={handleRetry}
          color="red"
          fullWidth
          sx={{ mt: 4, minHeight: 40 }}
        >
          ลองใหม่อีกครั้ง
        </Button>
        <Button
          variant="text"
          onClick={handleCancel}
          fullWidth
          sx={{ mt: 2, minHeight: 40 }}
        >
          ปิด
        </Button>
      </Box>
    </Dialog>
  );
}

function ErrorGotoHomePageModal() {
  const [fetchErrorModal, setFetchErrorModal] = businessMatchingStore(
    (state) => [state.fetchErrorModal, state.setFetchErrorModal]
  );
  return (
    <Dialog
      open={fetchErrorModal}
      onClose={() => setFetchErrorModal(false)}
      sx={{
        ".MuiPaper-root-MuiDialog-paper": { borderRadius: 2.5 },
      }}
    >
      <Box p={4} sx={{ maxWidth: 300 }}>
        <Typography textAlign="center" fontSize={16} fontWeight="bold">
          พบข้อผิดพลาดบางอย่าง
        </Typography>
        <Grid container alignItems="center" mt={4}>
          <Box
            component={Image}
            src={sorryImage}
            width={167}
            height={142}
            style={{
              objectFit: "cover",
              borderRadius: 2.5,
              backgroundColor: "#C4C4C4",
              margin: "0 auto",
            }}
          />
        </Grid>
        <Typography textAlign="center" my={4}>
          ขออภัยในความไม่สะดวก
        </Typography>
        <Typography textAlign="center">
          เนื่องจากเกิดข้อผิดพลาดบางประการทำให้ไม่ระบบไม่สามารถดำเนินการต่อได้
        </Typography>
        <Button
          variant="contained"
          onClick={() => window.location.reload()}
          color="red"
          fullWidth
          sx={{ mt: 4, minHeight: 40 }}
        >
          ลองใหม่อีกครั้ง
        </Button>
        <Button
          variant="text"
          onClick={() => <Navigate to="/" replace={true} />}
          fullWidth
          sx={{ mt: 2, minHeight: 40 }}
        >
          กลับสู่หน้าหลัก
        </Button>
      </Box>
    </Dialog>
  );
}

function ServerDownModal() {
  return (
    <Dialog
      open={false}
      sx={{
        ".MuiPaper-root-MuiDialog-paper": { borderRadius: 2.5 },
      }}
    >
      <Box p={4} sx={{ maxWidth: 300 }}>
        <Typography textAlign="center" fontSize={16} fontWeight="bold">
          พบข้อผิดพลาดบางอย่าง
        </Typography>
        <Grid container alignItems="center" mt={4}>
          <Box
            component={Image}
            src={sorryImage}
            width={167}
            height={142}
            style={{
              objectFit: "cover",
              borderRadius: 2.5,
              backgroundColor: "#C4C4C4",
              margin: "0 auto",
            }}
          />
        </Grid>
        <Typography textAlign="center" my={4}>
          ขออภัยในความไม่สะดวก
        </Typography>
        <Typography textAlign="center">
          เนื่องจากเกิดข้อผิดพลาดบางประการทำให้ไม่ระบบไม่สามารถดำเนินการต่อได้
        </Typography>
        <Typography>
          ทีมงานกำลังดำเนินการแก้ไขอยู่ขณะนี้ โปรดรอสักครู่
        </Typography>
      </Box>
    </Dialog>
  );
}
