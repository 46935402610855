export default (x) => {
    if (x === null) {
        return null
    }
    else if (x === 0)
    {
        return 0
    }
     else {
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }
}