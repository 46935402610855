import { useQuery } from "react-query";
import * as userService from "services/user.service";
import userStore from "common/stores/user.store";
import businessMatchingStore from "common/stores/business-matching-v2.store";
import _ from "lodash";
import Authen from "common/hooks/authen";

const isAuthenticated = () => localStorage.getItem("token");

export const useUserQuery = () => {
  const { setUser } = userStore();
  const { setRole: setBusinessMatchingRole } = businessMatchingStore();
  const { logout } = Authen();

  function setRole(user) {
    const permissions = user?.primary_service_permission;
    const businessMatchingRole = permissions.business_matching?.role;
    if (!_.isEmpty(businessMatchingRole))
      setBusinessMatchingRole(businessMatchingRole);
  }

  return useQuery({
    queryKey: "userInfo",
    queryFn: () => userService.getUserInfo(),
    enable: !!isAuthenticated(),
    onSuccess: (user) => {
      if (!_.isEmpty(user)) {
        setUser(user);
        setRole(user);
      }
    },
    onError: (error) => {
      console.log("userQuery error");
      if (error.code === 401) logout();
    },
  });
};
