import create from "zustand";

const useStore = create((set) => ({
  mall: { label: "ทั้งหมด" },
  setMall: (mall) => set(() => ({ mall: mall })),
  malls: [],
  setMalls: (malls) => set(() => ({ malls: malls })),
  clearMalls: () => set(() => ({ mall: { label: "ทั้งหมด" }, malls: [] })),
}));

export default useStore;
