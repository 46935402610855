import React, { useState, useEffect } from "react";
import { Segment, Icon, Image } from "semantic-ui-react";
import { Row, Col } from "antd";
import "../css/admin.css";
import RegisterItem from "./register-item";
import CPoriginLogo from "assets/img/cpOrigin-logo.png";

export default function Register(props) {
  const [regisLog, setRegisLog] = useState("/");

  return (
    <>
      <body className="body">
        <div id="admin-bg">
          <Row
            columns={2}
            style={{
              display: "flex",
              flexWrap: "wrap",
              alignContent: "center",
            }}
          >
            <Col span={12}>
              <div
                style={{
                  color: "white",
                  marginTop: "20%",
                  textAlign: "left",
                  marginLeft: "150px",
                }}
              >
                <img src={CPoriginLogo} width="240px" height="57.37" />
                <p
                  style={{
                    fontWeight: "bold",
                    fontSize: "48px",
                    marginTop: "18px",
                  }}
                >
                  We dedicated to building & Developing Subtainable Business
                  Growth for Smes Thailand
                </p>
              </div>
            </Col>
            <Col span={12}>
              <div
                style={{
                  padding: "25px",
                  backgroundColor: "white",
                  width: "520px",
                  borderRadius: "15px",
                  marginTop: "20%",
                  marginLeft: "20%",
                }}
              >
                <RegisterItem />
              </div>
            </Col>
          </Row>
        </div>
      </body>
    </>
  );
}
