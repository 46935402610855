import { axios, HeaderDefault, GetEnvironment } from "services/base.service";

export const getMallByUser = () => {
  return axios({
    url: `/v1/mall/user`,
    method: "get",
    ...HeaderDefault({}),
  }).then((res) =>
    res?.data?.data.map((e) => {
      return { ...e, label: e.name };
    })
  );
};
