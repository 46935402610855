import React from "react";
import loadable from "@loadable/component";
import { Navigate } from "react-router-dom";
const Layout = loadable(() => import("modules/opportunity/layout"));
const Dashboard = loadable(() => import("pages/opportunity/dashboard"));
const Application = loadable(() => import("pages/opportunity/application"));
const Purchase = loadable(() => import("pages/opportunity/purchase"));
const Calendar = loadable(() => import("pages/opportunity/calendar"));
const ApplicationDetail = loadable(() => import("pages/opportunity/application/registration-id"));
const CreateCalendar = loadable(() => import("pages/opportunity/calendar/create"));

const OpportunityRoute = [
  {
    path: "opportunity",
    element: <Layout />,
    children: [
      { index: true, element: <Dashboard /> },
      {
        path: "application",
        children: [
          { index: true, element: <Application /> },
          { path: ":registration_id", element: <ApplicationDetail /> },
        ],
      },
      { path: "purchase", element: <Purchase /> },
      { path: "calendar", element: <Calendar /> },
      { path: "calendar/create", element: <CreateCalendar /> },
      { path: "calendar/update/:id", element: <CreateCalendar /> },
    ],
  },
];
export default OpportunityRoute;
