import React, { useState, useEffect } from "react";
import "./css/admin.css";
import {
  PlusOutlined,
  ArrowLeftOutlined,
  CalendarOutlined,
} from "@ant-design/icons";
import {
  Modal,
  Button,
  Form,
  Input,
  Image,
  Checkbox,
  Radio,
  Divider,
  Upload,
  Row,
  Col,
  Switch,
  Collapse,
  message,
} from "antd";
import axiosAPI from "assets/js/axiosApi";
import { Loader, Label } from "semantic-ui-react";
import * as spaceService from "services/space.service";

const CheckboxGroup = Checkbox.Group;
const { Panel } = Collapse;
export default function EditSpace(props) {
  const [form] = Form.useForm();

  const [editZone, setEditZone] = useState(props.summaryAvailable);
  // API
  const [businessType, setBusinessType] = useState(null);
  const [id, setID] = useState(editZone.id);

  const [reserveType, setReserveType] = useState(editZone.booking_unit);
  const [required_approval, setRequired_approval] = useState(
    editZone.required_approval
  );
  const [uploadFileFloor, setUploadFileFloor] = useState(
    editZone.images.filter((img) => img.image_type === "รูปFloorPlan")
  );
  const [zoneName, setZoneName] = useState(editZone.name);
  const [avaiZone, setAvaiZone] = useState(editZone.total_unit);

  const [floor, setFloor] = useState(editZone.floor);
  const [contactType, setContactType] = useState(editZone.contract_type);
  const [areaType, setAreaType] = useState(editZone.area_type);
  const [booth_price, setBooth_price] = useState(editZone.booth_price);
  const [checkedList, setCheckedList] = useState(
    Object.keys(editZone.business_type)
  );
  const [boothSize_width, setBoothSize_width] = useState(editZone.booth_width);
  const [boothSize_length, setboothSize_length] = useState(
    editZone.booth_length
  );

  const [minReserved, setMinReserved] = useState(editZone.min_booking_day);
  const [maxReserved, setMaxReserved] = useState(editZone.max_booking_day);

  const [minFuture_Reserved, setMinFutureReserved] = useState(
    editZone.min_pre_booking_day
  );
  const [maxFuture_Reserved, setMaxFutureReserved] = useState(
    editZone.max_pre_booking_day
  );

  const [checkedValue, setCheckedValue] = useState(
    editZone && editZone.amenity && Object.keys(editZone.amenity)
  );

  const [onLoading, setOnloading] = useState(false);
  const [checkIsChange, setcheckIsChange] = useState(false);

  const [uploadFileZone, setUploadFileZone] = useState(
    editZone.images.filter((img) => img.image_type === "รูปโซน")
  );
  const [fileList, setFileList] = useState();

  useEffect(() => {
    console.log(editZone);
    initial(props.summaryAvailable);
  }, [props.summaryAvailable]);

  function initial(sa) {
    console.log(Object.keys(sa.business_type));
    setEditZone(sa);
    setID(sa.id);
    setZoneName(sa.name);
    setAvaiZone(sa.total_unit);
    setReserveType(sa.booking_unit);
    setRequired_approval(sa.required_approval);
    setUploadFileFloor(
      sa.images.filter((img) => img.image_type === "รูปFloorPlan")
    );
    setUploadFileZone(sa.images.filter((img) => img.image_type === "รูปโซน"));
    setFloor(sa.floor);
    setContactType(sa.contract_type);
    setAreaType(sa.area_type);
    setBooth_price(sa.booth_price);
    setCheckedList(Object.keys(sa.business_type));
    setBoothSize_width(sa.booth_width);
    setboothSize_length(sa.booth_length);
    setMinReserved(sa.min_booking_day);
    setMaxReserved(sa.max_booking_day);
    setMinFutureReserved(sa.min_pre_booking_day);
    setMaxFutureReserved(sa.max_pre_booking_day);
    setCheckedValue(sa && sa.amenity && Object.keys(sa.amenity));

    // const zonePicture = props.summaryAvailable.images.filter(img => img.image_type === "รูปโซน")
    // if(zonePicture.length > 0) {
    //     setFileList([{
    //         url: zonePicture[zonePicture.length - 1].image_url
    //     }])
    // }

    form.setFieldsValue({
      zoneName: sa.name,
      id: sa.id,
      avaiZone: sa.total_unit,
      required_approval: sa.required_approval,
      floor: sa.floor,
      minFuture_Reserved: sa.min_pre_booking_day,
      maxFuture_Reserved: sa.max_pre_booking_day,
      minReserved: sa.min_booking_day,
      maxReserved: sa.max_booking_day,
      boothSize_length: sa.booth_length,
      boothSize_width: sa.booth_width,
      booth_price: sa.booth_price,
      checkedList: Object.keys(sa.business_type),
      checkedValue: sa && sa.amenity && Object.keys(sa.amenity),
      uploadFileZone: sa.images.filter((img) => img.image_type === "รูปโซน"),
    });
  }

  useEffect(() => {
    async function getBusinessType() {
      await axiosAPI
        .get(`/v1/shop/business/type`)
        .then((response) => {
          setBusinessType(response.data.data);
          console.log("getBusinessType: ", response.data.data);
        })
        .catch((error) => {
          console.log(error.responce);
          setBusinessType([]);
        });
    }
    getBusinessType();
  }, []);

  const onFinish = async () => {
    const key = "updatable";

    // if((minReserved !== null && minReserved.length !== 0) && (maxReserved !== null && maxReserved.length !== 0)){
    //     if(parseFloat(minReserved) > parseFloat(maxReserved)){
    //         return Modal.info({
    //             content: (
    //             <div>
    //                 วันจองสูงสุดควรมากกว่าวันจองขั้นต่ำ
    //             </div>
    //             ),
    //         }),
    //         setOnloading(false)
    //     }
    // }

    // if((minFuture_Reserved !== null && minFuture_Reserved.length !== 0) && (maxFuture_Reserved !== null && maxFuture_Reserved.length !== 0)){
    //     if(parseFloat(minFuture_Reserved) > parseFloat(maxFuture_Reserved)){
    //         return Modal.info({
    //             content: (
    //             <div>
    //                 วันจองล่วงหน้าสูงสุดควรมากกว่าวันจองล่วงหน้าขั้นต่ำ
    //             </div>
    //             ),
    //         }),
    //         setOnloading(false)
    //     }
    // }
    var minR, maxR, minFutureR, maxFutureR;

    if (minFuture_Reserved === null || minFuture_Reserved.length === 0) {
      minFutureR = null;
    } else {
      minFutureR = minFuture_Reserved;
    }

    if (maxFuture_Reserved === null || maxFuture_Reserved.length === 0) {
      maxFutureR = null;
    } else {
      if (parseInt(maxFuture_Reserved) === 0) {
        return (
          Modal.info({
            content: <div>วันจองล่วงหน้าสูงสุดควรมีขั้นต่ำเป็น 1</div>,
          }),
          setOnloading(false)
        );
      } else {
        maxFutureR = maxFuture_Reserved;
      }
    }

    if (minReserved === null || minReserved.length === 0) {
      minR = null;
    } else {
      minR = minReserved;
    }

    if (maxReserved === null || maxReserved.length === 0) {
      maxR = null;
    } else {
      if (parseInt(maxReserved) === 0) {
        return (
          Modal.info({
            content: <div>วันจองสูงสุดควรมีขั้นต่ำเป็น 1</div>,
          }),
          setOnloading(false)
        );
      } else {
        maxR = maxReserved;
      }
    }
    if (minFutureR !== null && maxFutureR !== null) {
      if (parseFloat(minFuture_Reserved) > parseFloat(maxFuture_Reserved)) {
        return (
          Modal.info({
            content: (
              <div>วันจองล่วงหน้าสูงสุดควรมากกว่าวันจองล่วงหน้าขั้นต่ำ</div>
            ),
          }),
          setOnloading(false)
        );
      }
    }

    if (minR !== null && maxR !== null) {
      if (parseFloat(minReserved) > parseFloat(maxReserved)) {
        return (
          Modal.info({
            content: <div>วันจองสูงสุดควรมากกว่าวันจองขั้นต่ำ</div>,
          }),
          setOnloading(false)
        );
      }
    }

    var updateChecklist = {};
    if (checkedList.length !== 0) {
      for (let i = 0; i < checkedList.length; i++) {
        updateChecklist[checkedList[i]] = true;
      }
    }
    console.log(updateChecklist);

    var updatecheckedValue = {};
    if (checkedValue !== null) {
      for (let i = 0; i < checkedValue.length; i++) {
        updatecheckedValue[checkedValue[i]] = true;
      }
    }
    console.log(updatecheckedValue);

    setOnloading(true);
    message.loading({ content: "กำลังแก้ไขข้อมูล...", key });

    var data = {
      id: id,
      name: zoneName,
      area_type: areaType,
      contract_type: contactType,
      mall_id: props.mallID,
      floor: floor,
      booth_price: booth_price,
      booth_width: boothSize_width,
      booth_length: boothSize_length,
      business_type: updateChecklist,
      amenity: updatecheckedValue,
      min_booking_day: minR,
      max_booking_day: maxR,
      min_pre_booking_day: minFutureR,
      max_pre_booking_day: maxFutureR,
      booking_unit: reserveType,
      required_approval: required_approval,
      total_unit: avaiZone,
    };
    console.log(data);
    try {
      await spaceService.postSpaceUpdate(data);
      setOnloading(false);
      props.setEditZoneDrawer(false);
      props.getSpace(props.mallID);
      message.success({ content: "สำเร็จ!", key, duration: 2 });
    } catch (error) {
      console.log(error.response);
      setOnloading(false);
      return message.error({
        content: error.response.data.message,
        key,
        duration: 2,
      });
    }
  };

  function removeZonePic() {
    const key = "updatable";
    const { confirm } = Modal;

    var img = props.summaryAvailable.images.filter(
      (sm) => sm.image_type === "รูปโซน"
    );
    return new Promise((resolve, reject) => {
      confirm({
        title: "คุณยืนยันการลบรูปภาพโซน?",
        onOk: () => {
          message.loading({ content: "กำลังลบรูปภาพ...", key });

          async function deleteIMG() {
            try {
              await spaceService.deleteSpaceImage({ image_id: img[0].id });
              message.success({ content: "สำเร็จ!", key, duration: 2 });
              // window.location.reload()
              props.getSpaceInfo(props.summaryAvailable.id);
            } catch (error) {
              console.log(error.response);
              return message.error({
                content: error.response.data.message,
                key,
                duration: 2,
              });
            }
          }
          deleteIMG();
        },
        onCancel: () => {
          reject();
          setOnloading(false);
        },
      });
    });
  }
  async function uploadZonePic(e) {
    var img = props.summaryAvailable.images.filter(
      (sm) => sm.image_type === "รูปโซน"
    );

    if (img.length === 0) {
      const key = "updatable";
      message.loading({ content: "กำลังอัพโหลดรูปภาพ...", key });

      const params = new FormData();
      params.append("file", e.file);
      try {
        await spaceService.postNewSpaceImage({
          space_id: props.summaryAvailable.id,
          img_type: "รูปโซน",
          payload: params,
        });
        props.getSpaceInfo(props.summaryAvailable.id);
        message.success({ content: "สำเร็จ!", key, duration: 2 });
      } catch (error) {
        setOnloading(false);
        console.log(error.response);
        Modal.error({
          content: error.response.data.message,
        });
      }
    } else {
      return Modal.info({
        content: <div>กรุณาลบรูปภาพเก่าก่อนอัพโหลดรูปใหม่</div>,
      });
    }
  }

  if (businessType === null || editZone === null) {
    return (
      <div
        id="loading_div"
        style={{ top: "50%", left: "50%", position: "absolute" }}
      >
        <Loader active size="medium">
          Loading...
        </Loader>
      </div>
    );
  }

  return (
    <>
      <div
        style={{
          backgroundColor: "white",
          display: "block",
          marginLeft: "auto",
          marginRight: "auto",
          borderRadius: "12px",
          padding: "5px",
          fontFamily: "'Kanit', sans-serif",
        }}
      >
        <div
          style={{
            color: "#5ABBAD",
            fontSize: "24px",
            fontWeight: "bold",
            textAlign: "left",
          }}
        >
          แก้ไขโซน
        </div>
        <div
          style={{
            textAlign: "left",
            overflow: "scroll",
            height: "780px",
            overflowX: "visible",
            marginTop: "20px",
          }}
        >
          <div style={{ marginRight: "10px" }}>
            <div>
              <Form
                name="basic"
                labelCol={{ span: 8 }}
                wrapperCol={{ span: 24 }}
                requiredMark
                layout="vertical"
                form={form}
                onFinish={onFinish}
                onFocus={() => setcheckIsChange(true)}
              >
                <div style={{ textAlign: "left", marginTop: "17px" }}>
                  <Upload
                    // listType="picture"
                    listType="picture-card"
                    name="uploadFileZone"
                    onChange={(e) => uploadZonePic(e)}
                    beforeUpload={(file) => {
                      if (file.size > 6000000) {
                        message.error(`${file.name} มีขนาดเกิน 6 MB`);
                      }
                      return file.size < 6000000 ? false : Upload.LIST_IGNORE;
                    }}
                    fileList={
                      uploadFileZone[0] && [
                        {
                          url: uploadFileZone[0].image_url,
                        },
                      ]
                    }
                    maxCount={1}
                    onRemove={() => removeZonePic()}
                  >
                    <div>
                      <PlusOutlined
                        style={{
                          fontSize: "14px",
                          fontWeight: "bold",
                          color: "#5ABBAD",
                        }}
                      />
                      &nbsp;อัพโหลด
                    </div>
                  </Upload>
                </div>
                <div style={{ textAlign: "left", marginTop: "17px" }}>
                  {/* {
                                    zonePic.length !== 0 ?
                                    <Image
                                        width={80}
                                        src={zonePic[0].image_url}
                                    />
                                    :
                                    null
                                } */}
                </div>
                <div style={{ marginTop: "20px" }}>
                  <Form.Item
                    name="zoneName"
                    label="ตั้งชื่อโซน"
                    style={{ textAlign: "left" }}
                    rules={[{ required: true, message: "กรุณาระบุชื่อโซน" }]}
                  >
                    <Input
                      placeholder="โซนพาวิลเลี่ยน, เปิดท้าย"
                      value={zoneName}
                      name="zoneName"
                      onChange={(e) => setZoneName(e.target.value)}
                      style={{
                        borderBottom: "2px solid #F2F2F2",
                        fontFamily: "'Kanit', sans-serif",
                      }}
                      bordered={false}
                    />
                  </Form.Item>
                  <Collapse>
                    <Panel header="สิ่งอำนวยความสะดวก" key="1">
                      <Checkbox.Group
                        style={{ width: "100%" }}
                        value={checkedValue}
                        onChange={(e) => setCheckedValue(e)}
                      >
                        <div
                          style={{ marginTop: "10px", marginBottom: "10px" }}
                        >
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "center",
                            }}
                          >
                            <div style={{ color: "#5D6968", fontSize: "16px" }}>
                              {" "}
                              โต๊ะ
                            </div>
                            <div>
                              <Checkbox value="โต๊ะ"></Checkbox>
                            </div>
                          </div>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "center",
                              marginTop: "36px",
                            }}
                          >
                            <div style={{ color: "#5D6968", fontSize: "16px" }}>
                              {" "}
                              สายไฟ
                            </div>
                            <div>
                              <Checkbox value="สายไฟ"></Checkbox>
                            </div>
                          </div>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "center",
                              marginTop: "36px",
                            }}
                          >
                            <div style={{ color: "#5D6968", fontSize: "16px" }}>
                              {" "}
                              เก้าอี้
                            </div>
                            <div>
                              <Checkbox value="เก้าอี้"></Checkbox>
                            </div>
                          </div>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "center",
                              marginTop: "36px",
                            }}
                          >
                            <div style={{ color: "#5D6968", fontSize: "16px" }}>
                              {" "}
                              ปลั๊กไฟ
                            </div>
                            <div>
                              <Checkbox value="ปลั๊กไฟ"></Checkbox>
                            </div>
                          </div>
                        </div>
                      </Checkbox.Group>
                    </Panel>
                  </Collapse>
                  <Divider />
                  <Form.Item
                    name="floor"
                    label="ระบุชั้น"
                    style={{ textAlign: "left" }}
                    rules={[{ required: true, message: "กรุณาระบุชั้น" }]}
                  >
                    <Input
                      placeholder="B, G, 1"
                      name="floor"
                      value={floor}
                      onChange={(e) => setFloor(e.target.value)}
                      style={{
                        borderBottom: "2px solid #F2F2F2",
                        fontFamily: "'Kanit', sans-serif",
                      }}
                      bordered={false}
                    />
                  </Form.Item>
                  <Form.Item
                    label="รูปแบบการจอง"
                    rules={[
                      { required: true, message: "กรุณาเลือกรูปแบบการจอง" },
                    ]}
                  >
                    <Radio.Group
                      onChange={(e) => setReserveType(e.target.value)}
                      value={reserveType}
                    >
                      <Radio.Button value="วัน">รายวัน</Radio.Button>
                      <Radio.Button value="เดือน">รายเดือน</Radio.Button>
                    </Radio.Group>
                  </Form.Item>
                  <Form.Item
                    label="สัญญาในพื้นที่ที่เปิดรับ"
                    rules={[
                      { required: true, message: "กรุณาเลือกรูปแบบสัญญา" },
                    ]}
                  >
                    <Radio.Group
                      onChange={(e) => setContactType(e.target.value)}
                      value={contactType}
                    >
                      <Radio.Button value="ระยะสั้น">ระยะสั้น</Radio.Button>
                      <Radio.Button value="ระยะยาว">ระยะยาว</Radio.Button>
                    </Radio.Group>
                  </Form.Item>
                  <Form.Item
                    label="รูปแบบพื้นที่"
                    rules={[
                      { required: true, message: "กรุณาเลือกรูปแบบพื้นที่" },
                    ]}
                  >
                    <Radio.Group
                      onChange={(e) => setAreaType(e.target.value)}
                      value={areaType}
                    >
                      <Radio.Button value="indoor">indoor</Radio.Button>
                      <Radio.Button value="outdoor">outdoor</Radio.Button>
                    </Radio.Group>
                  </Form.Item>
                  <Form.Item
                    name="checkbox-group"
                    label="รูปแบบร้านค้าที่สามารถจองได้"
                  >
                    <div style={{ color: "#869594", fontSize: "14px" }}>
                      สามารถเลือกได้มากกว่า 1 รายการ
                    </div>
                    <div style={{ marginTop: "10px" }}>
                      <Form.Item
                        name="checkedList"
                        rules={[
                          { required: true, message: "กรุณาระบุรูปแบบร้านค้า" },
                        ]}
                      >
                        <CheckboxGroup
                          options={businessType}
                          value={checkedList}
                          name="checkedList"
                          onChange={(e) => setCheckedList(e)}
                        />
                      </Form.Item>
                    </div>
                  </Form.Item>
                  <Divider />

                  <Form.Item label="ขนาดต่อล็อก" style={{ marginBottom: 0 }}>
                    <Form.Item
                      style={{
                        display: "inline-block",
                        width: "calc(50% - 12px)",
                      }}
                      name="boothSize_width"
                      rules={[
                        { required: true, message: "กรุณาระบุขนาดต่อล็อก" },
                      ]}
                    >
                      <Input
                        placeholder="100ตร.ม"
                        name="boothSize_width"
                        value={boothSize_width}
                        onChange={(e) => setBoothSize_width(e.target.value)}
                        style={{
                          borderBottom: "2px solid #F2F2F2",
                          fontFamily: "'Kanit', sans-serif",
                        }}
                        bordered={false}
                      />
                    </Form.Item>
                    <span
                      style={{
                        display: "inline-block",
                        width: "24px",
                        lineHeight: "32px",
                        textAlign: "center",
                      }}
                    >
                      x
                    </span>
                    <Form.Item
                      style={{
                        display: "inline-block",
                        width: "calc(50% - 12px)",
                      }}
                      name="boothSize_length"
                      rules={[
                        { required: true, message: "กรุณาระบุขนาดต่อล็อก" },
                      ]}
                    >
                      <Input
                        placeholder="100ตร.ม"
                        name="boothSize_length"
                        value={boothSize_length}
                        onChange={(e) => setboothSize_length(e.target.value)}
                        style={{
                          borderBottom: "2px solid #F2F2F2",
                          fontFamily: "'Kanit', sans-serif",
                        }}
                        bordered={false}
                      />
                    </Form.Item>

                    <Form.Item
                      name="avaiZone"
                      label="ล็อกว่าง"
                      style={{ textAlign: "left" }}
                      rules={[
                        { required: true, message: "กรุณาระบุจำนวนล็อกว่าง" },
                        {
                          pattern: new RegExp(/^[0-9]+$/),
                          message: "ระบุเป็นตัวเลข",
                        },
                      ]}
                    >
                      <Input
                        placeholder="100"
                        name="avaiZone"
                        value={avaiZone}
                        onChange={(e) => setAvaiZone(e.target.value)}
                        style={{
                          borderBottom: "2px solid #F2F2F2",
                          fontFamily: "'Kanit', sans-serif",
                        }}
                        bordered={false}
                      />
                    </Form.Item>

                    <Form.Item
                      name="booth_price"
                      label="ราคาต่อล็อค"
                      style={{ textAlign: "left" }}
                      rules={[
                        { required: true, message: "กรุณาราคา" },
                        {
                          pattern: new RegExp(/^[0-9]+$/),
                          message: "ระบุเป็นตัวเลข",
                        },
                      ]}
                    >
                      <Input
                        placeholder="0.00"
                        name="booth_price"
                        value={booth_price}
                        suffix="ต่อวัน"
                        onChange={(e) => setBooth_price(e.target.value)}
                        style={{
                          borderBottom: "2px solid #F2F2F2",
                          fontFamily: "'Kanit', sans-serif",
                        }}
                        bordered={false}
                      />
                    </Form.Item>
                  </Form.Item>

                  <Row>
                    <Col span={12}>
                      <div style={{ marginBottom: "10px" }}>
                        จำนวนวันจองล่วงหน้าขั้นต่ำ
                      </div>
                      <Form.Item
                        rules={[
                          {
                            pattern: new RegExp(/^[0-9]+$/),
                            message: "ระบุเป็นตัวเลข",
                          },
                        ]}
                        style={{ width: "50%" }}
                        name="minFuture_Reserved"
                      >
                        <Input
                          name="minFuture_Reserved"
                          value={minFuture_Reserved}
                          onChange={(e) => setMinFutureReserved(e.target.value)}
                          suffix={
                            <CalendarOutlined style={{ color: "#5ABBAD" }} />
                          }
                        />
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <div style={{ marginBottom: "10px" }}>
                        จำนวนวันจองล่วงหน้าสูงสุด
                      </div>
                      <Form.Item
                        name="maxFuture_Reserved"
                        rules={[
                          {
                            pattern: new RegExp(/^[0-9]+$/),
                            message: "ระบุเป็นตัวเลข",
                          },
                        ]}
                        style={{ width: "50%" }}
                      >
                        <Input
                          name="maxFuture_Reserved"
                          value={maxFuture_Reserved}
                          onChange={(e) => setMaxFutureReserved(e.target.value)}
                          suffix={
                            <CalendarOutlined style={{ color: "#5ABBAD" }} />
                          }
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row>
                    <Col span={12}>
                      <div style={{ marginBottom: "10px" }}>
                        จำนวนวันขั้นต่ำในการจอง
                      </div>
                      <Form.Item
                        name="minReserved"
                        style={{ width: "50%" }}
                        rules={[
                          {
                            pattern: new RegExp(/^[0-9]+$/),
                            message: "ระบุเป็นตัวเลข",
                          },
                        ]}
                      >
                        <Input
                          value={minReserved}
                          name="minReserved"
                          onChange={(e) => setMinReserved(e.target.value)}
                          suffix={
                            <CalendarOutlined style={{ color: "#5ABBAD" }} />
                          }
                        />
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <div style={{ marginBottom: "10px" }}>
                        จำนวนวันสูงสุดในการจอง
                      </div>
                      <Form.Item
                        style={{ width: "50%" }}
                        rules={[
                          {
                            pattern: new RegExp(/^[0-9]+$/),
                            message: "ระบุเป็นตัวเลข",
                          },
                        ]}
                        name="maxReserved"
                      >
                        <Input
                          value={maxReserved}
                          name="maxReserved"
                          onChange={(e) => setMaxReserved(e.target.value)}
                          suffix={
                            <CalendarOutlined style={{ color: "#5ABBAD" }} />
                          }
                        />
                      </Form.Item>
                    </Col>
                  </Row>

                  <div style={{ marginTop: "10px" }}>
                    <div>
                      ตรวจสอบการจอง{" "}
                      <Switch
                        style={{ marginLeft: "15px" }}
                        onClick={(e) => setRequired_approval(e)}
                        checked={required_approval}
                      />
                    </div>
                    <p style={{ marginTop: "10px", color: "#869594" }}>
                      การจองที่ต้องตรวจสอบก่อนยืนยันให้ร้านค้าเข้าพื้นที่
                    </p>
                  </div>

                  <Divider />

                  <div style={{ marginTop: "10px" }}>
                    <div>ภาพ Floorplan</div>
                    <div>
                      {uploadFileFloor.length !== 0 ? (
                        <Image width={80} src={uploadFileFloor[0].image_url} />
                      ) : null}
                    </div>
                  </div>
                </div>

                <div style={{ marginTop: "45px" }}>
                  <Button
                    size="large"
                    htmlType="submit"
                    loading={onLoading}
                    disabled={!checkIsChange}
                    style={{
                      opacity: !checkIsChange && "0.2",
                      fontSize: "18px",
                      backgroundColor: "#5ABBAD",
                      color: "white",
                      borderRadius: "6px",
                      width: "100%",
                      fontFamily: "'Kanit', sans-serif",
                    }}
                  >
                    ยืนยัน
                  </Button>
                </div>
              </Form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
